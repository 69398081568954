import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Footer, Header, MainMenu } from "components/layout";

import "./layout.scss";

const Layout = () => {
  return (
    <Container className="layout">
      <Header />
      <MainMenu />
      <main className="layout-main">
        <Outlet />
      </main>
      <Footer />
    </Container>
  );
};

export default Layout;
