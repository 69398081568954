import { configureStore } from "@reduxjs/toolkit";

import projectImportFileReducer from "features/project/project-import-file.slice";
import projectCreateReducer from "features/project/project-create.slice";
import tailorMadeReducer from "features/tailor-made/tailor-made.slice";

import loginUserReducer from "../features/auth/auth-slice";

export const store = configureStore({
  reducer: {
    projectCreate: projectCreateReducer,
    fetchFile: projectImportFileReducer,
    tailorMade: tailorMadeReducer,
    loginUser: loginUserReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
