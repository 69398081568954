export const apiUrl = process.env.REACT_APP_API_URL;
export const menuRoutes = ["/", "/2", "/3", "/4", "/5", "/6", "/7", "/8", "/9"];

export const pipeConnectionItems = [
  {
    id: 1,
    label: "Seamless",
    labelValue: "(1.00)",
    value: "SEAMLESS",
  },
  {
    id: 2,
    label: "Electric Resistance Weld",
    labelValue: "(1.00)",
    value: "ELECTRIC_RESISTANCE_WELD",
  },
  {
    id: 3,
    label: "Electric Flash Weld",
    labelValue: "(1.00)",
    value: "ELECTRIC_FLASH_WELD",
  },
  {
    id: 4,
    label: "Electric Fusion (Arc) Weld / Spiral Weld",
    labelValue: "(0.80)",
    value: "ELECTRIC_FUSION_ARC_WELD",
  },
  {
    id: 5,
    label: "Furnace Butt Weld / Continuous Weld",
    labelValue: "(0.60)",
    value: "FURNACE_BUTT_WELD",
  },
  {
    id: 6,
    label: "(Double) Submerged Arc Weld",
    labelValue: "(1.00)",
    value: "SUBMERGED_ARC_WELD",
  },
  {
    id: 7,
    label: "Laser Beam Weld",
    labelValue: "(1.00)",
    value: "LASER_BEAM_WELD",
  },
];

export const pipeFluidItems = [
  { id: 1, label: "Oil", labelValue: "", value: "OIL" },
  {
    id: 2,
    label: "Gas",
    labelValue: "",
    value: "GAS",
  },
  { id: 3, label: "Water", labelValue: "", value: "WATER" },
  {
    id: 4,
    label: "Chemical product",
    labelValue: "",
    value: "CHEMICAL_PRODUCT",
  },
];

export const pipeNetworkItems = [
  { id: 1, label: "Pipeline", labelValue: "", value: "PIPELINE" },
  {
    id: 2,
    label: "Storage tank",
    labelValue: "",
    value: "STORAGE",
  },
  { id: 3, label: "Piping", labelValue: "", value: "PIPING" },
  {
    id: 4,
    label: "Pressurized vessel",
    labelValue: "",
    value: "VESSEL",
  },
  {
    id: 5,
    label: "Riser",
    labelValue: "",
    value: "RISER",
  },
];

export const pipeEnvironmentalItems = [
  { id: 1, label: "Onshore", labelValue: "", value: "ONSHORE" },
  {
    id: 2,
    label: "Buried",
    labelValue: "",
    value: "BURIED",
  },
  { id: 3, label: "Offshore Subsea", labelValue: "", value: "OFFSHORE_SUBSEA" },
  {
    id: 4,
    label: "Offshore top side",
    labelValue: "",
    value: "OFFSHORE_ON_AIR",
  },
];

export const addPrimerInitial = {
  name: "",
  catalog: null,
  consumption: null,
  max_temp: null,
  max_temp_install: null,
  min_temp: null,
  min_temp_install: null,
  weight_bucket: null,
  compatibility_composite_type: "",
  is_subsea_env: false,
};

export const addFillerInitial = {
  name: "",
  defect_zone: "",
  density: null,
  max_temp: null,
  suitable_for_leaks: false,
  is_subsea_env: false,
};

type StatusColors = {
  GENERATED: string;
  VALIDATION: string;
  VALIDATED: string;
  DONE: string;
  REJECTED: string;
  [key: string]: string;
};

export const statusColors: StatusColors = {
  GENERATED: "orange",
  VALIDATION: "light-blue",
  VALIDATED: "blue",
  DONE: "green",
  CALCULATED: "purple",
  REJECTED: "red",
};
