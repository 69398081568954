import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { useAppDispatch } from "app/hook";

import { resetSendCode } from "./auth-slice";

import "./modal-login.scss";

type ModalLoginProps = {
  form: { email: string; password: string };
  loading: boolean;
  sendLogin: Function;
  sendRestore: Function;
  errors: {
    email?: string;
    password?: string;
    newPassword?: string;
    confirmPassword?: string;
    code?: string;
  };
  cleanErrors: Function;
  isRestore: boolean;
  setIsRestore: Function;
  isRestoreSend: boolean;
  setIsRestoreSend: Function;
  sendSave: Function;
  isSetNewPassword: boolean;
  isSendCode: boolean;
};

const ModalLogin = ({
  form,
  loading,
  sendLogin,
  sendRestore,
  errors,
  cleanErrors,
  isRestore,
  setIsRestore,
  isRestoreSend,
  setIsRestoreSend,
  sendSave,
  isSetNewPassword,
  isSendCode,
}: ModalLoginProps) => {
  const [email, setEmail] = useState(form.email);
  const [password, setPassword] = useState(form.password);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");

  const dispatch = useAppDispatch();

  const handleLogin = () => {
    sendLogin({ email, password });
  };

  const handleRestore = () => {
    sendRestore({ email });
  };

  const handleSave = () => {
    sendSave({ code, email, newPassword, confirmPassword });
  };

  return (
    <Modal className="modal-login" show={true} centered>
      <Modal.Body>
        <div className="modal-login_left"></div>
        <div className="modal-login_right">
          {!isRestore && (
            <Form className="modal-login_form" autoComplete="off">
              <Form.Group controlId="formFieldLogin" className="formFieldLogin">
                <div className="formFieldLogin_wrap">
                  <Form.Control
                    type="email"
                    placeholder="Login"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    // pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group
                controlId="formFieldPassword"
                className="formFieldPassword"
              >
                <div className="formFieldPassword_wrap">
                  <Form.Control
                    autoComplete="off"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Button
                className="btn-next"
                onClick={() => handleLogin()}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="light"
                      size="sm"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>{" "}
                    Log in
                  </>
                ) : (
                  <> Log in</>
                )}
              </Button>
            </Form>
          )}
          {!isRestore && (
            <div className="modal-login_wrap-restore">
              <Button
                variant="link"
                className="modal-login_btn-restore"
                onClick={() => {
                  setIsRestore(true);
                  cleanErrors();
                }}
              >
                Do you want to restore your password?
              </Button>
            </div>
          )}
          {isRestore && !isSendCode && (
            <Form className="modal-login_form-restore" autoComplete="off">
              <Form.Group controlId="formFieldLogin" className="formFieldLogin">
                <div className="formFieldLogin_wrap">
                  <Form.Label>
                    Please enter your email address to get a code to restore you
                    password
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <div className="modal-login_form-restore-wrap-btns">
                <Button
                  className="btn-next"
                  onClick={() => handleRestore()}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner
                        animation="border"
                        role="status"
                        variant="light"
                        size="sm"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>{" "}
                      Restore
                    </>
                  ) : (
                    <> Restore</>
                  )}
                </Button>
                <Button
                  className="btn-prev"
                  onClick={() => {
                    setIsRestore(false);
                    setIsRestoreSend(false);
                    cleanErrors();
                  }}
                >
                  Back to log in
                </Button>
              </div>
            </Form>
          )}
          {isSendCode && isRestoreSend && (
            <Form
              className="modal-login_form-change-password"
              autoComplete="change-password"
            >
              <Form.Group controlId="formFieldCode" className="formFieldCode">
                <div className="formFieldCode_wrap">
                  <Form.Label>
                    Please check your email inbox to get a code to create a new
                    password
                  </Form.Label>
                  <Form.Control
                    autoComplete="code"
                    type="text"
                    placeholder="Code"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    isInvalid={!!errors.code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldNewPassword"
                className="formFieldNewPassword"
              >
                <div className="formFieldNewPassword_wrap">
                  <Form.Label>Make a new password</Form.Label>
                  <Form.Control
                    autoComplete="new-password"
                    type="password"
                    placeholder="New password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    isInvalid={!!errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldConfirmPassword"
                className="formFieldConfirmPassword"
              >
                <div className="formFieldPassword_wrap">
                  <Form.Control
                    autoComplete="off"
                    type="password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <div className="modal-login_form-restore-wrap-btns">
                <Button
                  className="btn-next"
                  onClick={() => handleSave()}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner
                        animation="border"
                        role="status"
                        variant="light"
                        size="sm"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>{" "}
                      Save
                    </>
                  ) : (
                    <> Save</>
                  )}
                </Button>
                <Button
                  className="btn-prev"
                  onClick={() => {
                    setIsRestore(false);
                    setIsRestoreSend(false);
                    cleanErrors();
                    dispatch(resetSendCode());
                  }}
                >
                  Back to log in
                </Button>
              </div>
              {isSetNewPassword && (
                <div className="modal-login_form-change-password_success-text">
                  Password has been successfully changed
                </div>
              )}
            </Form>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalLogin;
