import "./panel-body.scss";

type PanelBodyProps = {
  theme?: string;
  style?: Object;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

const PanelBody = ({ children, theme = "dark", style, className = "" }: PanelBodyProps) => {
  const getClasses = () => {
    if (theme === "dark") return `panel-body ${className}`;
    if (theme === "light-red") return `panel-body panel-body-light-red ${className}`;
    if (theme === "white") return `panel-body panel-body-white ${className}`;
    return `panel-body panel-body-light2 ${className}`;
  };
  return (
    <div className={getClasses()} style={style}>
      {children}
    </div>
  );
};

export default PanelBody;
