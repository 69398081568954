import { Button, Form, Spinner } from "react-bootstrap";

import { Results } from "features/project/project-create.slice.type";

import { Radio } from "components/library";

import Bend from "assets/images/bend_result.jpg";
import Straight from "assets/images/straight_result.jpg";
import Tee from "assets/images/tee_result.jpg";

import "./results-operations.scss";

type ResultsOperationsProps = {
  form: Results;
  loading: boolean;
  setField: Function;
  typePipe: string;
  calculate: Function;
  detailedEquilations: Function;
  is5050: boolean;
  is6040: boolean;
  is8020: boolean;
};

const ResultsOperations = ({
  form,
  loading,
  setField,
  typePipe,
  calculate,
  detailedEquilations,
  is5050,
  is6040,
  is8020,
}: ResultsOperationsProps) => {
  return (
    <div className="results-operations px-4 py-4">
      {typePipe === "STRAIGHT" && (
        <img src={Straight} alt="view pipe straight" />
      )}
      {typePipe === "BEND" && <img src={Bend} alt="view pipe bend" />}
      {typePipe === "TEE" && <img src={Tee} alt="view pipe tee" />}
      <div>
        <Form className="form-results-operations">
          <Form.Group
            controlId="formFieldTapeReference"
            className="formFieldTapeReference  px-3 py-4 mt-3"
          >
            <Form.Label>Used to compare results only</Form.Label>
            <div className="formFieldTapeReference_buttons">
              <Form.Control
                className={
                  form.tapeReference === "5050" && is5050
                    ? "btn-item btn-item-active-5050"
                    : "btn-item"
                }
                type="button"
                value="5050"
                onClick={() => setField("tapeReference", "5050")}
                readOnly={!is5050}
                disabled={!is5050}
              />
              <Form.Control
                className={
                  form.tapeReference === "6040" && is6040
                    ? "btn-item btn-item-active-6040"
                    : "btn-item"
                }
                type="button"
                value="6040"
                onClick={() => setField("tapeReference", "6040")}
                readOnly={!is6040}
                disabled={!is6040}
              />
              <Form.Control
                className={
                  form.tapeReference === "8020" && is8020
                    ? "btn-item btn-item-active-8020"
                    : "btn-item"
                }
                type="button"
                value="8020"
                onClick={() => setField("tapeReference", "8020")}
                readOnly={!is8020}
                disabled={!is8020}
              />
            </div>
            <Button
              className="btn-prev mt-2 mx-4 px-4"
              onClick={() => calculate()}
              disabled={loading}
            >
              {loading ? (
                <span className="loading-wrapp">
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                    size="sm"
                  />
                  Calculate
                </span>
              ) : (
                <>Calculate</>
              )}
            </Button>
          </Form.Group>
          <Form.Group
            controlId="formFieldTypeReport"
            className="formFieldTypeReport mt-6"
          >
            <Form.Control
              className={
                form.typeReport === "light"
                  ? "btn-item btn-item-active"
                  : "btn-item"
              }
              type="button"
              value="Light"
              onClick={() => setField("typeReport", "light")}
            />
            <Form.Control
              className={
                form.typeReport === "full"
                  ? "btn-item btn-item-active"
                  : "btn-item"
              }
              type="button"
              value="Full"
              onClick={() => setField("typeReport", "full")}
            />
          </Form.Group>
          {form.typeReport === "full" && (
            <>
              <div className="radio-group mt-4">
                <Radio
                  label="DOCX"
                  checked={form.reportType === "DOCX"}
                  flagCheck="DOCX"
                  setFlagCheck={() => setField("reportType", "DOCX")}
                />
                <Radio
                  label="PDF"
                  checked={form.reportType === "PDF"}
                  flagCheck="PDF"
                  setFlagCheck={() => setField("reportType", "PDF")}
                />
              </div>
              <Button
                className="btn-prev mt-4"
                onClick={() => detailedEquilations()}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="light"
                      size="sm"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>{" "}
                    Detailed Equilations
                  </>
                ) : (
                  <> Detailed Equilations</>
                )}
              </Button>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ResultsOperations;
