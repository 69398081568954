type ISvgProps = {
    isHover?: boolean;
  };
  
const BackSvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="20"
        height="12"
        viewBox="0 0 20 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.6339 6.883L15.2051 11.3118C14.961 11.5559 14.6411 11.678 14.3212 11.678C14.0013 11.678
          13.6814 11.5559 13.4373 11.3118C12.9491 10.8237 12.9491 10.0322 13.4373 9.54405L15.7322
          7.24913H1.25C0.559648 7.24913 0 6.68948 0 5.99913C0 5.30878 0.559648 4.74913 1.25 4.74913H15.7322L13.4373
          2.45421C12.9491 1.96604 12.9491 1.1746 13.4373 0.686436C13.9255 0.198271 14.7169 0.198271 15.2051
          0.686436L19.6339 5.11526C20.122 5.60339 20.122 6.39487 19.6339 6.883Z" fill="#a80d10"
        />
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.6339 6.883L15.2051 11.3118C14.961 11.5559 14.6411 11.678 14.3212 11.678C14.0013 11.678
        13.6814 11.5559 13.4373 11.3118C12.9491 10.8237 12.9491 10.0322 13.4373 9.54405L15.7322
        7.24913H1.25C0.559648 7.24913 0 6.68948 0 5.99913C0 5.30878 0.559648 4.74913 1.25 4.74913H15.7322L13.4373
        2.45421C12.9491 1.96604 12.9491 1.1746 13.4373 0.686436C13.9255 0.198271 14.7169 0.198271 15.2051
        0.686436L19.6339 5.11526C20.122 5.60339 20.122 6.39487 19.6339 6.883Z" fill="black"
      />
    </svg>
  );
};

export default BackSvg;
