import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";

import { Button } from "components/library";

import "../modal-window.scss";
import { getReasons } from "features/project/project-create.slice";

type IRejectedReasonModalProps = {
  isOpen: boolean;
  handleClose: (a: boolean) => void;
  handleSave: (a: string) => void;
};

interface IRejectedReasonModalErrors {
  reasonRejected?: string;
}

const RejectedReasonModal = ({
  isOpen,
  handleClose,
  handleSave,
}: IRejectedReasonModalProps) => {
  const { loading } = useAppSelector((state) => state.projectCreate);
  const { listReasons } = useAppSelector((state) => state.projectCreate.two);

  const [form, setForm] = useState({ reasonRejected: "" });
  const [errors, setErrors] = useState<IRejectedReasonModalErrors>({});

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && listReasons === null) {
      dispatch(getReasons());
    }
  }, [dispatch, listReasons, loading]);

  const validateForm = () => {
    const { reasonRejected } = form;
    const newErrors: IRejectedReasonModalErrors = {};

    if (reasonRejected === "")
      newErrors.reasonRejected = "Please enter Reason Rejected";

    return newErrors;
  };

  const handleOk = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      handleSave(form.reasonRejected);
      handleClose(false);
      setForm({ reasonRejected: "" });
      setErrors({});
    }
  };

  return (
    <Modal
      className="save-modal"
      show={isOpen}
      onHide={() => {
        handleClose(false);
        setForm({ reasonRejected: "" });
        setErrors({});
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>ATTENTION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Please, select or enter the own reason for</div>
        <div>the status Rejected</div>
        <Form.Group
          controlId="formFieldReasonRejected"
          className="formFieldReasonRejected"
        >
          <Form.Select
            aria-label="select reason rejected"
            onChange={(e) => {
              setForm({ reasonRejected: e.target.value });
            }}
            value={form.reasonRejected || ""}
          >
            <option key="" value="">
              Select reason
            </option>
            {listReasons !== null &&
              listReasons.length > 0 &&
              listReasons.map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
          </Form.Select>

          <Form.Control
            type="string"
            value={form.reasonRejected}
            onChange={(e) => {
              setForm({ reasonRejected: e.target.value });
            }}
            isInvalid={!!errors.reasonRejected}
          />
          <Form.Control.Feedback
            type="invalid"
            style={{
              display: !!errors.reasonRejected ? "block" : "none",
            }}
          >
            {errors.reasonRejected}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-next" text="Save" onClick={handleOk} />
      </Modal.Footer>
    </Modal>
  );
};

export default RejectedReasonModal;
