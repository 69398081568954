import ProjectCreateState from "./project-create.slice.type";

export const initialState: ProjectCreateState = {
  one: {
    client: "",
    project: "",
    distributorCompany: "",
    distributorId: null,
    salesManagerId: null,
    allSalesManagers: [],
    allDistributors: [],
    projectId: null,
    manager: "",
    repairing: "",
    isDisabled: false,
    projectData: {
      content: [],
      pageable: {
        sort: {
          sorted: false,
          unsorted: true,
          empty: true,
        },
        pageNumber: 1,
        pageSize: 10,
        offset: 0,
        paged: true,
        unpaged: false,
      },
      last: false,
      totalElements: 30,
      totalPages: 3,
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      first: true,
      numberOfElements: 10,
      size: 10,
      number: 0,
      empty: false,
    },
    archiveTableData: [],
    isExportLoading: false,
    isIdsLoaded: false,
    newIds: [],
  },
  two: {
    proposalName: "",
    selectedId: null,
    projectData: [],
    archiveTableData: [],
    techProposal: {
      data: {
        allowable_strains: "",
        alpha_s: 0,
        available_downstream: 0,
        available_upstream: 0,
        axial_load: 0,
        axial_moment: 0,
        bend_defect_location: "",
        bend_localization_defect: "",
        chemical_product: "",
        conditions: "",
        connection: "",
        defect_axial_length: 0,
        defect_circumferential_length: 0,
        defect_depth: 0,
        defect_location: "",
        defect_origin: "",
        defect_type: "",
        defect_type_report: "",
        design_factor: "",
        design_factor_value: 0,
        design_pressure: 0,
        design_temperature: 0,
        environment: "",
        filler_id: 0,
        fluid: "",
        gamma_lcl: "",
        grade_id: 0,
        grade_mby: 0,
        grade_smys: 0,
        id: 0,
        installation_pressure: 0,
        installation_temperature: 0,
        limited_axial_extand: "",
        mawp: 0,
        max_cyclic_loads_pressure: 0,
        medium_radius_bend_or_branch_diameter_tee: 0,
        min_cyclic_loads_pressure: 0,
        missing_informations: true,
        missing_informations_text: "",
        nb_cyclic_loads: 0,
        network: "",
        opening_angle_bend: 0,
        optional_design_consid: true,
        pipe_diameter: 0,
        pipe_thickness: 0,
        pipe_type: "",
        primer_id: 0,
        repair_composite_id: 0,
        repair_lifetime: 0,
        security_class: "",
        service_factor: "",
        service_factor_iso: "",
        sheer_load: 0,
        t_min: 0,
        tailor_made_number_of_layer: 0,
        tailor_made_option: "",
        tailor_made_reason: "",
        tailor_made_total_length_repair: 0,
        torsional_moment: 0,
      },
      tech_proposal: {
        date: "",
        id: 0,
        repair_reference: "",
        status: "",
        status_description: "",
        tech_proposal_name: "",
      },
    },
    isLoadedProposal: false,
    isChanged: false,
    isIdsLoaded: false,
    newIds: [],
    listReasons: null,
  },
  three: {
    typePipe: "STRAIGHT",
    standartThickness: false,
    originalDiameterMm: 0,
    originalDiameterInch: 0,
    material: "STEEL",
    wallThicknessMm: 0,
    wallThicknessInch: 0,
    mediumRadiusMm: 0,
    mediumRadiusInch: 0,
    radius: -1,
    openingAngleMm: 0,
    branchDiameterMm: 0,
    branchDiameterInch: 0,
    wallThicknessTbMm: 0,
    wallThicknessTbInch: 0,
    diameters: null,
    diameter: -1,
    schedules: null,
    schedule: -1,
    grades: [],
    grade: 1,
    smysMPa: 358.53,
    smyspsi: 52000,
    mby: 30000000,
    connection: "SEAMLESS",
    fluid: "OIL",
    network: "PIPELINE",
    environmental: "ONSHORE",
    thermalExpansion: 12,
    chemicalProduct: "",
    loadingDiameters: false,
    loadingSchedules: false,
  },
  four: {
    designFactorClass: "CLASS_1",
    designFactorOther: 0.72,
    designFactorDescription: [],
    repairClass: "CLASS_1",
    classDescription: [],
    designTemperatureC: 0,
    designTemperatureF: 32,
    installationTemperatureC: 0,
    installationTemperatureF: 32,
    designPressureMPa: 0,
    designPressurePsi: 0,
    designPressureBar: 0,
    installationPressureMPa: 0,
    installationPressurePsi: 0,
    installationPressureBar: 0,
    maopMPa: 0,
    maopPsi: 0,
    maopBar: 0,
    impact: 5,
    nLoadingCycles: 0,
    pMinPressure: 0,
    pMaxPressure: 0,
    optionalDesignConsideration: false,
    appliedFsh: 0,
    appliedMto: 0,
    appliedFax: 0,
    appliedMax: 0,
  },
  five: {
    defectTypeReport: "METAL_LOSS",
    defectOrigin: "CORROSION",
    defectLocation: "EXTERNAL",
    defectAxialLengthMm: 0,
    defectAxialLengthInch: 0,
    defectCircumferentialLengthMm: 0,
    defectCircumferentialLengthInch: 0,
    minimumResidualWallThicknessMm: 0,
    minimumResidualWallThicknessInch: 0,
    metalLossPercent: 0,
    metalLossMm: 0,
    metalLossInch: 0,
    gammaLcl: "LCL_1",
    isBendLocalisationDefect: "NO",
    bendDefectLocation: "EXTERNAL",
    isCustomValue: "NO",
    mawpValue: 0,
    isLimitedWrappingSpace: "NO",
    lAvailableDownstreamMm: 0,
    lAvailableDownstreamInch: 0,
    lAvailableUpstreamMm: 0,
    lAvailableUpstreamInch: 0,
    defectType: "CORROSION",
    defectTypeDescription: [],
    repairDesignLifetime: 0,
  },
  six: {
    repairCompositeId: 4,
    solutionSelection: "R4D",
    tapeType: "8020",
    tapeWidth: 100,
    resinSelection: "R3X1090",
    maximumInstallationTemperature: 95,
    minimumInstallationTemperature: 20,
    allowableStrains: "CONTINOUS",
    serviceFactor: "DESIGN_LIFE_DATA",
    serviceFactorIso: "NO",
    fillerSelection: [],
    fillerSelectionId: 1,
    fillerMaxTemp: 80,
    primerSelection: null,
    primerSelectionId: 1,
    primerMaxTemp: 14,
    materialFilter: {
      available_materials: [],
      tape_types: [],
      messages: {},
    },
    isFirstTime: true,
    materialFilterError: null,
  },
  seven: {
    tapeReference: "5050",
    typeReport: "light",
    reportStandart: "ISO",
    reportType: "DOCX",
    result: null,
    error: "",
  },
  nine: {
    conditions: [],
    missingText: "",
    reportType: "DOCX",
    repairCompositeIds: [],
  },
  loading: false,
  error: null,
};
