export function unique<T>(arr: Array<T> = []): Array<T> {
  let result: Array<T> = [];
  for (let str of arr) {
    if (!result.includes(str)) {
      result.push(str);
    }
  }

  return result;
};
