import { Form } from "react-bootstrap";

import {
  TailorMadeMain as TTailorMadeMain,
  TailorMadeErrors,
} from "features/tailor-made/tailor-made.slice.type";

import LtaperIncluded from "assets/images/ltaper_included.png";
import LtaperExcluded from "assets/images/ltaper_excluded.png";
import LtaperNo from "assets/images/ltaper_no.png";

import "./tailor-made-main.scss";

type TailorMadeMainProps = {
  form: TTailorMadeMain;
  setField: Function;
  errors: TailorMadeErrors;
  ltaperResults: number;
};

const TailorMadeMain = ({
  form,
  setField,
  errors,
  ltaperResults,
}: TailorMadeMainProps) => {
  return (
    <div className="tailor-made-main">
      <Form className="form-tailor-made-main">
        <div className="main-first-block">
          <Form.Group controlId="formFieldOption" className="formFieldOption">
            <Form.Label>Option</Form.Label>
            <Form.Select
              aria-label="Default select ltaper"
              value={form.option}
              onChange={(e) => {
                setField("option", e.target.value);
              }}
            >
              <option value="LTAPER_INCLUDED">
                Ltaper included in length chosen for repair
              </option>
              <option value="LTAPER_EXCLUDED">
                Ltaper excluded from length chosen for repair
              </option>
              <option value="LTAPER_NO">No Ltaper</option>
            </Form.Select>
          </Form.Group>
          <Form.Group
            controlId="formFieldRepairLength"
            className="formFieldRepairLength"
          >
            <Form.Label>Chosen length of repair</Form.Label>
            <div className="formFieldRepairLength_wrap-control">
              <div className="formFieldRepairLength_wrap-number">
                <Form.Control
                  type="number"
                  min={0}
                  value={form.repairLength || ""}
                  onChange={(e) =>
                    setField("repairLength", parseFloat(e.target.value))
                  }
                  isInvalid={!!errors.repairLength}
                />
              </div>
              <Form.Text>mm</Form.Text>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: !!errors.repairLength ? "block" : "none" }}
              >
                {errors.repairLength}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
        </div>
        <div className="main-second-block">
          <Form.Group
            controlId="formFieldNumberLayers"
            className="formFieldNumberLayers"
          >
            <Form.Label>Number of imposed layers</Form.Label>
            <div className="formFieldNumberLayers_wrap-control">
              <div className="formFieldNumberLayers_wrap-number">
                <Form.Control
                  type="number"
                  min={0}
                  value={form.numberLayers || ""}
                  onChange={(e) =>
                    setField("numberLayers", parseInt(e.target.value))
                  }
                  isInvalid={!!errors.numberLayers}
                />
                <Form.Text>mm</Form.Text>
              </div>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: !!errors.numberLayers ? "block" : "none" }}
              >
                {errors.numberLayers}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group controlId="formFieldLtaper" className="formFieldLtaper">
            <Form.Label>Ltaper</Form.Label>
            <div className="formFieldLtaper_wrap-control">
              <div className="formFieldLtaper_wrap-number">
                <Form.Control
                  type="number"
                  min={0}
                  value={ltaperResults || form.ltaper || ""}
                  onChange={(e) => setField("ltaper", parseInt(e.target.value))}
                  isInvalid={!!errors.ltaper}
                />
                <Form.Text>mm</Form.Text>
              </div>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: !!errors.ltaper ? "block" : "none" }}
              >
                {errors.ltaper}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
        </div>
        <div className="main-third-block">
          {form.option === "LTAPER_INCLUDED" && (
            <img src={LtaperIncluded} alt="view repair pipe" />
          )}
          {form.option === "LTAPER_EXCLUDED" && (
            <img src={LtaperExcluded} alt="view repair pipe" />
          )}
          {form.option === "LTAPER_NO" && (
            <img src={LtaperNo} alt="view repair pipe" />
          )}
        </div>
      </Form>
    </div>
  );
};

export default TailorMadeMain;
