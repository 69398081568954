type ISvgProps = {
    isHover?: boolean;
  };
  
const ArchiveOpenSvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2297_60381)">
          <path d="M9.1826 9.25055L18.0756 6.21947C19.112 5.86733 19.6695 4.738 19.3184 3.7009L18.7207 1.93536C18.4474
            1.12802 17.691 0.585518 16.8388 0.585449H16.8387H16.8385C16.6216 0.585449 16.4072 0.620802 16.2013
            0.69054L16.1999 0.691024L16.1985 0.691508L2.09721 5.49775C1.05993 5.84937 0.501895 6.97918 0.853142
            8.0167L1.45089 9.78223C1.65941 10.3982 2.14913 10.8596 2.74798 11.044V19.2522C2.74798 20.3612 3.65021
            21.2634 4.75916 21.2634H17.9542C19.0632 21.2634 19.9654 20.3612 19.9654
            19.2522V10.7325V9.25055H18.4835H9.1826ZM18.4835 19.2522C18.4835 19.5445 18.2465
            19.7814 17.9542 19.7814H4.75916C4.46686 19.7814 4.2299 19.5445 4.2299 19.2522V10.9386L4.83467
            10.7325H18.4834V19.2522H18.4835ZM2.57326 6.90111L16.6766 2.09418C16.7303 2.07602 16.785 2.06737
            16.8387 2.06737C17.0493 2.06737 17.2458 2.2001 17.3171 2.41062L17.9148 4.17613C18.0043 4.44034 17.8625
            4.72707 17.5984 4.81656L4.58911 9.25055L3.49496 9.62346C3.44128 9.64162 3.38662 9.65026 3.33293
            9.65026C3.12234 9.65026 2.92582 9.51753 2.85453 9.30701L2.83543 9.25055L2.25678 7.54151C2.16736 7.27726
            2.30905 6.99056 2.57326 6.90111Z" fill="#a80d10"
          />
          <path d="M17.9539 21.2809H4.75884C3.64034 21.2809 2.73036 20.3709 2.73036 19.2524V11.0569C2.12268
            10.8655 1.63867 10.392 1.4342 9.78797L0.83645 8.02243C0.482193 6.97603 1.04514 5.83619 2.09134
            5.48155L16.1926 0.675318C16.4031 0.604058 16.6194 0.568359 16.8382 0.568359C17.6982 0.568429 18.4611
            1.11564 18.7368 1.93004L19.3345 3.69557C19.6885 4.74125 19.1262 5.88088 18.0809 6.23604L9.28665
            9.23343H19.9824V19.2524C19.9824 20.3709 19.0724 21.2809 17.9539 21.2809ZM16.8385 0.602951C16.6231
            0.602951 16.4106 0.638028 16.2066 0.707142L2.10245 5.51431C1.07427 5.86286 0.521074 6.98302 0.869208
            8.01137L1.46696 9.7769C1.66925 10.3745 2.14994 10.8421 2.75274 11.0277L2.76495 11.0314V19.2524C2.76495
            20.3518 3.6594 21.2462 4.75884 21.2462H17.9539C19.0533 21.2462 19.9478 20.3518 19.9478
            19.2524V9.26805H9.07796L18.0698 6.20331C19.097 5.85428 19.6497 4.7343 19.3018 3.70668L18.704 1.94114C18.4331
            1.14079 17.6834 0.60302 16.8385 0.602951ZM17.9539 19.7989H4.75884C4.45747 19.7989 4.21228 19.5538 4.21228
            19.2524V10.9265L4.83435 10.7154H18.5004V19.2524C18.5005 19.5538 18.2553 19.7989 17.9539 19.7989ZM4.24691
            10.9512V19.2524C4.24691 19.5347 4.47657 19.7644 4.75887 19.7644H17.954C18.2363 19.7644 18.4659 19.5347
            18.4659 19.2524V10.75H4.83726L4.24691 10.9512ZM3.33261 9.66776C3.10856 9.66776 2.90972 9.52507 2.83784
            9.31275L2.24009 7.54721C2.19536 7.41507 2.20477 7.27338 2.26655 7.1483C2.32837 7.02321 2.43519 6.92968
            2.5674 6.88491L16.6707 2.07802C16.7251 2.05958 16.7815 2.05028 16.8384 2.05028C17.0624 2.05028 17.2612
            2.19293 17.3331 2.40529L17.9309 4.17083C17.9756 4.30297 17.9662 4.44466 17.9044 4.56978C17.8426
            4.6949 17.7358 4.78844 17.6036 4.8332L3.50021 9.64009C3.44583 9.65843 3.38944 9.66776 3.33261
            9.66776ZM16.8384 2.08483C16.7853 2.08483 16.7327 2.09355 16.6819 2.11074L2.57854 6.91767C2.45508 6.95946
            2.35532 7.0468 2.29758 7.16362C2.23988 7.28044 2.2311 7.41272 2.27288 7.53614L2.87063 9.30168C2.93778
            9.49996 3.12347 9.63317 3.33264 9.63317C3.38567 9.63317 3.43832 9.62446 3.48914 9.60726L17.5925
            4.80037C17.7159 4.75858 17.8157 4.6712 17.8734 4.55439C17.9312 4.43757 17.9399 4.30526 17.8981
            4.18187L17.3004 2.41633C17.2332 2.21805 17.0475 2.08483 16.8384 2.08483Z" fill="#a80d10"
          />
          <path d="M14.4824 18.3696H16.1472C16.4395 18.3696 16.6765 18.1327 16.6765 17.8404V17.4633C16.6765 17.171
            16.4395 16.9341 16.1472 16.9341H14.4824C14.1901 16.9341 13.9531 17.171 13.9531 17.4633V17.8404C13.9531
            18.1327 14.1901 18.3696 14.4824 18.3696Z" fill="#a80d10"
          />
          <path d="M16.1469 18.3871H14.4821C14.1807 18.3871 13.9355 18.1419 13.9355 17.8406V17.4635C13.9355
            17.1622 14.1807 16.917 14.4821 16.917H16.1469C16.4483 16.917 16.6935 17.1622 16.6935 17.4635V17.8406C16.6935
            18.1419 16.4483 18.3871 16.1469 18.3871ZM14.4821 16.9516C14.1998 16.9516 13.9701 17.1813 13.9701
            17.4635V17.8406C13.9701 18.1228 14.1998 18.3525 14.4821 18.3525H16.1469C16.4292 18.3525 16.6589 18.1228
            16.6589 17.8406V17.4635C16.6589 17.1813 16.4292 16.9516 16.1469 16.9516H14.4821Z" fill="#a80d10"
          />
        </g>
        <defs>
          <clipPath id="clip0_2297_60381">
            <rect width="20.7125" height="20.7125" fill="white" transform="translate(0 0.568359)"/>
          </clipPath>
        </defs>
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2297_60381)">
        <path d="M9.1826 9.25055L18.0756 6.21947C19.112 5.86733 19.6695 4.738 19.3184 3.7009L18.7207 1.93536C18.4474
          1.12802 17.691 0.585518 16.8388 0.585449H16.8387H16.8385C16.6216 0.585449 16.4072 0.620802 16.2013
          0.69054L16.1999 0.691024L16.1985 0.691508L2.09721 5.49775C1.05993 5.84937 0.501895 6.97918 0.853142
          8.0167L1.45089 9.78223C1.65941 10.3982 2.14913 10.8596 2.74798 11.044V19.2522C2.74798 20.3612 3.65021
          21.2634 4.75916 21.2634H17.9542C19.0632 21.2634 19.9654 20.3612 19.9654
          19.2522V10.7325V9.25055H18.4835H9.1826ZM18.4835 19.2522C18.4835 19.5445 18.2465
          19.7814 17.9542 19.7814H4.75916C4.46686 19.7814 4.2299 19.5445 4.2299 19.2522V10.9386L4.83467
          10.7325H18.4834V19.2522H18.4835ZM2.57326 6.90111L16.6766 2.09418C16.7303 2.07602 16.785 2.06737
          16.8387 2.06737C17.0493 2.06737 17.2458 2.2001 17.3171 2.41062L17.9148 4.17613C18.0043 4.44034 17.8625
          4.72707 17.5984 4.81656L4.58911 9.25055L3.49496 9.62346C3.44128 9.64162 3.38662 9.65026 3.33293
          9.65026C3.12234 9.65026 2.92582 9.51753 2.85453 9.30701L2.83543 9.25055L2.25678 7.54151C2.16736 7.27726
          2.30905 6.99056 2.57326 6.90111Z" fill="black"
        />
        <path d="M17.9539 21.2809H4.75884C3.64034 21.2809 2.73036 20.3709 2.73036 19.2524V11.0569C2.12268
          10.8655 1.63867 10.392 1.4342 9.78797L0.83645 8.02243C0.482193 6.97603 1.04514 5.83619 2.09134
          5.48155L16.1926 0.675318C16.4031 0.604058 16.6194 0.568359 16.8382 0.568359C17.6982 0.568429 18.4611
          1.11564 18.7368 1.93004L19.3345 3.69557C19.6885 4.74125 19.1262 5.88088 18.0809 6.23604L9.28665
          9.23343H19.9824V19.2524C19.9824 20.3709 19.0724 21.2809 17.9539 21.2809ZM16.8385 0.602951C16.6231
          0.602951 16.4106 0.638028 16.2066 0.707142L2.10245 5.51431C1.07427 5.86286 0.521074 6.98302 0.869208
          8.01137L1.46696 9.7769C1.66925 10.3745 2.14994 10.8421 2.75274 11.0277L2.76495 11.0314V19.2524C2.76495
          20.3518 3.6594 21.2462 4.75884 21.2462H17.9539C19.0533 21.2462 19.9478 20.3518 19.9478
          19.2524V9.26805H9.07796L18.0698 6.20331C19.097 5.85428 19.6497 4.7343 19.3018 3.70668L18.704 1.94114C18.4331
          1.14079 17.6834 0.60302 16.8385 0.602951ZM17.9539 19.7989H4.75884C4.45747 19.7989 4.21228 19.5538 4.21228
          19.2524V10.9265L4.83435 10.7154H18.5004V19.2524C18.5005 19.5538 18.2553 19.7989 17.9539 19.7989ZM4.24691
          10.9512V19.2524C4.24691 19.5347 4.47657 19.7644 4.75887 19.7644H17.954C18.2363 19.7644 18.4659 19.5347
          18.4659 19.2524V10.75H4.83726L4.24691 10.9512ZM3.33261 9.66776C3.10856 9.66776 2.90972 9.52507 2.83784
          9.31275L2.24009 7.54721C2.19536 7.41507 2.20477 7.27338 2.26655 7.1483C2.32837 7.02321 2.43519 6.92968
          2.5674 6.88491L16.6707 2.07802C16.7251 2.05958 16.7815 2.05028 16.8384 2.05028C17.0624 2.05028 17.2612
          2.19293 17.3331 2.40529L17.9309 4.17083C17.9756 4.30297 17.9662 4.44466 17.9044 4.56978C17.8426
          4.6949 17.7358 4.78844 17.6036 4.8332L3.50021 9.64009C3.44583 9.65843 3.38944 9.66776 3.33261
          9.66776ZM16.8384 2.08483C16.7853 2.08483 16.7327 2.09355 16.6819 2.11074L2.57854 6.91767C2.45508 6.95946
          2.35532 7.0468 2.29758 7.16362C2.23988 7.28044 2.2311 7.41272 2.27288 7.53614L2.87063 9.30168C2.93778 9.49996
          3.12347 9.63317 3.33264 9.63317C3.38567 9.63317 3.43832 9.62446 3.48914 9.60726L17.5925 4.80037C17.7159
          4.75858 17.8157 4.6712 17.8734 4.55439C17.9312 4.43757 17.9399 4.30526 17.8981 4.18187L17.3004
          2.41633C17.2332 2.21805 17.0475 2.08483 16.8384 2.08483Z" fill="black"
        />
        <path d="M14.4824 18.3696H16.1472C16.4395 18.3696 16.6765 18.1327 16.6765 17.8404V17.4633C16.6765 17.171
          16.4395 16.9341 16.1472 16.9341H14.4824C14.1901 16.9341 13.9531 17.171 13.9531 17.4633V17.8404C13.9531
          18.1327 14.1901 18.3696 14.4824 18.3696Z" fill="black"
        />
        <path d="M16.1469 18.3871H14.4821C14.1807 18.3871 13.9355 18.1419 13.9355 17.8406V17.4635C13.9355
          17.1622 14.1807 16.917 14.4821 16.917H16.1469C16.4483 16.917 16.6935 17.1622 16.6935 17.4635V17.8406C16.6935
          18.1419 16.4483 18.3871 16.1469 18.3871ZM14.4821 16.9516C14.1998 16.9516 13.9701 17.1813 13.9701
          17.4635V17.8406C13.9701 18.1228 14.1998 18.3525 14.4821 18.3525H16.1469C16.4292 18.3525 16.6589 18.1228
          16.6589 17.8406V17.4635C16.6589 17.1813 16.4292 16.9516 16.1469 16.9516H14.4821Z" fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2297_60381">
          <rect width="20.7125" height="20.7125" fill="white" transform="translate(0 0.568359)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArchiveOpenSvg;
