import { BrowserRouter } from "react-router-dom";

import AppLayout from "./app-layout";

import "./styles/all.scss";

const App = () => {
  return (
    <BrowserRouter>
      <AppLayout />
    </BrowserRouter>
  );
};

export default App;
