import { Form } from "react-bootstrap";
import { PanelHeader } from "components/library";

import "./reports-missing.scss";

type ReportsMissingProps = {
  missingText: string;
  setField: Function;
};

const ReportsMissing = ({ missingText, setField }: ReportsMissingProps) => {
  return (
    <>
      <PanelHeader className="min-height" theme="white" title="Missing information" />
      <Form.Group
        controlId="formFieldMissingText"
        className="formFieldMissingText"
      >
        <Form.Control
          as="textarea"
          rows={10}
          value={missingText}
          onChange={(e) => {
            setField(e.target.value);
          }}
        />
      </Form.Group>
    </>
  );
};

export default ReportsMissing;
