import { ModalWindow } from "components/library";

export type IModalProps = {
  modal: {
    isArchiveOpen: boolean;
    isCopyOpen: boolean;
    title: string;
  };
  setModal: (a: {
    isArchiveOpen: boolean;
    isCopyOpen: boolean;
    title: string;
  }) => void;
  onClose: () => void;
  projects: Array<string>;
  isArchive?: boolean;
  isProposal?: boolean;
};

const ArchiveModal = ({ onClose, isArchive, modal, setModal, projects }: IModalProps) => {
  const handleClose = () => setModal({
    ...modal,
    isArchiveOpen: false,
  });

  return (
    <ModalWindow
      isOpen={modal.isArchiveOpen}
      title={modal.title}
      handleClose={handleClose}
      onClose={onClose}
    >
      Are you sure that you want to {isArchive ? "unzip" : "archive"} the selected projects?
      <div className="projects-list">
        {projects && projects.map((item: string, index: number) => {
          return (
            <li key={item + index}>{item}</li>
          );
        })}
      </div>
    </ModalWindow>
  );
};

export default ArchiveModal;
