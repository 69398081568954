type ISvgProps = {
    isHover?: boolean;
  };
  
const CopySvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="20" height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2297_60310)">
          <path d="M12.1484 20.5684H4.6875C2.96432 20.5684 1.5625 19.1665 1.5625 17.4434V6.85742C1.5625 5.13425
            2.96432 3.73242 4.6875 3.73242H12.1484C13.8716 3.73242 15.2734 5.13425 15.2734 6.85742V17.4434C15.2734
            19.1665 13.8716 20.5684 12.1484 20.5684ZM4.6875 5.29492C3.82599 5.29492 3.125 5.99591 3.125
            6.85742V17.4434C3.125 18.3049 3.82599 19.0059 4.6875 19.0059H12.1484C13.0099 19.0059 13.7109 18.3049
            13.7109 17.4434V6.85742C13.7109 5.99591 13.0099 5.29492 12.1484 5.29492H4.6875ZM18.3984
            15.4902V3.69336C18.3984 1.97018 16.9966 0.568359 15.2734 0.568359H6.60156C6.17004 0.568359 5.82031
            0.918091 5.82031 1.34961C5.82031 1.78113 6.17004 2.13086 6.60156 2.13086H15.2734C16.1349 2.13086
            16.8359 2.83185 16.8359 3.69336V15.4902C16.8359 15.9218 17.1857 16.2715 17.6172 
            16.2715C18.0487 16.2715 18.3984 15.9218 18.3984 15.4902Z" fill="#a80d10"
          />
        </g>
        <defs>
          <clipPath id="clip0_2297_60310">
            <rect width="20" height="20" fill="white" transform="translate(0 0.568359)"/>
          </clipPath>
        </defs>
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="20" height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2297_60310)">
        <path d="M12.1484 20.5684H4.6875C2.96432 20.5684 1.5625 19.1665 1.5625 17.4434V6.85742C1.5625 5.13425
          2.96432 3.73242 4.6875 3.73242H12.1484C13.8716 3.73242 15.2734 5.13425 15.2734 6.85742V17.4434C15.2734
          19.1665 13.8716 20.5684 12.1484 20.5684ZM4.6875 5.29492C3.82599 5.29492 3.125 5.99591 3.125
          6.85742V17.4434C3.125 18.3049 3.82599 19.0059 4.6875 19.0059H12.1484C13.0099 19.0059 13.7109
          18.3049 13.7109 17.4434V6.85742C13.7109 5.99591 13.0099 5.29492 12.1484 5.29492H4.6875ZM18.3984
          15.4902V3.69336C18.3984 1.97018 16.9966 0.568359 15.2734 0.568359H6.60156C6.17004 0.568359 5.82031
          0.918091 5.82031 1.34961C5.82031 1.78113 6.17004 2.13086 6.60156 2.13086H15.2734C16.1349 2.13086
          16.8359 2.83185 16.8359 3.69336V15.4902C16.8359 15.9218 17.1857 16.2715 17.6172
          16.2715C18.0487 16.2715 18.3984 15.9218 18.3984 15.4902Z" fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2297_60310">
          <rect width="20" height="20" fill="white" transform="translate(0 0.568359)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopySvg;
