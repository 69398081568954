type ISvgProps = {
    isHover?: boolean;
  };
  
const UnzipSvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="26" height="19"
        viewBox="0 0 26 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.0797 8.26511C12.7983 8.00984 12.369 8.00979 12.0876 8.26511L9.88998 10.2584C9.58794
          10.5324 9.56515 10.9993 9.83913 11.3014C10.1131 11.6034 10.5801 11.6262 10.8821 11.3522L11.8453
          10.4786L11.8452 14.1445C11.8452 14.5522 12.1758 14.8828 12.5836 14.8828C12.9914 14.8828 13.3219
          14.5522 13.3219 14.1445L13.3219 10.4786L14.2851 11.3522C14.5872 11.6261 15.0541 11.6034 15.3281
          11.3013C15.602 10.9993 15.5793 10.5324 15.2772 10.2584L13.0797 8.26511Z" fill="#A80D10"
        />
        <path d="M24.9496 0.18254C24.6426 -0.0858718 24.1761 -0.0545166 23.9077 0.252387L20.6377
          3.99248H4.56428L1.29422 0.252387C1.0258 -0.054615 0.559369 -0.085921 0.252367 0.18254C-0.0546351
          0.450951 -0.0858915 0.917386 0.18252 1.22439L3.49044 5.00775V17.2772C3.49044 18.2272 4.26323
          19 5.21323 19H19.9889C20.9389 19 21.7117 18.2272 21.7117 17.2772V5.0076L25.0195 1.22434C25.2878
          0.917387 25.2566 0.450902 24.9496 0.18254ZM20.235 17.2772C20.235 17.4131 20.1242 17.5233 19.9889
          17.5233H5.21318C5.07782 17.5233 4.96707 17.4131 4.96707 17.2772V5.46916H20.235V17.2772Z" fill="#A80D10"
        />
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="26" height="19"
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.0797 8.26511C12.7983 8.00984 12.369 8.00979 12.0876 8.26511L9.88998 10.2584C9.58794
        10.5324 9.56515 10.9993 9.83913 11.3014C10.1131 11.6034 10.5801 11.6262 10.8821 11.3522L11.8453
        10.4786L11.8452 14.1445C11.8452 14.5522 12.1758 14.8828 12.5836 14.8828C12.9914 14.8828 13.3219
        14.5522 13.3219 14.1445L13.3219 10.4786L14.2851 11.3522C14.5872 11.6261 15.0541 11.6034 15.3281
        11.3013C15.602 10.9993 15.5793 10.5324 15.2772 10.2584L13.0797 8.26511Z" fill="black"
      />
      <path d="M24.9496 0.18254C24.6426 -0.0858718 24.1761 -0.0545166 23.9077 0.252387L20.6377
        3.99248H4.56428L1.29422 0.252387C1.0258 -0.054615 0.559369 -0.085921 0.252367 0.18254C-0.0546351
        0.450951 -0.0858915 0.917386 0.18252 1.22439L3.49044 5.00775V17.2772C3.49044 18.2272 4.26323
        19 5.21323 19H19.9889C20.9389 19 21.7117 18.2272 21.7117 17.2772V5.0076L25.0195 1.22434C25.2878
        0.917387 25.2566 0.450902 24.9496 0.18254ZM20.235 17.2772C20.235 17.4131 20.1242 17.5233 19.9889
        17.5233H5.21318C5.07782 17.5233 4.96707 17.4131 4.96707 17.2772V5.46916H20.235V17.2772Z" fill="black"
      />
    </svg>
  );
};

export default UnzipSvg;
