import { Button as ButtonRB } from "react-bootstrap";

import "./button.scss";

type ButtonProps = {
  className: string;
  text: string;
  onClick: Function;
};

const Button = ({ className, text, onClick }: ButtonProps) => {
  return (
    <ButtonRB className={`${className}`} onClick={() => onClick()}>
      {text}
    </ButtonRB>
  );
};

export default Button;
