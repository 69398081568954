import { apiUrl } from "constants/index";

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  AnyAction,
} from "@reduxjs/toolkit";
import FileSaver from "file-saver";

import { getFetchProp } from "helpers/getFetchProp";

import { LoginUserState } from "features/auth/auth-slice";

import TailorMadeState, {
  TailorMadeMaterial,
} from "features/tailor-made/tailor-made.slice.type";

import ProjectCreateState, {
  calculateMawpInput,
  fetchDetailInput,
  fetchMaopInput,
  fetchAllProjectsInput,
  Content,
  ProposalTableData,
  CopyData,
  saveProjectDataProps,
  changeStatusProps,
  EditProps,
  FetchFilterFillerProps,
  TailorValuesProps,
  FetchMaterialFilter,
} from "./project-create.slice.type";
import { AddGradeValues } from "./pipe-info/pipe-info";
import { initialState } from "./project-create.init.state";

export const calculation = createAsyncThunk(
  "calculation",
  async (tailorValues: TailorValuesProps, { getState }) => {
    const { tailorOption, tailorNumberLayers, tailorRepairLength } =
      tailorValues;
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };
    const { tailorMade } = getState() as {
      tailorMade: TailorMadeState;
    };
    const { reasonRepair } = tailorMade.footer;
    const { projectId } = projectCreate.one;
    const { proposalName } = projectCreate.two;
    const {
      techProposal: {
        tech_proposal: { id: techId },
      },
    } = projectCreate.two;
    const {
      typePipe,
      connection,
      fluid,
      network,
      environmental,
      originalDiameterMm,
      wallThicknessMm,
      mediumRadiusMm,
      openingAngleMm,
      branchDiameterMm,
      wallThicknessTbMm,
      grade,
      smysMPa,
      mby,
      thermalExpansion,
    } = projectCreate.three;
    const {
      designFactorClass,
      designFactorOther,
      repairClass,
      designTemperatureC,
      designPressureMPa,
      installationTemperatureC,
      installationPressureMPa,
      optionalDesignConsideration,
      impact,
      nLoadingCycles,
      pMinPressure,
      pMaxPressure,
      appliedFsh,
      appliedMto,
      appliedFax,
      appliedMax,
    } = projectCreate.four;

    const {
      bendDefectLocation,
      defectAxialLengthMm,
      defectCircumferentialLengthMm,
      defectLocation,
      defectOrigin,
      defectType,
      defectTypeReport,
      metalLossMm,
      isBendLocalisationDefect,
      isLimitedWrappingSpace,
      mawpValue,
      repairDesignLifetime,
      lAvailableDownstreamMm,
      lAvailableUpstreamMm,
      gammaLcl,
      isCustomValue,
    } = projectCreate.five;

    const {
      allowableStrains,
      fillerSelectionId,
      primerSelectionId,
      serviceFactor,
      serviceFactorIso,
      repairCompositeId,
    } = projectCreate.six;

    const { conditions, missingText } = projectCreate.nine;

    const getValueMRorBD = () => {
      let result = 0;

      if (typePipe === "BEND") result = openingAngleMm;
      if (typePipe === "TEE") result = branchDiameterMm;

      return result;
    };

    const getValueOABorWT = () => {
      let result = 0;

      if (typePipe === "BEND") result = mediumRadiusMm;
      if (typePipe === "TEE") result = wallThicknessTbMm;

      return result;
    };

    const query = `project_id=${projectId}&tech_proposal_id=${techId}&tech_proposal_name=${proposalName}`;

    const res = await fetch(`${apiUrl}/rea/v1.0/api/calculation?${query}`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "POST",
        contentType: "application/json",
        body: JSON.stringify({
          allowable_strains: allowableStrains,
          alpha_s: thermalExpansion,
          available_downstream: lAvailableDownstreamMm,
          available_upstream: lAvailableUpstreamMm,
          axial_load: appliedFax,
          axial_moment: appliedMax,
          bend_defect_location: bendDefectLocation,
          bend_localization_defect: isBendLocalisationDefect,
          conditions: conditions.length > 0 ? conditions.join("\n") : "",
          connection: connection,
          defect_axial_length: defectAxialLengthMm,
          defect_circumferential_length: defectCircumferentialLengthMm,
          defect_depth: metalLossMm,
          defect_location: defectLocation,
          defect_origin: defectOrigin,
          defect_type: defectType,
          defect_type_report: defectTypeReport,
          design_factor: designFactorClass,
          design_factor_value: designFactorOther,
          design_pressure: designPressureMPa,
          design_temperature: designTemperatureC,
          environment: environmental,
          filler_id: fillerSelectionId,
          fluid: fluid,
          gamma_lcl: gammaLcl,
          grade_id: grade,
          grade_mby: mby,
          grade_smys: smysMPa,
          id: null,
          installation_pressure: installationPressureMPa,
          installation_temperature: installationTemperatureC,
          limited_axial_extand: isLimitedWrappingSpace,
          max_cyclic_loads_pressure: pMaxPressure,
          mawp: isCustomValue === "NO" ? null : mawpValue,
          medium_radius_bend_or_branch_diameter_tee: getValueOABorWT(),
          min_cyclic_loads_pressure: pMinPressure,
          missing_informations: true,
          missing_informations_text: missingText,
          nb_cyclic_loads: nLoadingCycles,
          network: network,
          opening_angle_bend: getValueMRorBD(),
          optional_design_consid: optionalDesignConsideration,
          pipe_diameter: originalDiameterMm,
          pipe_thickness: wallThicknessMm,
          pipe_type: typePipe,
          primer_id: primerSelectionId,
          repair_composite_id: repairCompositeId,
          repair_lifetime: repairDesignLifetime,
          security_class: repairClass,
          service_factor: serviceFactor,
          service_factor_iso: serviceFactorIso,
          sheer_load: appliedFsh,
          t_min: impact,
          tailor_made_number_of_layer: tailorNumberLayers,
          tailor_made_option: tailorOption,
          tailor_made_reason: reasonRepair,
          tailor_made_total_length_repair: tailorRepairLength,
          torsional_moment: appliedMto,
        }),
      }),
    });

    return res.json();
  }
);

export const calculateMawp = createAsyncThunk(
  "mawp",
  async (values: calculateMawpInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };
    const { connection, originalDiameterMm, wallThicknessMm, smysMPa } =
      projectCreate.three;
    const { designFactorOther, designTemperatureC } = projectCreate.four;

    const res = await fetch(`${apiUrl}/rea/v1.0/api/calculation/ps`, {
      ...getFetchProp({
        method: "PUT",
        contentType: "application/json",
        token: loginUser.token,
        body: JSON.stringify({
          connection: connection,
          defect_axial_length_mm: values.defectAxialLengthMm,
          defect_depth_mm: values.defectDepthMm,
          design_factor: designFactorOther,
          design_temperature_c: designTemperatureC,
          origrnal_diameter_mm: originalDiameterMm,
          smys_mpa: smysMPa,
          wall_thickness_mm: wallThicknessMm,
        }),
      }),
    });

    return res.json();
  }
);

export const createProject = createAsyncThunk(
  "save",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };
    const {
      client,
      project,
      projectId,
      manager,
      distributorId,
      salesManagerId,
    } = projectCreate.one;
    const { proposalName } = projectCreate.two;

    const res = await fetch(`${apiUrl}/rea/v1.0/api/project`, {
      ...getFetchProp({
        method: "POST",
        contentType: "application/json",
        token: loginUser.token,
        body: JSON.stringify({
          client: client,
          distributor_id: distributorId,
          document_technical_proposal: proposalName,
          engineer_manager: manager,
          id: projectId,
          offer_number: "1",
          project: project,
          sale_manager_id: salesManagerId,
        }),
      }),
    });

    return res.json();
  }
);

export const fetchGrades = createAsyncThunk(
  "grade",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/grade`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });
    return res.json();
  }
);

export const addGradeToList = createAsyncThunk(
  "add_grade_to_list",
  async (values: AddGradeValues, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/grade`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "POST",
        contentType: "application/json",
        body: JSON.stringify([
          {
            grade: values.name,
            id: null,
            mby: values.mby,
            smys: values.smys,
          },
        ]),
      }),
    });
    return res.json();
  }
);

export const fetchSchedules = createAsyncThunk(
  "schedule_list",
  async (material: string, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/pipe_scheduler/schedule_list?default_pipe_type=${material}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    return res.json();
  }
);

export const fetchDiameters = createAsyncThunk(
  "diameter_list",
  async (material: string, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/pipe_scheduler/diameter_list?default_pipe_type=${material}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    return res.json();
  }
);

export const fetchMaop = createAsyncThunk(
  "maop",
  async (values: fetchMaopInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };
    const { connection, originalDiameterMm, wallThicknessMm, smysMPa } =
      projectCreate.three;

    const res = await fetch(`${apiUrl}/rea/v1.0/api/calculation/maop`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "PUT",
        contentType: "application/json",
        body: JSON.stringify({
          connection: connection,
          design_factor: values.designFactorClass,
          design_factor_value: values.designFactorOther,
          design_temperature_c: values.designTemperatureC,
          origrnal_diameter_mm: originalDiameterMm,
          smys_mpa: smysMPa,
          wall_thickness_mm: wallThicknessMm,
        }),
      }),
    });

    return res.json();
  }
);

export const fetchMaterialFilter = createAsyncThunk(
  "material_filter",
  async (values: FetchMaterialFilter, { getState }) => {
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { originalDiameterInch, environmental, typePipe } =
      projectCreate.three;
    const { repairClass, designTemperatureC, installationTemperatureC } =
      projectCreate.four;
    const {
      defectOrigin,
      defectLocation,
      metalLossPercent,
      minimumResidualWallThicknessMm,
      repairDesignLifetime,
    } = projectCreate.five;

    const res = await fetch(`${apiUrl}/rea/v1.0/api/material/filter`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "PUT",
        contentType: "application/json",
        body: JSON.stringify({
          composite_width: values.width,
          defect_location: defectLocation,
          defect_type: defectOrigin,
          design_temp: +designTemperatureC,
          environment: environmental,
          install_temp: +installationTemperatureC,
          metall_loss_percent: +metalLossPercent,
          min_wall_thickness: +minimumResidualWallThicknessMm,
          pipe_diameter_inch: +originalDiameterInch,
          pipe_type: typePipe,
          repair_design_lifetime: +repairDesignLifetime,
          security_class: repairClass,
          solution_selection: values.selection,
          tape_type: values.type,
        }),
      }),
    });

    return res.json();
  }
);

export const fetchFiller = createAsyncThunk(
  "filler_list",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/filler`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });
    return res.json();
  }
);

export const fetchPrimer = createAsyncThunk(
  "primer_list",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const {
      projectCreate: {
        three: { environmental },
      },
    } = getState() as { projectCreate: ProjectCreateState };
    const {
      projectCreate: {
        four: { designTemperatureC, installationTemperatureC },
      },
    } = getState() as { projectCreate: ProjectCreateState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/primer/filter`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "PUT",
        contentType: "application/json",
        body: JSON.stringify({
          design_temperature_c: designTemperatureC,
          environment: environmental,
          installation_temperature_c: installationTemperatureC,
          material_id: id,
        }),
      }),
    });
    return res.json();
  }
);

export const fetchAllProjects = createAsyncThunk(
  "projects_list",
  async (values: fetchAllProjectsInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/project/all?page=${values.pageIndex}&size=${values.pageSize}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    return res.json();
  }
);

export const fetchArchiveProjects = createAsyncThunk(
  "fetch_archive_projects",
  async (values: fetchAllProjectsInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/project/all_archived?page=${values.pageIndex}&size=${values.pageSize}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    return res.json();
  }
);

export const archiveProjects = createAsyncThunk(
  "archive_projects",
  async (ids: Array<number>, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const {
      projectCreate: {
        one: { projectData },
      },
    } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const idsArr: Array<number> = [];
    ids.forEach((item) => {
      idsArr.push(projectData.content[item].id);
    });

    let query = "";
    idsArr.forEach((item, idx) => {
      if (idx + 1 === ids.length) {
        query += `ids=${item}`;
      } else query += `ids=${item}&`;
    });

    const res = await fetch(`${apiUrl}/rea/v1.0/api/project/archive?${query}`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "PUT",
      }),
    });
    return res.json();
  }
);

export const unzipProjects = createAsyncThunk(
  "unzip_projects",
  async (ids: Array<number>, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const {
      projectCreate: {
        one: { archiveTableData },
      },
    } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const idsArr: Array<number> = [];
    ids.forEach((item) => {
      idsArr.push(archiveTableData[item].id);
    });

    let query = "";
    idsArr.forEach((item, idx) => {
      if (idx + 1 === ids.length) {
        query += `ids=${item}`;
      } else query += `ids=${item}&`;
    });

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/project/extract_archive?${query}`,
      {
        ...getFetchProp({
          token: loginUser.token,
          method: "PUT",
        }),
      }
    );
    return res.json();
  }
);

export const copyProjects = createAsyncThunk(
  "copy_projects",
  async (ids: Array<number>, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const {
      projectCreate: {
        one: { projectData },
      },
    } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const idsArr: Array<number> = [];
    ids.forEach((item) => {
      idsArr.push(projectData.content[item].id);
    });

    let query = "";
    idsArr.forEach((item, idx) => {
      if (idx + 1 === ids.length) {
        query += `ids=${item}`;
      } else query += `ids=${item}&`;
    });

    const res = await fetch(`${apiUrl}/rea/v1.0/api/project/copy?${query}`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "PUT",
      }),
    });
    return res.json();
  }
);

export const fetchClassDescription = createAsyncThunk(
  "classDescription",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/description/security_class`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    return res.json();
  }
);

export const fetchСonditionDescription = createAsyncThunk(
  "сonditionDescription",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/description/condition`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });
    return res.json();
  }
);

export const fetchDefectTypeDescription = createAsyncThunk(
  "defectTypeDescription",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/description/defect_type`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });
    return res.json();
  }
);

export const fetchDegignFactorDescription = createAsyncThunk(
  "degignFactorDescription",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/description/degign_factor`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    return res.json();
  }
);

export const fetchMissingInformationDescription = createAsyncThunk(
  "missingInformationDescription",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/description/missing_information`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );

    return res.text();
  }
);

export const fetchProposalsById = createAsyncThunk(
  "proposalById",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/project?id=${id}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    const result = await res.json();
    return {
      result,
      id,
    };
  }
);

export const fetchTechProposalById = createAsyncThunk(
  "techProposalById",
  async (id: number | null, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/tech_proposal?id=${id}`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const fetchAvailableStatusById = createAsyncThunk(
  "availableStatusById",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/available_status?id=${id}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );
    const responce = await res.json();
    return {
      result: responce,
      id: id,
    };
  }
);

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (
    {
      id,
      rejected_reason,
      repair_reference,
      status,
      tech_proposal_name,
      status_description,
      date,
    }: changeStatusProps,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const query = `id=${id}&rejected_reason=${rejected_reason}&repair_reference=${repair_reference}&status=${status}`;
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/status?${query}`,
      {
        ...getFetchProp({
          token: loginUser.token,
          method: "PUT",
          contentType: "application/json",
          body: JSON.stringify({
            id: id,
            tech_proposal_name: tech_proposal_name,
            repair_reference: repair_reference,
            status: status,
            status_description: status_description,
            date: date,
          }),
        }),
      }
    );

    return res.json();
  }
);

export const copyProposal = createAsyncThunk(
  "copy_proposal",
  async (ids: Array<number>, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const {
      projectCreate: {
        two: { projectData },
      },
    } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const idsArr: Array<number> = [];
    ids.forEach((item) => {
      idsArr.push(projectData[item].id);
    });

    let query = "";
    idsArr.forEach((item, idx) => {
      if (idx + 1 === ids.length) {
        query += `ids=${item}`;
      } else query += `ids=${item}&`;
    });

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/copy?${query}`,
      {
        ...getFetchProp({
          token: loginUser.token,
          method: "PUT",
        }),
      }
    );
    return res.json();
  }
);

export const saveProjectData = createAsyncThunk(
  "project_data_save",
  async (
    { index, id, client, engineer_manager, project }: saveProjectDataProps,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const { content } = projectCreate.one.projectData;

    const res = await fetch(`${apiUrl}/rea/v1.0/api/project`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "POST",
        contentType: "application/json",
        body: JSON.stringify({
          client: client,
          distributor_id: content[index].distributor_id,
          document_technical_proposal:
            content[index].document_technical_proposal,
          engineer_manager: engineer_manager,
          id: id,
          offer_number: content[index].offer_number,
          project: project,
          sale_manager_id: content[index].sale_manager_id,
        }),
      }),
    });

    return res.json();
  }
);

export const fetchGeneral = createAsyncThunk(
  "general",
  async (reportType: string, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const { tapeType } = projectCreate.six;
    const { result, reportStandart } = projectCreate.seven;
    const { conditions, missingText } = projectCreate.nine;

    if (result !== null && result.id > 0) {
      let filename = "report.zip";

      const query1 = `project_id=${result.id}&report_type=${reportType}&`;
      const query2 = `selected_tape_type=${tapeType}&standard=${reportStandart}`;

      const response = await fetch(
        `${apiUrl}/rea/v1.0/api/report/general?${query1}${query2}`,
        {
          ...getFetchProp({
            method: "POST",
            token: loginUser.token,
            contentType: "application/json",
            body: JSON.stringify({
              condition: conditions.length > 0 ? conditions.join("\n") : "",
              missing_information: missingText,
            }),
          }),
        }
      );

      if (!response.ok && response.status !== 200)
        throw new Error("Some error happend");

      const blob = await response.blob();

      if (response !== null && response.headers !== null) {
        const header_with_name = response?.headers?.get("Content-Disposition");
        if (header_with_name !== null) {
          const name = header_with_name.split("=").pop();

          if (name) {
            filename = name.substring(1, name.length - 1);
          }
        }
      }

      FileSaver.saveAs(blob, filename);
    }
  }
);

export const fetchDetail = createAsyncThunk(
  "detail",
  async (values: fetchDetailInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const { tapeType } = projectCreate.six;
    const { result } = projectCreate.seven;

    if (result !== null && result.id > 0) {
      let filename = "detail.zip";

      const subQuery1 = `project_id=${result.id}&selected_tape_type=${tapeType}&`;
      const subQuery2 = `report_type=${values.reportType}&standard=${values.reportStandart}`;
      const query = `${subQuery1}${subQuery2}`;

      const response = await fetch(
        `${apiUrl}/rea/v1.0/api/report/detail?${query}`,
        {
          ...getFetchProp({
            method: "GET",
            token: loginUser.token,
          }),
        }
      );

      if (!response.ok && response.status !== 200)
        throw new Error("Some error happend");

      const blob = await response.blob();

      if (response !== null && response.headers !== null) {
        const header_with_name = response?.headers?.get("Content-Disposition");
        if (header_with_name !== null) {
          const name = header_with_name.split("=").pop();

          if (name) {
            filename = name.substring(1, name.length - 1);
          }
        }
      }

      FileSaver.saveAs(blob, filename);
    }
  }
);

export const fetchResultsToFile = createAsyncThunk(
  "lotOfComposite",
  async (values: { repairCompositeIds: number[] }, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const { result } = projectCreate.seven;

    if (result !== null && result.id > 0) {
      let filename = "resultstofile.zip";

      const repairCompositeIdsToString = values.repairCompositeIds
        .map((item: number) => {
          return `&repair_composite_ids=${item}`;
        })
        .join("");

      const query = `project_id=${result.id}&${repairCompositeIdsToString}`;

      const response = await fetch(
        `${apiUrl}/rea/v1.0/api/report/lotcomposite?${query}`,
        {
          ...getFetchProp({
            method: "GET",
            token: loginUser.token,
          }),
        }
      );

      if (!response.ok && response.status !== 200)
        throw new Error("Some error happend");

      const blob = await response.blob();

      if (response !== null && response.headers !== null) {
        const header_with_name = response?.headers?.get("Content-Disposition");
        if (header_with_name !== null) {
          const name = header_with_name.split("=").pop();

          if (name) {
            filename = name.substring(1, name.length - 1);
          }
        }
      }

      FileSaver.saveAs(blob, filename);
    }
  }
);

export const exportProjectFile = createAsyncThunk(
  "exportProjectFile",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const {
      projectCreate: {
        one: {
          projectData: { content },
        },
      },
    } = getState() as { projectCreate: ProjectCreateState };
    const projectId = content[id].id;

    let filename = "export-file.zip";

    const response = await fetch(
      `${apiUrl}/rea/v1.0/api/report/export_project?project_id=${projectId}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );

    if (!response.ok && response.status !== 200)
      throw new Error("Some error happend");

    const blob = await response.blob();

    if (response !== null && response.headers !== null) {
      const header_with_name = response?.headers?.get("Content-Disposition");
      if (header_with_name !== null) {
        const name = header_with_name.split("=").pop();

        if (name) {
          filename = name.substring(1, name.length - 1);
        }
      }
    }

    FileSaver.saveAs(blob, filename);
  }
);

export const fetchSalesManager = createAsyncThunk(
  "fetch_sales_manager",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/sale_manager`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const fetchDistributors = createAsyncThunk(
  "fetch_distributors",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/distributor`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const editProposalName = createAsyncThunk(
  "edit_proposal_name",
  async ({ id, name }: EditProps, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/change_name?id=${id}&name=${name}`,
      {
        ...getFetchProp({
          method: "PUT",
          token: loginUser.token,
        }),
      }
    );

    return {
      name: name,
      id: id,
    };
  }
);

export const deleteGrade = createAsyncThunk(
  "delete_grade",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    await fetch(`${apiUrl}/rea/v1.0/api/grade?id=${id}`, {
      ...getFetchProp({
        method: "DELETE",
        token: loginUser.token,
      }),
    });

    return { id };
  }
);

export const fetchFilterFiller = createAsyncThunk(
  "fetch_filter_filler",
  async (values: FetchFilterFillerProps, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/filler/filter`, {
      ...getFetchProp({
        token: loginUser.token,
        method: "PUT",
        contentType: "application/json",
        body: JSON.stringify({
          defect_location: values.defectLocation,
          defect_type: values.defectType,
          design_temperature_c: values.designTemperatureC,
          environment: values.environmental,
        }),
      }),
    });

    return res.json();
  }
);

export const getReasons = createAsyncThunk(
  "getAllReasons",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/rejected_reason`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

const projectCreateSlice = createSlice({
  name: "projectCreate",
  initialState,
  reducers: {
    resetStateProjectCreate() {
      return initialState;
    },
    addValueStep1(state, action: PayloadAction<Object>) {
      state.one = {
        ...state.one,
        ...action.payload,
      };
    },
    selectedProjectId(state, action: PayloadAction<number>) {
      const ourProject = state.one.projectData.content[action.payload];
      state.one.client = ourProject.client;
      state.one.project = ourProject.project;
      state.one.projectId = ourProject.id;
      state.one.manager = ourProject.engineer_manager;
      state.two.selectedId = ourProject.id;
    },
    archiveProjectTable(state, action: PayloadAction<Array<number>>) {
      const newTableData = [...state.one.projectData.content];
      const archiveData: Array<Content> = [];

      action.payload.forEach((item) => {
        archiveData.push(newTableData[item]);
        delete newTableData[item];
      });

      state.one.projectData.content = newTableData.filter(
        (item) => item !== undefined
      );

      state.one.archiveTableData = [
        ...state.one.archiveTableData,
        ...archiveData,
      ].sort(function (a, b) {
        return b.id - a.id;
      });
    },
    unzipProjectTable(state, action: PayloadAction<Array<number>>) {
      const newArchiveTableData = state.one.archiveTableData;
      const unzipData: Array<Content> = [];

      action.payload.forEach((item) => {
        unzipData.push(newArchiveTableData[item]);
        delete newArchiveTableData[item];
      });

      state.one.archiveTableData = newArchiveTableData.filter(
        (item) => item !== undefined
      );

      state.one.projectData.content = [
        ...state.one.projectData.content,
        ...unzipData,
      ].sort(function (a, b) {
        return b.id - a.id;
      });
    },
    addValueStep2(state, action: PayloadAction<Object>) {
      state.two = {
        ...state.two,
        ...action.payload,
      };
    },
    archiveProposalTable(state, action: PayloadAction<Array<number>>) {
      const newTableData = state.two.projectData;
      const archiveData: Array<ProposalTableData> = [];
      action.payload.forEach((item) => {
        archiveData.push(newTableData[item]);
        delete newTableData[item];
      });
      state.two.projectData = newTableData.filter((item) => item !== undefined);
      state.two.archiveTableData = [
        ...state.two.archiveTableData,
        ...archiveData,
      ];
    },
    unzipProposalTable(state, action: PayloadAction<Array<number>>) {
      const newArchiveTableData = state.two.archiveTableData;
      const unzipData: Array<ProposalTableData> = [];
      action.payload.forEach((item) => {
        unzipData.push(newArchiveTableData[item]);
        delete newArchiveTableData[item];
      });
      state.two.archiveTableData = newArchiveTableData.filter(
        (item) => item !== undefined
      );
      state.two.projectData = [...state.two.projectData, ...unzipData];
    },
    copyProjectTableItems(state, action: PayloadAction<CopyData>) {
      const arr: Array<Content> = [];

      action.payload.selectedIds.forEach((item) => {
        arr.push(state.one.projectData.content[item]);
      });

      const copyData = arr
        .map((item, idx) => {
          return {
            ...item,
            id: action.payload.newIds[idx],
            status_description:
              item.status_description !== null ? "Calculated" : null,
            status: item.status !== null ? "CALCULATED" : null,
            date: new Date().toISOString().substring(0, 10),
          };
        })
        .reverse();

      state.one.projectData.content = [
        ...copyData,
        ...state.one.projectData.content,
      ];
    },
    copyProposalTableItems(state, action: PayloadAction<CopyData>) {
      const arr: Array<ProposalTableData> = [];

      action.payload.selectedIds.forEach((item) => {
        arr.push(state.two.projectData[item]);
      });

      const copyData = arr
        .map((item, idx) => {
          return {
            ...item,
            id: action.payload.newIds[idx],
            status_description: "Calculated",
            status: "CALCULATED",
            available_status: { CALCULATED: "Calculated" },
            date: new Date().toISOString().substring(0, 10),
            repair_reference: "",
          };
        })
        .reverse();

      state.two.projectData = [...copyData, ...state.two.projectData];
    },
    completeProjectState(
      state,
      action: PayloadAction<Array<TailorMadeMaterial>>
    ) {
      const { data, tech_proposal } = state.two.techProposal;
      const residualWallThickness = parseFloat(
        (data.pipe_thickness - data.defect_depth).toFixed(2)
      );
      const metallLossP = parseFloat(
        ((data.defect_depth * 100) / data.pipe_thickness).toFixed(2)
      );
      const selectionData = action.payload.find((item) => {
        return item.id === data.repair_composite_id;
      });

      state.two.proposalName = tech_proposal.tech_proposal_name;

      state.three = {
        ...state.three,
        typePipe: data.pipe_type,
        originalDiameterMm: data.pipe_diameter,
        originalDiameterInch: parseFloat(
          (data.pipe_diameter * 0.0393701).toFixed(2)
        ),
        wallThicknessMm: data.pipe_thickness,
        wallThicknessInch: parseFloat(
          (data.pipe_thickness * 0.0393701).toFixed(2)
        ),
        mediumRadiusMm:
          data.pipe_type === "BEND"
            ? data.medium_radius_bend_or_branch_diameter_tee
            : 0,
        mediumRadiusInch:
          data.pipe_type === "BEND"
            ? parseFloat(
                (
                  data.medium_radius_bend_or_branch_diameter_tee * 0.0393701
                ).toFixed(2)
              )
            : 0,
        branchDiameterMm:
          data.pipe_type === "TEE"
            ? data.medium_radius_bend_or_branch_diameter_tee
            : 0,
        branchDiameterInch:
          data.pipe_type === "TEE"
            ? parseFloat(
                (
                  data.medium_radius_bend_or_branch_diameter_tee * 0.0393701
                ).toFixed(2)
              )
            : 0,
        openingAngleMm: data.pipe_type === "BEND" ? data.opening_angle_bend : 0,
        wallThicknessTbMm:
          data.pipe_type === "TEE" ? data.opening_angle_bend : 0,
        wallThicknessTbInch:
          data.pipe_type === "TEE"
            ? parseFloat((data.opening_angle_bend * 0.0393701).toFixed(2))
            : 0,
        grade: data.grade_id,
        smysMPa: data.grade_smys,
        mby: data.grade_mby,
        connection: data.connection,
        fluid: data.fluid,
        network: data.network,
        environmental: data.environment,
        thermalExpansion: data.alpha_s,
        chemicalProduct: data.chemical_product,
      };
      state.four = {
        ...state.four,
        designFactorClass: data.design_factor,
        designFactorOther: data.design_factor_value,
        repairClass: data.security_class,
        designTemperatureC: data.design_temperature,
        designTemperatureF: (data.design_temperature * 9) / 5 + 32,
        installationTemperatureC: data.installation_temperature,
        installationTemperatureF: (data.installation_temperature * 9) / 5 + 32,
        designPressureMPa: data.design_pressure,
        designPressurePsi: parseFloat(
          (data.design_pressure * 145.038).toFixed(2)
        ),
        designPressureBar: parseFloat((data.design_pressure * 10).toFixed(2)),
        installationPressureMPa: data.installation_pressure,
        installationPressurePsi: parseFloat(
          (data.installation_pressure * 145.038).toFixed(2)
        ),
        installationPressureBar: parseFloat(
          (data.installation_pressure * 10).toFixed(2)
        ),
        impact: data.t_min,
        nLoadingCycles: data.nb_cyclic_loads,
        pMinPressure: data.min_cyclic_loads_pressure,
        pMaxPressure: data.max_cyclic_loads_pressure,
        optionalDesignConsideration: data.optional_design_consid,
        appliedFsh: data.sheer_load,
        appliedMto: data.torsional_moment,
        appliedFax: data.axial_load,
        appliedMax: data.axial_moment,
      };
      state.five = {
        ...state.five,
        defectTypeReport: data.defect_type_report,
        defectOrigin: data.defect_origin,
        defectLocation: data.defect_location,
        defectAxialLengthMm: data.defect_axial_length,
        defectAxialLengthInch: parseFloat(
          (data.defect_axial_length * 0.0393701).toFixed(2)
        ),
        defectCircumferentialLengthMm: data.defect_circumferential_length,
        defectCircumferentialLengthInch: parseFloat(
          (data.defect_circumferential_length * 0.0393701).toFixed(2)
        ),
        minimumResidualWallThicknessMm: residualWallThickness,
        minimumResidualWallThicknessInch: parseFloat(
          (residualWallThickness * 0.0393701).toFixed(2)
        ),
        metalLossPercent: metallLossP,
        metalLossMm: data.defect_depth,
        metalLossInch: parseFloat((data.defect_depth * 0.0393701).toFixed(2)),
        gammaLcl: data.gamma_lcl,
        isBendLocalisationDefect: data.bend_localization_defect,
        bendDefectLocation: data.bend_defect_location,
        mawpValue: data.mawp,
        isLimitedWrappingSpace: data.limited_axial_extand,
        lAvailableDownstreamMm: data.available_downstream,
        lAvailableUpstreamMm: data.available_upstream,
        defectType: data.defect_type,
        repairDesignLifetime: data.repair_lifetime,
      };
      state.six = {
        ...state.six,
        repairCompositeId: data.repair_composite_id,
        solutionSelection:
          selectionData?.composite_type || state.six.solutionSelection,
        tapeType: selectionData?.tape_type || state.six.tapeType,
        tapeWidth: selectionData?.tape_width || state.six.tapeWidth,
        resinSelection: selectionData?.resin_name || state.six.resinSelection,
        maximumInstallationTemperature:
          selectionData?.maximum_installation_temperature ||
          state.six.maximumInstallationTemperature,
        minimumInstallationTemperature:
          selectionData?.minimum_installation_temperature ||
          state.six.minimumInstallationTemperature,
        allowableStrains: data.allowable_strains,
        serviceFactor: data.service_factor,
        serviceFactorIso: data.service_factor_iso,
        fillerSelectionId: data.filler_id,
        primerSelectionId: data.primer_id,
      };
      state.nine = {
        ...state.nine,
        missingText: data.missing_informations_text,
        conditions: [data.conditions],
      };
      state.two.isChanged = true;
    },
    setChangeAndLoaded(state) {
      state.two.isChanged = false;
      state.two.isLoadedProposal = false;
    },
    setIsFirstTime(state, action: PayloadAction<boolean>) {
      state.six.isFirstTime = action.payload;
    },
    setMaterialError(state) {
      state.six.materialFilterError = null;
    },
    addValueStep3(state, action: PayloadAction<Object>) {
      state.three = {
        ...state.three,
        ...action.payload,
      };
    },
    addValueStep4(state, action: PayloadAction<Object>) {
      state.four = {
        ...state.four,
        ...action.payload,
      };
    },
    addValueStep5(state, action: PayloadAction<Object>) {
      state.five = {
        ...state.five,
        ...action.payload,
      };
    },
    addValueStep6(state, action: PayloadAction<Object>) {
      state.six = {
        ...state.six,
        ...action.payload,
      };
    },
    addValueStep7(state, action: PayloadAction<Object>) {
      state.seven = {
        ...state.seven,
        ...action.payload,
        result: state.seven.result,
      };
    },
    addValueStep9(state, action: PayloadAction<Object>) {
      state.nine = {
        ...state.nine,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculation.pending, (state) => {
        state.seven.result = null;
        state.loading = true;
        state.error = null;
        state.seven.error = "";
      })
      .addCase(calculation.rejected, (state) => {
        state.seven.error = "Project calculation error!";
        state.loading = false;
      })
      .addCase(calculation.fulfilled, (state, action) => {
        if (typeof action.payload !== "number" && "error" in action.payload) {
          state.seven.error = `Project calculation error!\n    ${action.payload.error}`;
        } else {
          state.seven.result = action.payload;

          if (action.payload.result) {
            const keysResult = Object.keys(action.payload.result);
            if (keysResult.length > 0)
              state.seven.tapeReference = keysResult[0];
          }
        }

        state.loading = false;
      })
      .addCase(calculateMawp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(calculateMawp.fulfilled, (state, action) => {
        state.five.mawpValue = action.payload;
        state.loading = false;
      })
      .addCase(createProject.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.seven.error = "";
      })
      .addCase(createProject.rejected, (state) => {
        state.seven.error = "Project creation error!";
        state.loading = false;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        if (typeof action.payload !== "number" && "error" in action.payload) {
          state.seven.error = `Project creation error!\n    ${action.payload.error}`;
        } else {
          state.one.projectId = action.payload;
        }
        state.loading = false;
      })
      .addCase(fetchDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDetail.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(fetchGeneral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGeneral.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(fetchResultsToFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchResultsToFile.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(fetchGrades.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGrades.fulfilled, (state, action) => {
        state.three.grades = [...action.payload];
        state.loading = false;
      })
      .addCase(fetchMaop.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMaop.fulfilled, (state, action) => {
        state.four.maopMPa = action.payload.maop_mpa;
        state.four.maopPsi = action.payload.maop_psi;
        state.four.maopBar = action.payload.maop_bar;
        state.loading = false;
      })
      .addCase(fetchSchedules.pending, (state) => {
        state.three.loadingSchedules = true;
        state.error = null;
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.three.schedules = { ...action.payload };
        state.three.loadingSchedules = false;
      })
      .addCase(fetchDiameters.pending, (state) => {
        state.three.loadingDiameters = true;
        state.error = null;
      })
      .addCase(fetchDiameters.fulfilled, (state, action) => {
        state.three.diameters = [...action.payload];
        state.three.loadingDiameters = false;
      })
      .addCase(fetchFiller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFiller.fulfilled, (state, action) => {
        state.six.fillerSelection = action.payload;
        state.loading = false;
      })
      .addCase(fetchPrimer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPrimer.fulfilled, (state, action) => {
        state.six.primerSelection = action.payload;
        state.six.primerMaxTemp = action.payload[0]?.max_temp || 0;
        state.loading = false;
      })
      .addCase(fetchPrimer.rejected, (state) => {
        state.six.primerSelection = null;
        state.six.primerMaxTemp = 0;
        state.loading = false;
      })
      .addCase(fetchAllProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.one.projectData = action.payload;
        state.loading = false;
      })
      .addCase(fetchArchiveProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchArchiveProjects.fulfilled, (state, action) => {
        state.one.archiveTableData = action.payload.content;
        state.error = null;
      })
      .addCase(archiveProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(archiveProjects.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(unzipProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unzipProjects.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(copyProjects.pending, (state) => {
        state.one.isIdsLoaded = false;
        state.error = null;
      })
      .addCase(copyProjects.fulfilled, (state, action) => {
        state.one.newIds = action.payload;
        state.one.isIdsLoaded = true;
        state.error = null;
      })
      .addCase(fetchMaterialFilter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMaterialFilter.fulfilled, (state, action) => {
        state.six.materialFilter = action.payload;
        state.loading = false;
      })
      .addCase(fetchMaterialFilter.rejected, (state) => {
        state.six.materialFilterError =
          "Please, choose another solution selection.";
        state.loading = false;
      })
      .addCase(fetchProposalsById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProposalsById.fulfilled, (state, action) => {
        const obj = state.one.projectData.content.find(
          (item) => item.id === action.payload.id
        );

        const newProjectData = action.payload.result.map((item: any) => {
          return {
            ...item,
            offer_number: obj?.offer_number,
            // available_status: {
            //   [item.status]: item.status_description,
            // },
          };
        });

        state.two.projectData = newProjectData;
        state.loading = false;
      })
      .addCase(fetchTechProposalById.fulfilled, (state, action) => {
        state.two.techProposal = action.payload;
        state.loading = false;
        state.two.isLoadedProposal = true;
      })
      .addCase(fetchTechProposalById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.two.isLoadedProposal = false;
      })
      .addCase(copyProposal.pending, (state) => {
        state.two.isIdsLoaded = false;
        state.error = null;
      })
      .addCase(copyProposal.fulfilled, (state, action) => {
        state.two.newIds = action.payload;
        state.two.isIdsLoaded = true;
        state.error = null;
      })
      .addCase(fetchAvailableStatusById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAvailableStatusById.fulfilled, (state, action) => {
        const newProjectData = state.two.projectData.map((item) => {
          if (item.id === action.payload.id) {
            const lastKey = Object.keys(item.available_status || {})[
              Object.keys(item.available_status || {}).length - 1
            ];
            const lastValue = Object.values(item.available_status || {})[
              Object.values(item.available_status || {}).length - 1
            ];
            return {
              ...item,
              available_status: {
                [lastKey]: lastValue,
                ...action.payload.result,
              },
            };
          }
          return item;
        });
        state.two.projectData = newProjectData;
        state.loading = false;
      })
      .addCase(changeStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        const oldTechProposal = state.two.projectData.find(
          (item) => item.id === action.payload.id
        );

        const index = state.two.projectData.findIndex(
          (item) => item.id === action.payload.id
        );

        state.two.projectData[index] = {
          ...oldTechProposal,
          ...action.payload,
        };

        state.loading = false;
      })
      .addCase(saveProjectData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProjectData.fulfilled, (state, action) => {
        state.one.projectData.content[action.meta.arg.index] = {
          ...state.one.projectData.content[action.meta.arg.index],
          client: action.meta.arg.client,
          engineer_manager: action.meta.arg.engineer_manager,
          project: action.meta.arg.project,
        };

        state.loading = false;
      })
      .addCase(exportProjectFile.pending, (state) => {
        state.one.isExportLoading = true;
      })
      .addCase(exportProjectFile.fulfilled, (state) => {
        state.one.isExportLoading = false;
      })
      .addCase(exportProjectFile.rejected, (state) => {
        state.error = "Downloading error!";
        state.one.isExportLoading = false;
      })
      .addCase(fetchClassDescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassDescription.fulfilled, (state, action) => {
        state.loading = false;
        state.four.classDescription = action.payload;
      })
      .addCase(fetchDegignFactorDescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDegignFactorDescription.fulfilled, (state, action) => {
        state.loading = false;
        state.four.designFactorDescription = action.payload;
      })
      .addCase(fetchDefectTypeDescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDefectTypeDescription.fulfilled, (state, action) => {
        state.loading = false;
        state.five.defectTypeDescription = action.payload;
      })
      .addCase(fetchСonditionDescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchСonditionDescription.fulfilled, (state, action) => {
        state.loading = false;
        state.nine.conditions = action.payload;
      })
      .addCase(fetchMissingInformationDescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchMissingInformationDescription.fulfilled,
        (state, action) => {
          state.loading = false;
          state.nine.missingText = action.payload;
        }
      )
      .addCase(addGradeToList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addGradeToList.fulfilled, (state, action) => {
        state.loading = false;
        state.three.grades.push(...action.payload);
      })
      .addCase(fetchSalesManager.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSalesManager.fulfilled, (state, action) => {
        state.loading = false;
        state.one.salesManagerId = action.payload[0].user_id;
        state.one.allSalesManagers = action.payload;
      })
      .addCase(fetchDistributors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDistributors.fulfilled, (state, action) => {
        state.loading = false;
        state.one.allDistributors = action.payload;
      })
      .addCase(editProposalName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editProposalName.fulfilled, (state, action) => {
        const index = state.two.projectData.findIndex(
          (item) => item.id === action.payload.id
        );
        state.loading = false;
        state.error = null;
        state.two.projectData[index].tech_proposal_name = action.payload.name;
      })
      .addCase(deleteGrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGrade.fulfilled, (state, action) => {
        const newGrades = state.three.grades.filter(
          (item) => item.id !== action.payload.id
        );
        state.loading = false;
        state.error = null;
        state.three.grades = newGrades;
      })
      .addCase(fetchFilterFiller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFilterFiller.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.six.fillerMaxTemp = action.payload[0]?.max_temp || 0;
        state.six.fillerSelection = action.payload;
      })
      .addCase(getReasons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReasons.fulfilled, (state, action) => {
        state.two.listReasons = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getReasons.rejected, (state) => {
        state.loading = false;
        state.error = "Request get reasons is fail!";
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const {
  addValueStep1,
  addValueStep2,
  addValueStep3,
  addValueStep4,
  addValueStep5,
  addValueStep6,
  addValueStep7,
  addValueStep9,
  archiveProjectTable,
  archiveProposalTable,
  completeProjectState,
  copyProjectTableItems,
  copyProposalTableItems,
  resetStateProjectCreate,
  selectedProjectId,
  setChangeAndLoaded,
  setIsFirstTime,
  setMaterialError,
  unzipProjectTable,
  unzipProposalTable,
} = projectCreateSlice.actions;

export default projectCreateSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith("rejected");
}
