import { Button, Col, Form, Spinner } from "react-bootstrap";

import {
  TailorMadeErrors,
  TailorMadeFooter as TTailorMadeFooter,
} from "features/tailor-made/tailor-made.slice.type";

import "./tailor-made-footer.scss";

type TailorMadeFooterProps = {
  form: TTailorMadeFooter;
  setField: Function;
  errors: TailorMadeErrors;
  handleCalculate: Function;
  handleClear: Function;
  loading: boolean;
};

const TailorMadeFooter = ({
  form,
  setField,
  errors,
  handleCalculate,
  handleClear,
  loading,
}: TailorMadeFooterProps) => {
  return (
    <div className="tailor-made-footer">
      <Form className="form-tailor-made-footer">
        <Form.Group
          controlId="formFieldReasonRepair"
          className="formFieldReasonRepair"
        >
          <Form.Label>Reason for tailor-made repair</Form.Label>
          <Form.Control
            type="text"
            value={form.reasonRepair || ""}
            onChange={(e) => {
              setField("reasonRepair", e.target.value);
            }}
            isInvalid={!!errors.reasonRepair}
          />
          <Form.Control.Feedback type="invalid">
            {errors.reasonRepair}
          </Form.Control.Feedback>
        </Form.Group>
        <Col className="col-6">
          <Button
            className="btn-prev"
            onClick={() => handleClear()}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="danger"
                  size="sm"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>{" "}
                Clear
              </>
            ) : (
              <>Clear</>
            )}
          </Button>
          <Button
            className="btn-next"
            onClick={() => handleCalculate()}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>{" "}
                Calculate
              </>
            ) : (
              <>Calculate</>
            )}
          </Button>
        </Col>
      </Form>
    </div>
  );
};

export default TailorMadeFooter;
