import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import { PanelBody, PanelHeader, StopModal } from "components/library";

import {
  addValueStep4,
  fetchMaop,
  fetchClassDescription,
  fetchDegignFactorDescription,
} from "../project-create.slice";

import Impact from "./components/impact";
import Maop from "./components/maop";

import "./temperature-loads.scss";

type TemperatureLoadsProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
};

interface ITemperatureLoadsErrors {
  designFactorOther?: string;
  designTemperatureC?: string;
  installationTemperatureC?: string;
  designPressureMPa?: string;
  installationPressureMPa?: string;
  nLoadingCycles?: string;
  pMinPressure?: string;
  pMaxPressure?: string;
  appliedFsh?: string;
  appliedMto?: string;
  appliedFax?: string;
  appliedMax?: string;
}

const TemperatureLoads = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
}: TemperatureLoadsProps) => {
  const { permissions = [] } = useAppSelector((state) => state.loginUser);
  const four = useAppSelector((state) => state.projectCreate.four);
  const [form, setForm] = useState(four);
  const [errors, setErrors] = useState<ITemperatureLoadsErrors>({});
  const [showStopModal, setShowStopModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setField = (field: string, value: string | number | boolean | null) => {
    setForm((form) => ({
      ...form,
      [field]: value,
    }));

    if (!!errors[field as keyof ITemperatureLoadsErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const changeFieldC = (value: string, nameField: string) => {
    let localValue = value;

    if (parseFloat(value) > 1000) {
      localValue = "1000";
    }

    if (parseFloat(value) < -1000) {
      localValue = "-1000";
    }

    setField(`${nameField}C`, parseFloat(localValue));

    setField(`${nameField}F`, (parseFloat(localValue) * 9) / 5 + 32);
  };

  const changeFieldF = (value: string, nameField: string) => {
    let localValue = value;

    if (parseFloat(value) > 1832) {
      localValue = "1832";
    }

    if (parseFloat(value) < -1768) {
      localValue = "-1768";
    }

    setField(`${nameField}F`, parseFloat(localValue));

    setField(`${nameField}C`, ((parseFloat(localValue) - 32) * 5) / 9);
  };

  const changeFieldMPa = (value: string, nameField: string) => {
    let localValue = value;

    if (parseFloat(value) > 965.2664) {
      localValue = "965.2664";
    }

    setField(`${nameField}MPa`, parseFloat(localValue));

    setField(
      `${nameField}Psi`,
      parseFloat((parseFloat(localValue) * 145.038).toFixed(2))
    );

    setField(
      `${nameField}Bar`,
      parseFloat((parseFloat(localValue) * 10).toFixed(2))
    );
  };

  const changeFieldPsi = (value: string, nameField: string) => {
    let localValue = value;

    if (parseFloat(value) > 140000) {
      localValue = "140000";
    }

    setField(`${nameField}Psi`, parseFloat(localValue));

    setField(
      `${nameField}MPa`,
      parseFloat((parseFloat(localValue) * 0.00689476).toFixed(2))
    );

    setField(
      `${nameField}Bar`,
      parseFloat((parseFloat(localValue) * 0.0689476).toFixed(2))
    );
  };

  const changeFieldBar = (value: string, nameField: string) => {
    let localValue = value;

    if (parseFloat(value) > 9652.664) {
      localValue = "9652.664";
    }

    setField(`${nameField}Bar`, parseFloat(localValue));

    setField(
      `${nameField}MPa`,
      parseFloat((parseFloat(localValue) * 0.1).toFixed(2))
    );

    setField(
      `${nameField}Psi`,
      parseFloat((parseFloat(localValue) * 14.5038).toFixed(2))
    );
  };

  const validateForm = () => {
    const { designFactorClass, designFactorOther } = form;
    const newErrors: ITemperatureLoadsErrors = {};

    if (
      designFactorClass === "CLASS_5" &&
      (!designFactorOther || designFactorOther === 0)
    )
      newErrors.designFactorOther = "Please enter other";

    return newErrors;
  };

  useEffect(() => {
    dispatch(fetchClassDescription());
    dispatch(fetchDegignFactorDescription());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchMaop({
        designFactorClass: form.designFactorClass,
        designFactorOther: form.designFactorOther,
        designTemperatureC: form.designTemperatureC,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.designFactorClass, form.designFactorOther, form.designTemperatureC]);

  useEffect(() => {
    if (flagNext) {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setFlagNext(false);
      } else {
        setFlagNext(false);
        dispatch(
          addValueStep4({
            ...form,
            maopMPa: four.maopMPa,
            maopPsi: four.maopPsi,
            maopBar: four.maopBar,
          })
        );
        navigate("/5");
      }
    }

    if (flagPrev) {
      setFlagPrev(false);
      if (form.designFactorClass) {
        dispatch(addValueStep4(form));
      }
      navigate("/3");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev]);

  return (
    <Row className="gx-0">
      <Form className="form-temperature-loads">
        <Col className="panel-wrap">
          <PanelHeader className="min-height" title="Design factor" />
          <PanelBody>
            <div>
              <Form.Group
                controlId="formFieldFactorClass"
                className="formFieldTemperatureLoads"
              >
                <div className="formFieldTemperatureLoads_wrapp">
                  <div className="formFieldTemperatureLoads_first">
                    <div className="formFieldTemperatureLoads_item">
                      <Form.Label>Class 1</Form.Label>
                      <Form.Check
                        checked={form.designFactorClass === "CLASS_1"}
                        name="groupDesignFactorClass"
                        type="radio"
                        label=""
                        onChange={(e) => {
                          if (e.target.checked) {
                            setField("designFactorClass", "CLASS_1");
                            setField("designFactorOther", 0.72);
                          }
                        }}
                      />
                    </div>
                    <div className="formFieldTemperatureLoads_item">
                      <Form.Label>Class 2</Form.Label>
                      <Form.Check
                        checked={form.designFactorClass === "CLASS_2"}
                        name="groupDesignFactorClass"
                        type="radio"
                        label=""
                        onChange={(e) => {
                          if (e.target.checked) {
                            setField("designFactorClass", "CLASS_2");
                            setField("designFactorOther", 0.6);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="formFieldTemperatureLoads_second">
                    <div className="formFieldTemperatureLoads_item">
                      <Form.Label>Class 3</Form.Label>
                      <Form.Check
                        checked={form.designFactorClass === "CLASS_3"}
                        name="groupDesignFactorClass"
                        type="radio"
                        label=""
                        onChange={(e) => {
                          if (e.target.checked) {
                            setField("designFactorClass", "CLASS_3");
                            setField("designFactorOther", 0.5);
                          }
                        }}
                      />
                    </div>
                    <div className="formFieldTemperatureLoads_item">
                      <Form.Label>Class 4</Form.Label>
                      <Form.Check
                        checked={form.designFactorClass === "CLASS_4"}
                        name="groupDesignFactorClass"
                        type="radio"
                        label=""
                        onChange={(e) => {
                          if (e.target.checked) {
                            setField("designFactorClass", "CLASS_4");
                            setField("designFactorOther", 0.4);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="field-design-factor-other">
                  <div>
                    <Form.Label>Other</Form.Label>
                    <Form.Check
                      checked={form.designFactorClass === "CLASS_5"}
                      name="groupDesignFactorClass"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("designFactorClass", "CLASS_5");
                      }}
                    />
                  </div>
                  <Form.Control
                    type="number"
                    value={form.designFactorOther ? form.designFactorOther : ""}
                    onChange={(e) =>
                      setField("designFactorOther", e.target.value)
                    }
                    readOnly={form.designFactorClass !== "CLASS_5"}
                    isInvalid={!!errors.designFactorOther}
                  />
                </div>
              </Form.Group>
              <div className="design-factor-description">
                {
                  four.designFactorDescription.find(
                    (item) => item.id === form.designFactorClass
                  )?.name
                }
              </div>
            </div>
          </PanelBody>
          <PanelHeader
            className="mt-2 min-height"
            title="Repair class"
            theme="light2"
          />
          <PanelBody theme="light2">
            <div>
              <Form.Group
                controlId="formFieldRepairClass"
                className="formFieldRepairClass"
              >
                <div className="formFieldRepairClass-btns">
                  <Form.Control
                    className={
                      form.repairClass === "CLASS_1"
                        ? "btn-item btn-item-active"
                        : "btn-item"
                    }
                    type="button"
                    value="Class1"
                    onClick={() => setField("repairClass", "CLASS_1")}
                  />
                  <Form.Control
                    className={
                      form.repairClass === "CLASS_2"
                        ? "btn-item btn-item-active"
                        : "btn-item"
                    }
                    type="button"
                    value="Class2"
                    onClick={() => setField("repairClass", "CLASS_2")}
                  />
                  <Form.Control
                    className={
                      form.repairClass === "CLASS_3"
                        ? "btn-item btn-item-active"
                        : "btn-item"
                    }
                    type="button"
                    value="Class3"
                    onClick={() => setField("repairClass", "CLASS_3")}
                  />
                </div>
                <div className="repair-class-describtion">
                  {
                    four.classDescription.find(
                      (item) => item.id === form.repairClass
                    )?.name
                  }
                </div>
              </Form.Group>
            </div>
          </PanelBody>
        </Col>
        <Col>
          <PanelHeader className="min-height" title="Temperatures" />
          <PanelBody className="border-radius-none">
            <div className="row row-gap-2">
              <Form.Group
                controlId="formFieldDesignTemperature"
                className="formFieldDesignTemperature"
              >
                <Form.Label>Repair design temperature</Form.Label>
                <div className="formFieldDesignTemperature_wrap-control">
                  <div className="formFieldDesignTemperature_wrap-number">
                    <Form.Control
                      type="number"
                      min={-1000}
                      max={1000}
                      value={form.designTemperatureC}
                      onChange={(e) => {
                        changeFieldC(e.target.value, "designTemperature");
                      }}
                      isInvalid={!!errors.designTemperatureC}
                    />
                    <Form.Text>°C</Form.Text>
                  </div>
                  <div className="formFieldDesignTemperature_wrap-number">
                    <Form.Control
                      type="number"
                      min={-1768}
                      max={1832}
                      value={form.designTemperatureF}
                      onChange={(e) => {
                        changeFieldF(e.target.value, "designTemperature");
                        dispatch(
                          fetchMaop({
                            designFactorClass: form.designFactorClass,
                            designFactorOther: form.designFactorOther,
                            designTemperatureC: form.designTemperatureC,
                          })
                        );
                      }}
                    />
                    <Form.Text>°F</Form.Text>
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      display: !!errors.designTemperatureC ? "block" : "none",
                    }}
                  >
                    {errors.designTemperatureC}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldInstallationTemperature"
                className="formFieldInstallationTemperature"
              >
                <Form.Label>Repair installation temperature</Form.Label>
                <div className="formFieldInstallationTemperature_wrap-control">
                  <div className="formFieldInstallationTemperature_wrap-number">
                    <Form.Control
                      type="number"
                      min={-1000}
                      max={1000}
                      value={form.installationTemperatureC}
                      onChange={(e) => {
                        changeFieldC(e.target.value, "installationTemperature");
                      }}
                      isInvalid={!!errors.installationTemperatureC}
                    />
                    <Form.Text>°C</Form.Text>
                  </div>
                  <div className="formFieldDesignTemperature_wrap-number">
                    <Form.Control
                      type="number"
                      min={-1768}
                      max={1832}
                      value={form.installationTemperatureF}
                      onChange={(e) => {
                        changeFieldF(e.target.value, "installationTemperature");
                      }}
                    />
                    <Form.Text>°F</Form.Text>
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      display: !!errors.installationTemperatureC
                        ? "block"
                        : "none",
                    }}
                  >
                    {errors.installationTemperatureC}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
          </PanelBody>
          <PanelHeader
            className="border-radius-none min-height"
            title="Pressures"
          />
          <PanelBody>
            <div className="row row-gap-2">
              <Form.Group
                controlId="formFieldDesignPressure"
                className="formFieldDesignPressure"
              >
                <Form.Label>Repair design pressure</Form.Label>
                <div className="formFieldDesignPressure_wrap-control">
                  <div className="formFieldDesignPressure_wrap-number">
                    <Form.Control
                      type="number"
                      max={965.2664}
                      value={form.designPressureMPa}
                      onChange={(e) => {
                        changeFieldMPa(e.target.value, "designPressure");
                      }}
                      isInvalid={!!errors.designPressureMPa}
                    />
                    <Form.Text>MPa</Form.Text>
                  </div>
                  <div className="formFieldDesignPressure_wrap-number">
                    <Form.Control
                      type="number"
                      max={140000}
                      value={form.designPressurePsi}
                      onChange={(e) => {
                        changeFieldPsi(e.target.value, "designPressure");
                      }}
                    />
                    <Form.Text>psi</Form.Text>
                  </div>
                  <div className="formFieldDesignPressure_wrap-number">
                    <Form.Control
                      type="number"
                      max={9652.664}
                      value={form.designPressureBar}
                      onChange={(e) => {
                        changeFieldBar(e.target.value, "designPressure");
                      }}
                    />
                    <Form.Text>bar</Form.Text>
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      display: !!errors.designPressureMPa ? "block" : "none",
                    }}
                  >
                    {errors.designPressureMPa}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldInstallationPressure"
                className="formFieldInstallationPressure"
              >
                <Form.Label>Repair installation pressure (plive).</Form.Label>
                <Form.Label>
                  Plive: pressure during application of the repair
                </Form.Label>
                <div className="formFieldInstallationPressure_wrap-control">
                  <div className="formFieldInstallationPressure_wrap-number">
                    <Form.Control
                      type="number"
                      max={965.2664}
                      value={form.installationPressureMPa}
                      onChange={(e) => {
                        changeFieldMPa(e.target.value, "installationPressure");
                      }}
                      isInvalid={!!errors.installationPressureMPa}
                    />
                    <Form.Text>MPa</Form.Text>
                  </div>
                  <div className="formFieldDesignPressure_wrap-number">
                    <Form.Control
                      type="number"
                      max={140000}
                      value={form.installationPressurePsi}
                      onChange={(e) => {
                        changeFieldPsi(e.target.value, "installationPressure");
                      }}
                    />
                    <Form.Text>psi</Form.Text>
                  </div>
                  <div className="formFieldDesignPressure_wrap-number">
                    <Form.Control
                      type="number"
                      max={9652.664}
                      value={form.installationPressureBar}
                      onChange={(e) => {
                        changeFieldBar(e.target.value, "installationPressure");
                      }}
                    />
                    <Form.Text>bar</Form.Text>
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      display: !!errors.installationPressureMPa
                        ? "block"
                        : "none",
                    }}
                  >
                    {errors.installationPressureMPa}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
          </PanelBody>
          <Maop
            maopMPa={four.maopMPa}
            maopPsi={four.maopPsi}
            maopBar={four.maopBar}
            designPressureMPa={form.designPressureMPa}
          />
        </Col>
        <Col>
          <div className="optional-design-consideration">
            <Form.Group
              controlId="formFieldOptionalDesignConsideration"
              className="formFieldOptionalDesignConsideration"
            >
              <Form.Label>Optional design consideration</Form.Label>
              <div className="formFieldOptionalDesignConsideration_wrap-control">
                <Form.Check
                  label="Yes"
                  checked={form.optionalDesignConsideration}
                  onChange={(e) => {
                    if (
                      e.target.checked &&
                      permissions.includes("OPTIONAL_DESIGN_CONSIDERATION")
                    ) {
                      setField("optionalDesignConsideration", true);
                    } else setShowStopModal(true);
                  }}
                />
                <Form.Check
                  label="No"
                  checked={!form.optionalDesignConsideration}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setField("optionalDesignConsideration", false);
                      setField("nLoadingCycles", 0);
                      setField("pMinPressure", 0);
                      setField("pMaxPressure", 0);
                      setField("appliedFsh", 0);
                      setField("appliedMto", 0);
                      setField("appliedFax", 0);
                      setField("appliedMax", 0);
                    }
                  }}
                />
              </div>
            </Form.Group>
          </div>
          <StopModal
            showStopModal={showStopModal}
            setShowStopModal={setShowStopModal}
          />
          {form.optionalDesignConsideration && (
            <>
              <Impact impact={form.impact} setField={setField} />
              <PanelHeader
                className="mt-2 min-height"
                title="If cyclic loading"
              />
              <PanelBody>
                <div className="row justify-content-end row-gap-1">
                  <div className="cyclicLoadingBlock">
                    <Form.Group
                      controlId="formFieldNLoadingCycles"
                      className="formFieldNLoadingCycles"
                    >
                      <Form.Label>Number of loading cycles:</Form.Label>
                      <div className="formFieldNLoadingCycles_wrap-number">
                        <Form.Control
                          type="number"
                          min={0}
                          value={form.nLoadingCycles}
                          onChange={(e) => {
                            setField(
                              "nLoadingCycles",
                              parseFloat(e.target.value)
                            );
                          }}
                          isInvalid={!!errors.nLoadingCycles}
                        />
                        <Form.Text>MPa</Form.Text>
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !!errors.nLoadingCycles ? "block" : "none",
                        }}
                      >
                        {errors.nLoadingCycles}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="formFieldPMinPressure"
                      className="formFieldPMinPressure"
                    >
                      <Form.Label>Pmin: minimum pressure</Form.Label>
                      <div className="formFieldPMinPressure_wrap-number">
                        <Form.Control
                          type="number"
                          max={965.2664}
                          value={form.pMinPressure}
                          onChange={(e) => {
                            if (parseFloat(e.target.value) > 965.2664) {
                              setField("pMinPressure", parseFloat("965.2664"));
                            } else {
                              setField(
                                "pMinPressure",
                                parseFloat(e.target.value)
                              );
                            }
                          }}
                          isInvalid={!!errors.pMinPressure}
                        />
                        <Form.Text>MPa</Form.Text>
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !!errors.pMinPressure ? "block" : "none",
                        }}
                      >
                        {errors.pMinPressure}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="cyclicLoadingBlock">
                    <Form.Group
                      controlId="formFieldPMaxPressure"
                      className="formFieldPMaxPressure"
                    >
                      <Form.Label>Pmax: maximum pressure </Form.Label>
                      <div className="formFieldPMaxPressure_wrap-number">
                        <Form.Control
                          type="number"
                          max={965.2664}
                          value={form.pMaxPressure}
                          onChange={(e) => {
                            if (parseFloat(e.target.value) > 965.2664) {
                              setField("pMaxPressure", parseFloat("965.2664"));
                            } else {
                              setField(
                                "pMaxPressure",
                                parseFloat(e.target.value)
                              );
                            }
                          }}
                          isInvalid={!!errors.pMaxPressure}
                        />
                        <Form.Text>MPa</Form.Text>
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !!errors.pMaxPressure ? "block" : "none",
                        }}
                      >
                        {errors.pMaxPressure}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <button
                      className="clear-all-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setField("nLoadingCycles", 0);
                        setField("pMinPressure", 0);
                        setField("pMaxPressure", 0);
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              </PanelBody>
              <PanelHeader
                className="mt-2 min-height"
                title="External loads"
                theme="light"
              />
              <PanelBody theme="light">
                <div className="row justify-content-end row-gap-1">
                  <div className="externalLoadsBlock">
                    <Form.Group
                      controlId="formFieldAppliedFsh"
                      className="formFieldAppliedFsh pr-2"
                    >
                      <Form.Label>Fsh: applied shear load</Form.Label>
                      <div className="formFieldAppliedFsh_wrap-control">
                        <div className="formFieldAppliedFsh_wrap-number">
                          <Form.Control
                            type="number"
                            value={form.appliedFsh}
                            onChange={(e) => {
                              setField(
                                "appliedFsh",
                                parseFloat(e.target.value)
                              );
                            }}
                            isInvalid={!!errors.appliedFsh}
                          />
                          <Form.Text>N</Form.Text>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{
                            display: !!errors.appliedFsh ? "block" : "none",
                          }}
                        >
                          {errors.appliedFsh}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="formFieldAppliedMto"
                      className="formFieldAppliedMto"
                    >
                      <Form.Label>Mto: applied torsional moment</Form.Label>
                      <div className="formFieldAppliedMto_wrap-control">
                        <div className="formFieldAppliedMto_wrap-number">
                          <Form.Control
                            type="number"
                            value={form.appliedMto}
                            onChange={(e) => {
                              setField(
                                "appliedMto",
                                parseFloat(e.target.value)
                              );
                            }}
                            isInvalid={!!errors.appliedMto}
                          />
                          <Form.Text>N mm</Form.Text>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{
                            display: !!errors.appliedMto ? "block" : "none",
                          }}
                        >
                          {errors.appliedMto}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="externalLoadsBlock">
                    <Form.Group
                      controlId="formFieldAppliedFax"
                      className="formFieldAppliedFax"
                    >
                      <Form.Label>Fax: applied axial load</Form.Label>
                      <div className="formFieldAppliedFax_wrap-control">
                        <div className="formFieldAppliedFax_wrap-number">
                          <Form.Control
                            type="number"
                            value={form.appliedFax}
                            onChange={(e) => {
                              setField(
                                "appliedFax",
                                parseFloat(e.target.value)
                              );
                            }}
                            isInvalid={!!errors.appliedFax}
                          />
                          <Form.Text>N</Form.Text>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{
                            display: !!errors.appliedFax ? "block" : "none",
                          }}
                        >
                          {errors.appliedFax}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="formFieldAppliedMax"
                      className="formFieldAppliedMax"
                    >
                      <Form.Label>Max: applied axial moment</Form.Label>
                      <div className="formFieldAppliedMax_wrap-control">
                        <div className="formFieldAppliedMax_wrap-number">
                          <Form.Control
                            type="number"
                            value={form.appliedMax}
                            onChange={(e) => {
                              setField(
                                "appliedMax",
                                parseFloat(e.target.value)
                              );
                            }}
                            isInvalid={!!errors.appliedMax}
                          />
                          <Form.Text>N mm</Form.Text>
                        </div>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{
                            display: !!errors.appliedMax ? "block" : "none",
                          }}
                        >
                          {errors.appliedMax}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <button
                    className="clear-all-btn externalLoadsBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      setField("appliedFsh", 0);
                      setField("appliedMto", 0);
                      setField("appliedFax", 0);
                      setField("appliedMax", 0);
                    }}
                  >
                    Clear All
                  </button>
                </div>
              </PanelBody>
            </>
          )}
        </Col>
      </Form>
    </Row>
  );
};

export default TemperatureLoads;
