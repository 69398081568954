import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";

import {
  addValueTailorMadeStep6,
  fetchMaterials,
} from "features/tailor-made/tailor-made.slice";
import { TailorMadeMaterial } from "features/tailor-made/tailor-made.slice.type";
import { useAppDispatch, useAppSelector } from "app/hook";
import { PanelBody, PanelHeader, WarningModal } from "components/library";
import ThermometerHeat from "assets/images/thermometer_heat.png";

import {
  addValueStep6,
  fetchFiller,
  fetchPrimer,
  setIsFirstTime,
  fetchFilterFiller,
  fetchMaterialFilter,
  setMaterialError,
} from "../project-create.slice";

import { FetchMaterialFilter } from "../project-create.slice.type";

import SolutionSelection from "./components/solution-selection";

import "./select-product.scss";

type SelectProductProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
};

interface ISelectProductErrors {
  solutionSelection?: string;
  tapeType?: string;
  tapeWidth?: string;
  resinSelection?: string;
}

const SelectProduct = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
}: SelectProductProps) => {
  const { accountType } = useAppSelector((state) => state.loginUser);
  const { defectLocation, defectType } = useAppSelector(
    (state) => state.projectCreate.five
  );
  const six = useAppSelector((state) => state.projectCreate.six);
  const { fillerSelection, isFirstTime, materialFilterError } = useAppSelector(
    (state) => state.projectCreate.six
  );
  const { environmental } = useAppSelector(
    (state) => state.projectCreate.three
  );
  const { installationTemperatureC, designTemperatureC } = useAppSelector(
    (state) => state.projectCreate.four
  );
  const { available_materials: filterMaterials } = useAppSelector(
    (state) => state.projectCreate.six.materialFilter
  );
  const { materials } = useAppSelector((state) => state.tailorMade);
  const [form, setForm] = useState(six);
  const [isBlinkFiller, setIsBlinkFiller] = useState(false);
  const [isBlinkPrimer, setIsBlinkPrimer] = useState(false);
  const [errors, setErrors] = useState<ISelectProductErrors>({});
  const [updateMaterials, setUpdateMaterials] = useState<
    Array<TailorMadeMaterial>
  >([]);
  const [isOpenWarning, setIsOpenWarning] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setField = (field: string, value: string | boolean | number | null) => {
    setForm((form) => ({
      ...form,
      [field]: value,
    }));

    if (!!errors[field as keyof ISelectProductErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const getFilterMaterial = (values: FetchMaterialFilter) => {
    dispatch(
      fetchMaterialFilter({
        width: values.width,
        type: values.type,
        selection: values.selection,
      })
    );
  };

  const onCloseModal = (value: boolean) => {
    setIsOpenWarning(value);
    dispatch(setMaterialError());
  };

  const validateForm = () => {
    const { repairCompositeId } = form;
    const newErrors: ISelectProductErrors = {};

    if (repairCompositeId === 0)
      newErrors.solutionSelection = "Please select solution selection";

    return newErrors;
  };

  useEffect(() => {
    if (materialFilterError) {
      setIsOpenWarning(true);
    }
  }, [materialFilterError]);

  useEffect(() => {
    if (designTemperatureC !== 0) {
      dispatch(
        fetchFilterFiller({
          environmental,
          designTemperatureC,
          defectLocation,
          defectType,
        })
      );
    } else {
      dispatch(fetchFiller());
    }
  }, [designTemperatureC, dispatch, defectLocation, defectType, environmental]);

  useEffect(() => {
    if (accountType !== "ADMINISTRATOR") {
      setField("allowableStrains", null);
      setField("serviceFactorIso", null);
    } else {
      setField("allowableStrains", six.allowableStrains);
      setField("serviceFactor", six.serviceFactor);
      setField("serviceFactorIso", six.serviceFactorIso);
    }
    // eslint-disable-next-line
  }, [accountType]);

  useEffect(() => {
    if (installationTemperatureC > six.fillerMaxTemp) {
      setIsBlinkFiller(true);
    } else setIsBlinkFiller(false);
    // eslint-disable-next-line
  }, [form.fillerMaxTemp, installationTemperatureC]);

  useEffect(() => {
    if (installationTemperatureC > six.primerMaxTemp) {
      setIsBlinkPrimer(true);
    } else setIsBlinkPrimer(false);
    // eslint-disable-next-line
  }, [form.primerMaxTemp, installationTemperatureC]);

  useEffect(() => {
    dispatch(fetchMaterials());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      fetchMaterialFilter({
        width: null,
        type: null,
        selection: six.solutionSelection,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const arr = filterMaterials.filter((item) => item.tape_width !== 300);
    setUpdateMaterials(filterMaterials);
  }, [filterMaterials]);

  useEffect(() => {
    if (environmental === "OFFSHORE_SUBSEA") {
      dispatch(fetchPrimer(form.repairCompositeId));
    }
  }, [environmental, dispatch, form.repairCompositeId]);

  useEffect(() => {
    if (flagNext) {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setFlagNext(false);
      } else {
        setFlagNext(false);
        dispatch(addValueStep6(form));
        dispatch(
          addValueTailorMadeStep6({
            repairCompositeId: form.repairCompositeId,
            tapeWidth: form.tapeWidth,
          })
        );
        dispatch(setIsFirstTime(false));
        navigate("/7");
      }
    }

    if (flagPrev) {
      setFlagPrev(false);
      if (form.repairCompositeId) {
        dispatch(addValueStep6(form));
        dispatch(
          addValueTailorMadeStep6({
            repairCompositeId: form.repairCompositeId,
            tapeWidth: form.tapeWidth,
          })
        );
      }
      navigate("/5");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev]);

  return (
    <Row className="gx-0">
      <WarningModal
        showWarningModal={isOpenWarning}
        setShowWarningModal={onCloseModal}
      >
        {materialFilterError}
      </WarningModal>
      <Form className="form-select-products">
        <Col className="panel-wrap">
          <SolutionSelection
            setField={setField}
            form={form}
            materials={updateMaterials}
            allmaterials={materials}
            getFilterMaterial={getFilterMaterial}
            errors={errors}
            installTemp={installationTemperatureC}
            isFirstTime={isFirstTime}
            environmental={environmental}
          />
          <div className="infoAboutSelectedSolution mt-2">
            <div className="infoAboutSelectedSolution_header">
              <span className="infoAboutSelectedSolution_title">
                Information about the selected solution
              </span>
            </div>
            <div className="infoAboutSelectedSolution_main"></div>
          </div>
          {(accountType === "ADMINISTRATOR" ||
            accountType === "TECHNICAL_ADMINISTRATOR") && (
            <>
              <PanelHeader
                title="Factors for calculation from standarts"
                className="mt-2 min-height"
                theme="light"
              />
              <PanelBody theme="light">
                <div className="formFieldFactorsForCalculation">
                  <Form.Group className="formFieldFactorsForCalculation_item">
                    <Form.Label>
                      Allowable strains for repair laminates (ASME only)
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={form.allowableStrains === "CONTINOUS"}
                        name="strainsForRepairLaminates"
                        type="radio"
                        label="Continuous (Sustained)"
                        onChange={(e) => {
                          if (e.target.checked)
                            setField("allowableStrains", "CONTINOUS");
                        }}
                      />
                      <Form.Check
                        checked={form.allowableStrains === "RARELY_OCCURRING"}
                        name="strainsForRepairLaminates"
                        type="radio"
                        label="Rarely occurring"
                        onChange={(e) => {
                          if (e.target.checked)
                            setField("allowableStrains", "RARELY_OCCURRING");
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="formFieldFactorsForCalculation_item">
                    <Form.Label>
                      Service factor (ISO-Table10, ASME-Table-401-3.4.4-1)
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={form.serviceFactor === "DESIGN_LIFE_DATA"}
                        name="serviceFactor"
                        type="radio"
                        label="Design life data"
                        onChange={(e) => {
                          if (e.target.checked)
                            setField("serviceFactor", "DESIGN_LIFE_DATA");
                        }}
                      />
                      <Form.Check
                        checked={form.serviceFactor === "_1000_HR_DATA"}
                        name="serviceFactor"
                        type="radio"
                        label="1000 hr data test"
                        onChange={(e) => {
                          if (e.target.checked)
                            setField("serviceFactor", "_1000_HR_DATA");
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="formFieldFactorsForCalculation_item">
                    <Form.Label>Use of ELt - equation 11 (ISO)</Form.Label>
                    <div>
                      <Form.Check
                        checked={form.serviceFactorIso === "YES"}
                        name="useOfELt"
                        type="radio"
                        label="Yes"
                        onChange={(e) => {
                          if (e.target.checked)
                            setField("serviceFactorIso", "YES");
                        }}
                      />
                      <Form.Check
                        checked={form.serviceFactorIso === "NO"}
                        name="useOfELt"
                        type="radio"
                        label="No"
                        onChange={(e) => {
                          if (e.target.checked)
                            setField("serviceFactorIso", "NO");
                        }}
                      />
                    </div>
                  </Form.Group>
                </div>
              </PanelBody>
            </>
          )}
        </Col>
        <Col>
          <PanelHeader
            className="min-height"
            title="Filler selection"
            theme="white"
          />
          <PanelBody theme="white">
            <div className="fillerSelection">
              <Form.Group
                controlId="formFieldSelectFiller"
                className="formFieldSelectFiller"
              >
                <Form.Label>Please select filler:</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    let a = document.getElementById(e.target.value);
                    setField("fillerSelectionId", parseInt(e.target.value));
                    setField("fillerMaxTemp", a?.dataset.maxTemp || "");
                  }}
                  value={form.fillerSelectionId}
                >
                  {fillerSelection.map((item) => {
                    return (
                      <option
                        value={item.id}
                        data-max-temp={item.max_temp}
                        key={item.id}
                        id={item.id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group
                controlId="formFieldApplicationTemperature"
                className="formFieldApplicationTemperature"
              >
                <Form.Label>Application temperature:</Form.Label>
                <div className="formFieldApplicationTemperature_item">
                  <Form.Text>Max</Form.Text>
                  <Form.Control
                    type="number"
                    value={six.fillerMaxTemp}
                    readOnly
                  />
                  <Form.Text>°C</Form.Text>
                </div>
                <div className="thermometerWrapp">
                  <img
                    className={`${
                      isBlinkFiller
                        ? "blink filler-thermometer"
                        : "filler-thermometer"
                    }`}
                    alt="thermometer"
                    src={ThermometerHeat}
                  />
                </div>
              </Form.Group>
            </div>
          </PanelBody>
          {Array.isArray(six.primerSelection) && (
            <>
              <PanelHeader
                className="mt-2 min-height"
                title="Primer Selection"
                theme="light"
              />
              <PanelBody theme="light">
                <div className="fillerSelection primerHeight">
                  <Form.Group
                    controlId="formFieldPrimerFiller"
                    className="formFieldSelectFiller primerSelection"
                  >
                    <Form.Label>Please select primer:</Form.Label>
                    <Form.Select
                      value={form.primerSelectionId}
                      onChange={(e) => {
                        let a = document.getElementById(e.target.value);
                        setField("primerSelectionId", e.target.value);
                        setField("primerMaxTemp", a?.dataset.maxTemp || "");
                      }}
                    >
                      {six.primerSelection?.map((item) => {
                        return (
                          <option
                            value={item.id}
                            data-max-temp={item.max_temp}
                            key={item.name}
                            id={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    controlId="formFieldApplicationTemperature"
                    className="formFieldApplicationTemperature"
                  >
                    <Form.Label>Application temperature:</Form.Label>
                    <div className="formFieldApplicationTemperature_item">
                      <Form.Text>Max</Form.Text>
                      <Form.Control
                        type="number"
                        value={six.primerMaxTemp}
                        readOnly
                      />
                      <Form.Text>°C</Form.Text>
                    </div>
                    <div className="thermometerWrapp">
                      <img
                        className={`${
                          isBlinkPrimer
                            ? "blink filler-thermometer"
                            : "filler-thermometer"
                        }`}
                        alt="thermometer"
                        src={ThermometerHeat}
                      />
                    </div>
                  </Form.Group>
                </div>
              </PanelBody>
            </>
          )}
        </Col>
      </Form>
    </Row>
  );
};

export default SelectProduct;
