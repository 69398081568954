import { useState } from "react";
import { Container } from "react-bootstrap";

import { RowButtons } from "components/library";
import { TemperatureLoads } from "features/project";

const Temperature = () => {
  const [flagNext, setFlagNext] = useState(false);
  const [flagPrev, setFlagPrev] = useState(false);

  return (
    <Container>
      <TemperatureLoads
        flagNext={flagNext}
        setFlagNext={setFlagNext}
        flagPrev={flagPrev}
        setFlagPrev={setFlagPrev}
      />
      <RowButtons
        textPrev="Previous"
        onClickPrev={setFlagPrev}
        textNext="Next"
        onClickNext={setFlagNext}
      />
    </Container>
  );
};

export default Temperature;
