import { Button, Modal } from "react-bootstrap";

import Warning from "assets/images/modal-warning.svg";

type IModalProps = {
  showWarningModal: boolean;
  setShowWarningModal: (a: boolean) => void;
  children: React.ReactNode;
};

const WarningModal = ({ showWarningModal, setShowWarningModal, children }: IModalProps) => {
  return (
    <Modal className="stop-modal" show={showWarningModal} onHide={() => setShowWarningModal(false)}>
      <Modal.Header closeButton>
        <div className="warning-image-wrapp">
          <img src={Warning} alt="warning" />
        </div>
        <div className="title-wrapp">
          <Modal.Title>Warning!</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-stop-btn" variant="secondary" onClick={() => setShowWarningModal(false)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;
