import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import { authRefreshToken, fetchUserDetail } from "features/auth/auth-slice";

import { Auth } from "features/auth";

import { Layout } from "components/layout";
import {
  Defect,
  Home,
  Pipe,
  Product,
  Proposal,
  Report,
  Result,
  Tailor,
  Temperature,
} from "pages";

import "styles/all.scss";

const AppLayout = () => {
  const {
    token: accessToken,
    refreshToken,
    loading,
    email,
    permissions,
    accountType,
  } = useAppSelector((state) => state.loginUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && (!accessToken || email === "") && refreshToken) {
      dispatch(authRefreshToken());
    }
  }, [dispatch, accessToken, refreshToken, loading, email]);

  useEffect(() => {
    if (accessToken && !loading && permissions === undefined) {
      dispatch(fetchUserDetail());
    }
  }, [dispatch, accessToken, loading, permissions]);

  if ((!accessToken && !refreshToken) || !refreshToken) {
    return <Auth />;
  }

  if (accountType !== "") {
    const body = document.getElementById("body");

    if (body !== null) {
      body.classList.remove("preload");
    }
  }

  return (
    <Container fluid className={loading ? "gx-0 refresh-token" : "gx-0"}>
      {accountType === "" && <span className="loader">REA</span>}

      {accountType !== "" && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="2" element={<Proposal />} />
            <Route path="3" element={<Pipe />} />
            <Route path="4" element={<Temperature />} />
            <Route path="5" element={<Defect />} />
            <Route path="6" element={<Product />} />
            <Route path="7" element={<Result />} />
            <Route path="8" element={<Tailor />} />
            <Route path="9" element={<Report />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      )}
    </Container>
  );
};

export default AppLayout;
