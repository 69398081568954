import { Container, Row } from "react-bootstrap";

import Linkedin from "assets/images/linkedin.svg";
import Facebook from "assets/images/facebook.svg";
import Youtube from "assets/images/youtube.svg";

import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <div className="footer_privacy-policy">
            <div>All rights reserved</div>
            <div>Privacy policy</div>
          </div>
          <div className="footer_social-links">
            <div className="footer_title">SOCIAL LINKS</div>
            <div className="">
              <a href="https://linkedin.com">
                <img src={Linkedin} alt="icon linkedin" />
              </a>
              <a href="https://youtube.com">
                <img src={Youtube} alt="icon youtube" />
              </a>
              <a href="https://facebook.com">
                <img src={Facebook} alt="icon facebook" />
              </a>
            </div>
          </div>
          <div className="footer_contacts">
            <div className="footer_title">CONTACT US</div>
            <div>
              <a href="tel:+37792057981">+ 377 92 05 79 81</a>
              <a href="tel:+37792057981">+ 377 92 05 79 81</a>
              <a href="mailto:3x@3xeng.com">3x@3xeng.com</a>
            </div>
          </div>
          <div className="footer_social-offices">
            <div className="footer_title">OUR HEAD OFFICES</div>
            <div>
              <span>3X ENGINEERING</span>
              <span>"Le Copori"</span>
              <span>9, avenue Albert II</span>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
