import { useState } from "react";
import { Form, Modal } from "react-bootstrap";

import { Button } from "components/library";

import "../modal-window.scss";

type IJobDoneModalProps = {
  isOpen: boolean;
  handleClose: (a: boolean) => void;
  handleSave: (a: string) => void;
};

interface IJobDoneModalErrors {
  reparationNumber?: string;
}

const JobDoneModal = ({
  isOpen,
  handleClose,
  handleSave,
}: IJobDoneModalProps) => {
  const [form, setForm] = useState({ reparationNumber: "" });
  const [errors, setErrors] = useState<IJobDoneModalErrors>({});

  const validateForm = () => {
    const { reparationNumber } = form;
    const newErrors: IJobDoneModalErrors = {};

    if (reparationNumber === "")
      newErrors.reparationNumber = "Please enter Reparation Number";

    return newErrors;
  };

  const handleOk = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      handleSave(form.reparationNumber);
      handleClose(false);
      setForm({ reparationNumber: "" });
      setErrors({});
    }
  };

  return (
    <Modal
      className="save-modal"
      show={isOpen}
      onHide={() => {
        handleClose(false);
        setForm({ reparationNumber: "" });
        setErrors({});
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>ATTENTION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          Please, enter the <strong>Reparation number</strong>
        </div>
        <div>first - then you can change the status to Job done</div>
        <Form.Group
          controlId="formFieldOriginalDiameter"
          className="formFieldOriginalDiameter"
        >
          <Form.Control
            type="string"
            value={form.reparationNumber}
            onChange={(e) => {
              setForm({ reparationNumber: e.target.value });
            }}
            isInvalid={!!errors.reparationNumber}
          />
          <Form.Control.Feedback
            type="invalid"
            style={{
              display: !!errors.reparationNumber ? "block" : "none",
            }}
          >
            {errors.reparationNumber}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-next" text="Save" onClick={handleOk} />
      </Modal.Footer>
    </Modal>
  );
};

export default JobDoneModal;
