type ISvgProps = {
    isHover?: boolean;
  };
  
const DeleteSvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="20" height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.834 6.69336C15.613 6.69336 15.401 6.78555 15.2447 6.94964C15.0884 7.11374 15.0006 7.3363 15.0006
          7.56836V17.3605C14.9767 17.803 14.7873 18.2176 14.4736 18.5141C14.1598 18.8106 13.7472 18.9649 13.3256
          18.9434H6.67565C6.25406 18.9649 5.84147 18.8106 5.52774 18.5141C5.21401 18.2176 5.02456 17.803 5.00065
          17.3605V7.56836C5.00065 7.3363 4.91285 7.11374 4.75657 6.94964C4.60029 6.78555 4.38833 6.69336 4.16732
          6.69336C3.9463 6.69336 3.73434 6.78555 3.57806 6.94964C3.42178 7.11374 3.33398 7.3363 3.33398
          7.56836V17.3605C3.35777 18.2672 3.7228 19.127 4.34913 19.7517C4.97547 20.3764 5.81204 20.715 6.67565
          20.6934H13.3256C14.1893 20.715 15.0258 20.3764 15.6522 19.7517C16.2785 19.127 16.6435 18.2672 16.6673
          17.3605V7.56836C16.6673 7.3363 16.5795 7.11374 16.4232 6.94964C16.267 6.78555 16.055 6.69336
          15.834 6.69336Z" fill="#a80d10"
        />
        <path d="M16.6667 4.06836H13.3333V2.31836C13.3333 2.08629 13.2455 1.86374 13.0893 1.69964C12.933 1.53555
          12.721 1.44336 12.5 1.44336H7.5C7.27899 1.44336 7.06702 1.53555 6.91074 1.69964C6.75446 1.86374 6.66667
          2.08629 6.66667 2.31836V4.06836H3.33333C3.11232 4.06836 2.90036 4.16055 2.74408 4.32464C2.5878 4.48873 2.5
          4.71129 2.5 4.94336C2.5 5.17542 2.5878 5.39798 2.74408 5.56208C2.90036 5.72617 3.11232 5.81836 3.33333
          5.81836H16.6667C16.8877 5.81836 17.0996 5.72617 17.2559 5.56208C17.4122 5.39798 17.5 5.17542 17.5
          4.94336C17.5 4.71129 17.4122 4.48873 17.2559 4.32464C17.0996 4.16055 16.8877 4.06836 16.6667 4.06836ZM8.33333
          4.06836V3.19336H11.6667V4.06836H8.33333Z" fill="#a80d10"
        />
        <path d="M9.16666 15.4434V9.31836C9.16666 9.08629 9.07887 8.86374 8.92259 8.69964C8.76631 8.53555 8.55435
          8.44336 8.33333 8.44336C8.11232 8.44336 7.90036 8.53555 7.74408 8.69964C7.5878 8.86374 7.5 9.08629 7.5
          9.31836V15.4434C7.5 15.6754 7.5878 15.898 7.74408 16.0621C7.90036 16.2262 8.11232 16.3184 8.33333
          16.3184C8.55435 16.3184 8.76631 16.2262 8.92259 16.0621C9.07887 15.898 9.16666 15.6754 9.16666
          15.4434Z" fill="#a80d10"
        />
        <path d="M12.5007 15.4434V9.31836C12.5007 9.08629 12.4129 8.86374 12.2566 8.69964C12.1003 8.53555 11.8883
          8.44336 11.6673 8.44336C11.4463 8.44336 11.2343 8.53555 11.0781 8.69964C10.9218 8.86374 10.834 9.08629
          10.834 9.31836V15.4434C10.834 15.6754 10.9218 15.898 11.0781 16.0621C11.2343 16.2262 11.4463 16.3184 11.6673
          16.3184C11.8883 16.3184 12.1003 16.2262 12.2566 16.0621C12.4129 15.898 12.5007 15.6754 12.5007
          15.4434Z" fill="#a80d10"
        />
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="20" height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.834 6.69336C15.613 6.69336 15.401 6.78555 15.2447 6.94964C15.0884 7.11374 15.0006 7.3363 15.0006
        7.56836V17.3605C14.9767 17.803 14.7873 18.2176 14.4736 18.5141C14.1598 18.8106 13.7472 18.9649 13.3256
        18.9434H6.67565C6.25406 18.9649 5.84147 18.8106 5.52774 18.5141C5.21401 18.2176 5.02456 17.803 5.00065
        17.3605V7.56836C5.00065 7.3363 4.91285 7.11374 4.75657 6.94964C4.60029 6.78555 4.38833 6.69336 4.16732
        6.69336C3.9463 6.69336 3.73434 6.78555 3.57806 6.94964C3.42178 7.11374 3.33398 7.3363 3.33398
        7.56836V17.3605C3.35777 18.2672 3.7228 19.127 4.34913 19.7517C4.97547 20.3764 5.81204 20.715 6.67565
        20.6934H13.3256C14.1893 20.715 15.0258 20.3764 15.6522 19.7517C16.2785 19.127 16.6435 18.2672 16.6673
        17.3605V7.56836C16.6673 7.3363 16.5795 7.11374 16.4232 6.94964C16.267 6.78555 16.055 6.69336
        15.834 6.69336Z" fill="black"
      />
      <path d="M16.6667 4.06836H13.3333V2.31836C13.3333 2.08629 13.2455 1.86374 13.0893 1.69964C12.933 1.53555
        12.721 1.44336 12.5 1.44336H7.5C7.27899 1.44336 7.06702 1.53555 6.91074 1.69964C6.75446 1.86374 6.66667
        2.08629 6.66667 2.31836V4.06836H3.33333C3.11232 4.06836 2.90036 4.16055 2.74408 4.32464C2.5878 4.48873 2.5
        4.71129 2.5 4.94336C2.5 5.17542 2.5878 5.39798 2.74408 5.56208C2.90036 5.72617 3.11232 5.81836 3.33333
        5.81836H16.6667C16.8877 5.81836 17.0996 5.72617 17.2559 5.56208C17.4122 5.39798 17.5 5.17542 17.5
        4.94336C17.5 4.71129 17.4122 4.48873 17.2559 4.32464C17.0996 4.16055 16.8877 4.06836 16.6667 4.06836ZM8.33333
        4.06836V3.19336H11.6667V4.06836H8.33333Z" fill="black"
      />
      <path d="M9.16666 15.4434V9.31836C9.16666 9.08629 9.07887 8.86374 8.92259 8.69964C8.76631 8.53555 8.55435
        8.44336 8.33333 8.44336C8.11232 8.44336 7.90036 8.53555 7.74408 8.69964C7.5878 8.86374 7.5 9.08629 7.5
        9.31836V15.4434C7.5 15.6754 7.5878 15.898 7.74408 16.0621C7.90036 16.2262 8.11232 16.3184 8.33333
        16.3184C8.55435 16.3184 8.76631 16.2262 8.92259 16.0621C9.07887 15.898 9.16666 15.6754 9.16666
        15.4434Z" fill="black"
      />
      <path d="M12.5007 15.4434V9.31836C12.5007 9.08629 12.4129 8.86374 12.2566 8.69964C12.1003 8.53555 11.8883
        8.44336 11.6673 8.44336C11.4463 8.44336 11.2343 8.53555 11.0781 8.69964C10.9218 8.86374 10.834 9.08629
        10.834 9.31836V15.4434C10.834 15.6754 10.9218 15.898 11.0781 16.0621C11.2343 16.2262 11.4463 16.3184 11.6673
        16.3184C11.8883 16.3184 12.1003 16.2262 12.2566 16.0621C12.4129 15.898 12.5007 15.6754 12.5007
        15.4434Z" fill="black"
      />
    </svg>
  );
};

export default DeleteSvg;
