import { ModalWindow } from "components/library";

import { IModalProps } from "./archive-modal";

const CopyModal = ({ onClose, modal, setModal, projects, isProposal }: IModalProps) => {
  const handleClose = () => setModal({
    ...modal,
    isCopyOpen: false,
  });
  return (
    <ModalWindow
      isOpen={modal.isCopyOpen}
      title={modal.title}
      handleClose={handleClose}
      onClose={onClose}
    >
      Are you sure that you want to copy the selected {isProposal ? "tech proposal" : "project"}?
      <div className="projects-list">
        {projects && projects.map((item: string, index: number) => {
          return (
            <li key={item + index}>{item}</li>
          );
        })}
      </div>
    </ModalWindow>
  );
};

export default CopyModal;
