import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import { PanelBody, PanelHeader, Alert } from "components/library";
import { IAlertConfig } from "components/library/alert/alert";
import { checkFormat } from "helpers/checkFormat";
import UploadFile from "assets/images/upload-file.png";

import {
  addValueStep1,
  fetchSalesManager,
  fetchDistributors,
} from "./project-create.slice";

import { importProjectFile } from "./project-import-file.slice";

import "./project-create.scss";

type ProjectCreateProps = {
  flagNext: boolean;
  setFlagNext: Function;
  isChecked: boolean;
  flagCheck: string;
  setFlagCheck: Function;
};

interface IProjectErrors {
  client?: string;
  project?: string;
  manager?: string;
  repairing?: string;
}

const ProjectCreate = ({
  flagNext,
  setFlagNext,
  isChecked,
  flagCheck,
  setFlagCheck,
}: ProjectCreateProps) => {
  const one = useAppSelector((state) => state.projectCreate.one);
  const { allDistributors, allSalesManagers } = one;
  const { accountType, roles, company, saleManagerName } = useAppSelector(
    (state) => state.loginUser
  );
  const [form, setForm] = useState({ ...one });
  const [errors, setErrors] = useState<IProjectErrors>({});
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [config, setConfig] = useState<IAlertConfig>({
    isShow: false,
    variant: "danger",
    error: "",
  });

  if (form.distributorId === null && form.distributorId !== one.distributorId) {
    setForm({
      ...form,
      distributorId: one.distributorId,
    });
  }

  if (
    form.salesManagerId === null &&
    form.salesManagerId !== one.salesManagerId
  ) {
    setForm({
      ...form,
      salesManagerId: one.salesManagerId,
    });
  }

  const { isLoading, error } = useAppSelector((state) => state.fetchFile.file);
  const fileRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setField = (field: string, value: string | number | null) => {
    setForm((form) => ({
      ...form,
      [field]: value,
    }));

    if (!!errors[field as keyof IProjectErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  useEffect(() => {
    if (accountType === "ADMINISTRATOR") {
      dispatch(fetchSalesManager());
      dispatch(fetchDistributors());
    }
    if (
      accountType === "TECHNICAL_ADMINISTRATOR" ||
      accountType === "TECHNICAL_3X"
    ) {
      dispatch(fetchSalesManager());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const validateForm = () => {
    const { client, project, manager } = form;
    const newErrors: IProjectErrors = {};

    if (!client || client === "") newErrors.client = "Please enter client";
    if (!project || project === "") newErrors.project = "Please enter project";
    if (!manager || manager === "") newErrors.manager = "Please enter manager";

    return newErrors;
  };

  useEffect(() => {
    if (flagNext && isChecked) {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        dispatch(
          addValueStep1({
            ...form,
          })
        );
        navigate("/2");
      }
    }
    setFlagNext(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, isChecked]);

  useEffect(() => {
    error &&
      setConfig({
        ...config,
        isShow: true,
        error: error,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleSubmit = () => {
    document.body.onfocus = handleFileUpload as any;
    if (fileRef.current) {
      fileRef.current.click();
      setIsUpload(true);
      setConfig({
        ...config,
        isShow: false,
      });
    }
  };

  const handleFileUpload = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.files && !checkFormat(event.target.files[0].name)) {
      setConfig({
        ...config,
        isShow: true,
        error: "Please choose the file in xlsx format",
      });
      setIsUpload(false);
    } else if (event.target.files && checkFormat(event.target.files[0].name)) {
      dispatch(importProjectFile(event.target.files[0]));
      setIsUpload(false);
    } else {
      setIsUpload(false);
      document.body.onfocus = null;
    }
  };

  const getClasses = () => {
    let result = "gx-0";
    if (isUpload || isLoading) result += " create-project-form";
    if (!isChecked) result += " opacity-50";

    return result;
  };

  return (
    <Row className={getClasses()}>
      <PanelHeader
        title="Create new project"
        isChecked={isChecked}
        flagCheck={flagCheck}
        setFlagCheck={setFlagCheck}
      />
      {accountType === "ADMINISTRATOR" && (
        <PanelBody>
          <Card.Img
            className={
              isUpload ? "upload-file-image-active" : "upload-file-image"
            }
            src={UploadFile}
          />
          {isLoading && (
            <div className="loading">
              <Spinner className="spinner" animation="border" />
              <span className="loading-text">LOADING...</span>
            </div>
          )}
          <Form className="project-create-admin-form">
            <div className="admin-form-group">
              <div className="admin-form-group_block">
                <Form.Group
                  controlId="formFieldClient"
                  className="admin-form-group_item"
                >
                  <Form.Label>
                    Client <span className="red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter client"
                    value={form.client}
                    onChange={(e) => setField("client", e.target.value)}
                    isInvalid={!!errors.client}
                    disabled={!isChecked}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.client}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="formFieldProject"
                  className="admin-form-group_item"
                >
                  <Form.Label>
                    Project <span className="red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter project name"
                    value={form.project}
                    onChange={(e) => setField("project", e.target.value)}
                    isInvalid={!!errors.project}
                    disabled={!isChecked}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.project}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="formFieldRepairing"
                  className="admin-form-group_item"
                >
                  <Form.Label>
                    This document is the technical proposal for repairing :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter repairing"
                    value={form.repairing}
                    onChange={(e) => setField("repairing", e.target.value)}
                    disabled={!isChecked}
                  />
                </Form.Group>
              </div>
              <div className="admin-form-group_block">
                <Form.Group
                  controlId="formFieldDistributorCompany"
                  className="admin-form-group_item"
                >
                  <Form.Label>Distributor company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="3x"
                    value={form.distributorCompany}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  controlId="formFieldDistributorName"
                  className="admin-form-group_item"
                >
                  <Form.Label>Distributor name</Form.Label>
                  <Form.Select
                    aria-label="select distributor name"
                    onChange={(e) => {
                      setField(
                        "distributorCompany",
                        e.target.options[e.target.selectedIndex].dataset
                          .company || ""
                      );

                      setField(
                        "distributorId",
                        parseInt(e.target.value) || null
                      );
                    }}
                    value={form.distributorId || ""}
                  >
                    <option key="empty" value={undefined}>
                      Administrator
                    </option>
                    {!!allDistributors.length &&
                      allDistributors.map((item) => (
                        <option
                          key={item.user_id}
                          value={item.user_id}
                          data-company={item.company}
                        >
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Col className="button-import-wrap">
                  <div>
                    <Form.Control
                      ref={fileRef}
                      onChange={handleFileUpload}
                      type="file"
                      accept=".xlsx"
                      style={{ display: "none" }}
                    />
                  </div>
                  {accountType === "ADMINISTRATOR" ? (
                    <Button
                      className="import-project-file-btn"
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={!isChecked}
                    >
                      Import your project file
                    </Button>
                  ) : null}
                </Col>
              </div>
              <div className="admin-form-group_block">
                <Form.Group
                  controlId="formFieldSalesManager"
                  className="admin-form-group_item"
                >
                  <Form.Label>
                    3X sales manager <span className="red">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="select sales manager"
                    onChange={(e) => {
                      setField("salesManagerId", e.target.value);
                    }}
                    value={form.salesManagerId || ""}
                  >
                    {!!allSalesManagers.length &&
                      allSalesManagers.map((item) => (
                        <option key={item.user_id} value={item.user_id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  controlId="formFieldManager"
                  className="admin-form-group_item"
                >
                  <Form.Label>
                    Engineering manager <span className="red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter engineering manager"
                    value={form.manager}
                    onChange={(e) => setField("manager", e.target.value)}
                    isInvalid={!!errors.manager}
                    disabled={!isChecked}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.manager}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Form>
        </PanelBody>
      )}

      {(accountType === "TECHNICAL_ADMINISTRATOR" ||
        accountType === "TECHNICAL_3X") && (
        <PanelBody>
          <Card.Img
            className={
              isUpload ? "upload-file-image-active" : "upload-file-image"
            }
            src={UploadFile}
          />
          {isLoading && (
            <div className="loading">
              <Spinner className="spinner" animation="border" />
              <span className="loading-text">LOADING...</span>
            </div>
          )}
          <Form className="project-create-form" style={{ display: "block" }}>
            <div
              className="row gx-4 gy-4 align-items-start form-group"
              style={{ width: "100%" }}
            >
              <Col>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldClient">
                    <Form.Label>
                      Client <span className="red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter client"
                      value={form.client}
                      onChange={(e) => setField("client", e.target.value)}
                      isInvalid={!!errors.client}
                      disabled={!isChecked}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.client}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldProject">
                    <Form.Label>
                      Project <span className="red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter project name"
                      value={form.project}
                      onChange={(e) => setField("project", e.target.value)}
                      isInvalid={!!errors.project}
                      disabled={!isChecked}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.project}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldManager">
                    <Form.Label>
                      Engineering manager <span className="red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter engineering manager"
                      value={form.manager}
                      onChange={(e) => setField("manager", e.target.value)}
                      isInvalid={!!errors.manager}
                      disabled={!isChecked}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.manager}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldRepairing">
                    <Form.Label>
                      This document is the technical proposal for repairing :
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter repairing"
                      value={form.repairing}
                      onChange={(e) => setField("repairing", e.target.value)}
                      disabled={!isChecked}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldCompany">
                    <Form.Label>Distributor company :</Form.Label>
                    <Form.Control
                      type="text"
                      value={company}
                      disabled
                      readOnly
                    />
                  </Form.Group>
                </div>
                <div className="form-group_item">
                  <Form.Group
                    controlId="formFieldSalesManager"
                    className="admin-form-group_item"
                  >
                    <Form.Label>
                      3X sales manager <span className="red">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="select sales manager"
                      onChange={(e) => {
                        setField("salesManagerId", e.target.value);
                      }}
                      value={form.salesManagerId || ""}
                    >
                      {!!allSalesManagers.length &&
                        allSalesManagers.map((item) => (
                          <option key={item.user_id} value={item.user_id}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </Col>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              {roles.includes("LOT_COMPOSITE") && (
                <Col className="button-import-wrap">
                  <div>
                    <Form.Control
                      ref={fileRef}
                      onChange={handleFileUpload}
                      type="file"
                      accept=".xlsx"
                      style={{ display: "none" }}
                    />
                  </div>
                  {accountType === "TECHNICAL_ADMINISTRATOR" ? (
                    <Button
                      className="import-project-file-btn"
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={!isChecked}
                    >
                      Import your project file
                    </Button>
                  ) : null}
                </Col>
              )}
            </div>
          </Form>
        </PanelBody>
      )}

      {(accountType === "DISTRIBUTOR" || accountType === "SALE_MANAGER") && (
        <PanelBody>
          <Card.Img
            className={
              isUpload ? "upload-file-image-active" : "upload-file-image"
            }
            src={UploadFile}
          />
          {isLoading && (
            <div className="loading">
              <Spinner className="spinner" animation="border" />
              <span className="loading-text">LOADING...</span>
            </div>
          )}
          <Form className="project-create-form" style={{ display: "block" }}>
            <div
              className="row gx-4 gy-4 align-items-start form-group"
              style={{ width: "100%" }}
            >
              <Col>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldClient">
                    <Form.Label>
                      Client <span className="red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter client"
                      value={form.client}
                      onChange={(e) => setField("client", e.target.value)}
                      isInvalid={!!errors.client}
                      disabled={!isChecked}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.client}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldProject">
                    <Form.Label>
                      Project <span className="red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter project name"
                      value={form.project}
                      onChange={(e) => setField("project", e.target.value)}
                      isInvalid={!!errors.project}
                      disabled={!isChecked}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.project}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldManager">
                    <Form.Label>
                      Engineering manager <span className="red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter engineering manager"
                      value={form.manager}
                      onChange={(e) => setField("manager", e.target.value)}
                      isInvalid={!!errors.manager}
                      disabled={!isChecked}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.manager}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldRepairing">
                    <Form.Label>
                      This document is the technical proposal for repairing :
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter repairing"
                      value={form.repairing}
                      onChange={(e) => setField("repairing", e.target.value)}
                      disabled={!isChecked}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldCompany">
                    <Form.Label>Distributor company :</Form.Label>
                    <Form.Control
                      type="text"
                      value={company}
                      disabled
                      readOnly
                    />
                  </Form.Group>
                </div>
                <div className="form-group_item">
                  <Form.Group controlId="formFieldManager">
                    <Form.Label>3X sales manager :</Form.Label>
                    <Form.Control
                      type="text"
                      value={saleManagerName || ""}
                      disabled
                      readOnly
                    />
                  </Form.Group>
                </div>
              </Col>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              {roles.includes("LOT_COMPOSITE") && (
                <Col className="button-import-wrap">
                  <div>
                    <Form.Control
                      ref={fileRef}
                      onChange={handleFileUpload}
                      type="file"
                      accept=".xlsx"
                      style={{ display: "none" }}
                    />
                  </div>
                  {
                    // accountType === "ADMINISTRATOR" ? (
                    //   <Button
                    //     className="import-project-file-btn"
                    //     variant="primary"
                    //     onClick={handleSubmit}
                    //     disabled={!isChecked}
                    //   >
                    //     Import your project file
                    //   </Button>
                    // ) :
                    null
                  }
                </Col>
              )}
            </div>
          </Form>
        </PanelBody>
      )}

      {config.isShow && <Alert config={config} setConfig={setConfig} />}
    </Row>
  );
};

export default ProjectCreate;
