import React from "react";
import cc from "classcat";
import { useGauge } from "use-gauge";

import "./power.scss";

interface PowerProps {
  value: number;
  label: string;
}

export function Power(props: PowerProps) {
  const { value, label } = props;

  const divider = (() => {
    let divider = 20;

    if (value > 100 && value < 1000) {
      divider = 200;
    }

    if (value > 1000 && value < 10000) {
      divider = 2000;
    }

    if (value > 10000 && value < 100000) {
      divider = 20000;
    }

    if (value > 100000 && value < 1000000) {
      divider = 200000;
    }

    return divider;
  })();

  const getMax = () => {
    let max = 100;

    if (value > 100 && value < 1000) {
      max = 1000;
    }

    if (value > 1000 && value < 10000) {
      max = 10000;
    }

    if (value > 10000 && value < 100000) {
      max = 100000;
    }

    if (value > 100000 && value < 1000000) {
      max = 1000000;
    }

    return max;
  };
  const gauge = useGauge({
    domain: [0, getMax()],
    startAngle: 30,
    endAngle: 330,
    numTicks: 21,
    diameter: 200,
  });

  const needle = gauge.getNeedleProps({
    value,
    baseRadius: 8,
    tipRadius: 2,
  });

  return (
    <div className="power">
      <svg className="w-full overflow-visible p-2" {...gauge.getSVGProps()}>
        <g id="ticks">
          {gauge.ticks.map((angle) => {
            const asValue = gauge.angleToValue(angle);
            const showText = asValue % divider === 0;

            return (
              <React.Fragment key={`tick-group-${angle}`}>
                <line
                  className={cc([
                    "stroke-gray-300",
                    {
                      "stroke-green-300": asValue <= divider,
                      "stroke-yellow-300":
                        asValue >= divider * 3 && asValue <= divider * 4,
                      "stroke-red-400": asValue >= divider * 4,
                    },
                  ])}
                  strokeWidth={2}
                  {...gauge.getTickProps({
                    angle,
                    length: 8,
                  })}
                />
                {showText && (
                  <text
                    className="text-sm fill-gray-400 font-medium"
                    {...gauge.getLabelProps({ angle, offset: 20 })}
                  >
                    {asValue}
                  </text>
                )}
              </React.Fragment>
            );
          })}
        </g>
        <g id="needle">
          <text
            className="text-sm fill-black-400 font-medium"
            x="0"
            y="-50"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            {label}
          </text>
          <text
            className="text-sm fill-black-400 font-medium"
            x="0"
            y="-25"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            MPa
          </text>
          <circle className="fill-gray-300" {...needle.base} r={12} />
          <circle className="fill-red-700" {...needle.base} />
          <circle className="fill-red-700" {...needle.tip} />
          <polyline className="fill-red-700" points={needle.points} />
          <circle className="fill-white" {...needle.base} r={4} />
          <text
            className="text-md fill-black-400 font-medium"
            x="0"
            y="50"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            {parseFloat(`${value}`).toFixed(2)}
          </text>
        </g>
      </svg>
    </div>
  );
}
