import { statusColors } from "constants/";

import React, { useState } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import { Form } from "react-bootstrap";

import {
  ArchiveModal,
  CopyModal,
  JobDoneModal,
  RejectedReasonModal,
} from "components/library";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  addValueStep1,
  fetchAvailableStatusById,
  changeStatus,
  saveProjectData,
  editProposalName,
} from "features/project/project-create.slice";

import EditSvg from "../svg/edit";
import SaveSvg from "../svg/save";

import { TableStyles } from "./styled";
import {
  EditButtonProps,
  DataTableProps,
  EditableCellProps,
  SelectStatusProps,
  CheckboxProps,
} from "./types";

const IndeterminateCheckbox = React.forwardRef(
  // Свойство "current" не существует в типе "(instance: HTMLInputElement | null) => void"
  ({ indeterminate, ...rest }: CheckboxProps, ref: any) => {
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Form.Check
          className="checkbox"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
}: EditableCellProps) => {
  const [value, setValue] = React.useState(initialValue);
  const { isDisabled } = useAppSelector((state) => state.projectCreate.one);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  if (
    id === "tech_proposal_name" ||
    id === "client" ||
    id === "engineer_manager" ||
    id === "project"
  ) {
    return (
      <input
        className="edit-input"
        name={`element${index}`}
        id={`${id + index}`}
        value={value}
        onChange={onChange}
        disabled={!isDisabled}
      />
    );
  }

  return value;
};

const EditButton = ({
  row: { index, original },
  isProposal,
}: EditButtonProps) => {
  const [isFocus, setIsFocus] = React.useState(false);
  const dispatch = useAppDispatch();
  const { isDisabled } = useAppSelector((state) => state.projectCreate.one);

  const onFocus = () => {
    setIsFocus(true);
    dispatch(addValueStep1({ isDisabled: true }));
    const elements = Array.from(document.getElementsByName(`element${index}`));
    for (let c of elements) {
      c.classList.add("focus");
    }
  };

  const onRemoveClass = () => {
    const elements = Array.from(document.getElementsByName(`element${index}`));
    for (let c of elements) {
      c.classList.remove("focus");
    }
    setIsFocus(false);
    if (!isProposal) {
      dispatch(
        saveProjectData({
          index: index,
          id: original.id,
          client: (
            document.getElementById(`client${index}`) as HTMLInputElement
          ).value,
          engineer_manager: (
            document.getElementById(
              `engineer_manager${index}`
            ) as HTMLInputElement
          ).value,
          project: (
            document.getElementById(`project${index}`) as HTMLInputElement
          ).value,
        })
      );
    } else {
      dispatch(
        editProposalName({
          id: original.id,
          name: (
            document.getElementById(
              `tech_proposal_name${index}`
            ) as HTMLInputElement
          ).value,
        })
      );
    }
    dispatch(addValueStep1({ isDisabled: false }));
  };

  if (isFocus) {
    return (
      <button className="save-button hover-btn" onClick={onRemoveClass}>
        <SaveSvg isHover />
        <SaveSvg />
      </button>
    );
  }
  return (
    <button
      disabled={isDisabled}
      className="edit-button hover-btn"
      onClick={onFocus}
    >
      <EditSvg isHover />
      <EditSvg />
    </button>
  );
};

const SelectProjectStatus = ({
  data,
  row: { original },
  isProposal,
}: SelectStatusProps) => {
  const { projectData } = useAppSelector((state) => state.projectCreate.two);
  const { content } = useAppSelector(
    (state) => state.projectCreate.one.projectData
  );
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [value, setValue] = useState(data);

  const dispatch = useAppDispatch();

  if (isProposal) {
    const tableData = projectData.filter((item) => item.id === +original.id);
    const statusArr = tableData.length
      ? Object.entries(tableData[0].available_status || {})
      : [];

    const colorClass =
      !!tableData.length && statusColors[`${tableData[0].status}`];

    const setJobDoneStatus = (repationNumber: string) => {
      dispatch(
        changeStatus({
          id: tableData[0].id,
          tech_proposal_name: tableData[0].tech_proposal_name,
          repair_reference: repationNumber,
          status: "DONE",
          status_description: tableData[0].status_description,
          date: tableData[0].date,
        })
      );
    };

    const setRejectedStatus = (rejectedReason: string) => {
      dispatch(
        changeStatus({
          id: tableData[0].id,
          tech_proposal_name: tableData[0].tech_proposal_name,
          repair_reference: tableData[0].repair_reference,
          status: "REJECTED",
          status_description: tableData[0].status_description,
          date: tableData[0].date,
          rejected_reason: rejectedReason,
        })
      );
    };

    return (
      <>
        <Form.Select
          value={value}
          className={`project-form-select ${colorClass}`}
          onClick={() => dispatch(fetchAvailableStatusById(tableData[0].id))}
          onChange={(e) => {
            setValue(e.target.value);

            let a = document.getElementById(e.target.value);
            const newStatus = a?.dataset.statusKey || "";

            if (newStatus === "DONE") {
              setShowDoneModal(true);
            } else if (newStatus === "REJECTED") {
              setShowRejectedModal(true);
            } else {
              dispatch(
                changeStatus({
                  id: tableData[0].id,
                  tech_proposal_name: tableData[0].tech_proposal_name,
                  repair_reference: tableData[0].repair_reference,
                  status: a?.dataset.statusKey || "",
                  status_description: tableData[0].status_description,
                  date: tableData[0].date,
                })
              );
            }
          }}
        >
          {statusArr.map((item) => {
            return (
              <option
                key={item[1]}
                value={`${item[1]}`}
                data-status-key={item[0]}
                id={item[1]}
              >
                {item[1]}
              </option>
            );
          })}
        </Form.Select>
        <JobDoneModal
          isOpen={showDoneModal}
          handleClose={() => {
            setValue(data);
            setShowDoneModal(false);
          }}
          handleSave={setJobDoneStatus}
        />
        <RejectedReasonModal
          isOpen={showRejectedModal}
          handleClose={() => {
            setValue(data);
            setShowRejectedModal(false);
          }}
          handleSave={setRejectedStatus}
        />
      </>
    );
  } else {
    const tableData = content.filter((item) => item.id === +original.id);
    const colorClass =
      !!tableData.length && statusColors[`${tableData[0].status}`];

    return <span className={`span-select ${colorClass}`}>{data}</span>;
  }
};

const defaultColumn = {
  Cell: EditableCell,
};

function TableSetup<Data extends object>({
  columns,
  data,
  id,
  headerTitle,
  setSelectedIds,
  modal,
  setModal,
  projects,
  onArchive = () => {},
  // onDelete = () => {},
  onUnzip = () => {},
  onCopy = () => {},
  isArchive = false,
  isProposal = false,
}: DataTableProps<Data>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (isProposal)
          return [
            {
              id: "selection",
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div className="checkbox-wrapper">
                  <IndeterminateCheckbox
                    {...getToggleAllPageRowsSelectedProps()}
                  />
                </div>
              ), // RowType from "./types" don`t work for this
              Cell: ({ row }: any) => (
                <div className="checkbox-wrapper">
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns.slice(0, columns.length - 1),
            {
              id: id,
              Header: () => <div>{headerTitle}</div>, // RowType from "./types" don`t work for this
              Cell: ({ row }: any) => {
                return (
                  <div className="project-status">
                    <SelectProjectStatus
                      isProposal={isProposal}
                      row={row}
                      data={row.original["status_description"]}
                    />
                  </div>
                );
              },
            },
            ...columns.slice(columns.length - 1),
            {
              id: "repair_reference",
              Header: () => <div>Reparation Number</div>,
              // RowType from "./types" don`t work for this
              Cell: ({ row }: any) => {
                return <div>{row.original.repair_reference}</div>;
              },
            },
            {
              id: "proposalId",
              Header: () => <div>Proposal ID</div>,
              // RowType from "./types" don`t work for this
              Cell: ({ row }: any) => {
                return <div>{row.original.id}</div>;
              },
            },
            {
              id: "edit", // RowType from "./types" don`t work for this
              Cell: ({ row }: any) => (
                <div className="edit-btn-wrapper">
                  <EditButton row={row} isProposal={isProposal} />
                </div>
              ),
            },
          ];

        return [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div className="checkbox-wrapper">
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ), // RowType from "./types" don`t work for this
            Cell: ({ row }: any) => (
              <div className="checkbox-wrapper">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns.slice(0, columns.length - 1),
          {
            id: id,
            Header: () => <div>{headerTitle}</div>, // RowType from "./types" don`t work for this
            Cell: ({ row }: any) => {
              return (
                <div className="project-status">
                  <SelectProjectStatus
                    isProposal={isProposal}
                    row={row}
                    data={row.original["status_description"]}
                  />
                </div>
              );
            },
          },
          ...columns.slice(columns.length - 1),
          {
            id: "projectId",
            Header: () => <div>Project ID</div>,
            // RowType from "./types" don`t work for this
            Cell: ({ row }: any) => {
              return <div>{row.original.id}</div>;
            },
          },
          {
            id: "edit",
            // RowType from "./types" don`t work for this
            Cell: ({ row }: any) => (
              <div className="edit-btn-wrapper">
                <EditButton row={row} isProposal={isProposal} />
              </div>
            ),
          },
        ];
      });
    }
  );

  React.useEffect(() => {
    setSelectedIds && setSelectedIds(selectedRowIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  return (
    <TableStyles>
      <table
        {...getTableProps()}
        className={isProposal ? "proposal-table" : ""}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render("Header")}
                  <span className="sorting-arrow">
                    {column.id !== "selection" ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <span style={{ color: "#D01317" }}>↑</span>
                        ) : (
                          <span>↓</span>
                        )
                      ) : null
                    ) : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr className="line" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <span>Rows per page:</span>
        <select
          className="rows-per-page"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 15, 20, 25].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <span className="pages-counter">
          {pageIndex + 1} of {pageOptions.length} pages
        </span>
        <div className="btn-group">
          <button
            className="left"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          {pageIndex + 1}
          <button
            className="right"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />
        </div>
      </div>

      <CopyModal
        modal={modal}
        setModal={setModal}
        onClose={onCopy}
        projects={projects}
        isProposal={isProposal}
      />

      {isArchive ? (
        <ArchiveModal
          modal={modal}
          setModal={setModal}
          onClose={onUnzip}
          projects={projects}
          isArchive
        />
      ) : (
        <ArchiveModal
          modal={modal}
          setModal={setModal}
          onClose={onArchive}
          projects={projects}
        />
      )}
    </TableStyles>
  );
}

export default TableSetup;
