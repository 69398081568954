import { Table } from "react-bootstrap";

import "./results-report.scss";

type ResultsReportProps = {
  resultView:
    | {
        description: string;
        valueNumbIso: string;
        valueNumbAsme: string;
      }[]
    | null;
};

const ResultsReport = ({ resultView }: ResultsReportProps) => {
  return (
    <div className="results-report">
      <Table striped bordered hover size="sm">
        <tbody>
          {resultView !== null &&
            resultView.map((item, index) => {
              return (
                <tr key={`${index} ${item.description}`}>
                  <td>{item.description}</td>
                  <td>{item.valueNumbIso}</td>
                  <td>{item.valueNumbAsme}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default ResultsReport;
