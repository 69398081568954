import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import "./modal-get-product.scss";

type ModalGetProductProps = {
  handleClose: Function;
  setField: Function;
  show: boolean;
  text: string;
};

const ModalGetProduct = ({
  handleClose,
  show,
  setField,
  text,
}: ModalGetProductProps) => {
  const [value, setValue] = useState(text);

  return (
    <Modal className="modal-get-product" show={show} centered>
      <Modal.Header onHide={() => handleClose(false)} />

      <Modal.Body>
        <div className="modal-get-product_question">
          Please precise the name of the used chemical product
        </div>
        <Form.Group
          controlId="formFieldChemicalProduct"
          className="formFieldChemicalProduct"
        >
          <div className="formFieldMby_wrap-control">
            <div className="formFieldMby_wrap-number">
              <Form.Control
                as="textarea"
                rows={2}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </div>
          </div>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn-prev" onClick={() => handleClose(false)}>
          Cancel
        </Button>
        <Button
          className="btn-next"
          onClick={() => {
            setField("chemicalProduct", value);
            handleClose(false);
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGetProduct;
