import React from "react";
import { Alert } from "react-bootstrap";

import "./alert.scss";

export interface IAlertConfig {
  isShow: boolean;
  variant: string;
  error: string;
}

interface IAlertProps {
  setConfig: (a: IAlertConfig) => void;
  config: IAlertConfig;
}

const Alerts: React.FC<IAlertProps> = ({ setConfig, config }) => {
  return (
    <Alert
      className="alerts"
      variant={config.variant}
      onClose={() =>
        setConfig({
          ...config,
          isShow: false,
        })
      }
      dismissible
    >
      <p style={{ whiteSpace: "pre-wrap" }}>{config.error}</p>
    </Alert>
  );
};

export default Alerts;
