import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import {
  PanelBody,
  PanelHeader,
  StopModal,
  WarningModal,
} from "components/library";

import DefectExternal from "assets/images/defect_external.png";
import DefectInternal from "assets/images/defect_internal.png";

import { addValueTailorMadeStep5 } from "features/tailor-made/tailor-made.slice";

import {
  addValueStep5,
  calculateMawp,
  fetchDefectTypeDescription,
} from "../project-create.slice";

import BendDefectLocation from "./components/bend-defect-localisation";

import "./defect-dimensions.scss";

type DefectDimensionsProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
};

interface IDefectDimensionsErrors {
  defectAxialLengthMm?: string;
  defectCircumferentialLengthMm?: string;
  minimumResidualWallThicknessMm?: string;
  metalLossPercent?: string;
  lAvailableDownstreamMm?: string;
  lAvailableUpstreamMm?: string;
  repairDesignLifetime?: string;
}

const DefectDimensions = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
}: DefectDimensionsProps) => {
  const { permissions = [] } = useAppSelector((state) => state.loginUser);
  const five = useAppSelector((state) => state.projectCreate.five);
  const { typePipe, environmental, wallThicknessMm, wallThicknessInch } =
    useAppSelector((state) => state.projectCreate.three);
  const [form, setForm] = useState(five);
  const [errors, setErrors] = useState<IDefectDimensionsErrors>({});
  const [showStopModal, setShowStopModal] = useState(false);
  const [crackWarning, setCrackWarning] = useState(false);
  const [dentWarning, setDentWarning] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setField = (field: string, value: string | boolean | number) => {
    setForm((form) => ({
      ...form,
      [field]: value,
    }));

    if (!!errors[field as keyof IDefectDimensionsErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const onLimitedWrappChange = (checked: string) => {
    if (permissions.includes("LIMITED_WRAPPING_SPACE")) {
      if (checked === "YES") {
        setField("isLimitedWrappingSpace", "YES");
      } else {
        setField("isLimitedWrappingSpace", "NO");
        setField("lAvailableDownstreamMm", 0);
        setField("lAvailableDownstreamInch", 0);
        setField("lAvailableUpstreamMm", 0);
        setField("lAvailableUpstreamInch", 0);
      }
    } else setShowStopModal(true);
  };

  const onCustomValueChange = (checked: string) => {
    if (permissions.includes("MAWP")) {
      setField("isCustomValue", checked);
    } else setShowStopModal(true);
  };

  const validateForm = () => {
    const {
      defectAxialLengthMm,
      defectCircumferentialLengthMm,
      minimumResidualWallThicknessMm,
      metalLossPercent,
      lAvailableDownstreamMm,
      lAvailableUpstreamMm,
      isLimitedWrappingSpace,
      repairDesignLifetime,
    } = form;
    const newErrors: IDefectDimensionsErrors = {};

    if (!defectAxialLengthMm || defectAxialLengthMm === 0)
      newErrors.defectAxialLengthMm = "Please enter defect axial length mm";
    if (!defectCircumferentialLengthMm || defectCircumferentialLengthMm === 0)
      newErrors.defectCircumferentialLengthMm =
        "Please enter defect circumferential length mm";
    if (minimumResidualWallThicknessMm < 0)
      newErrors.minimumResidualWallThicknessMm =
        "Please enter minimum residual wall thickness mm";
    if (!metalLossPercent || metalLossPercent === 0)
      newErrors.metalLossPercent = "Please enter metal loss percentage";
    if (
      isLimitedWrappingSpace === "YES" &&
      (!lAvailableDownstreamMm || lAvailableDownstreamMm === 0)
    )
      newErrors.lAvailableDownstreamMm =
        "Please enter L available downstream mm";
    if (
      isLimitedWrappingSpace === "YES" &&
      (!lAvailableUpstreamMm || lAvailableUpstreamMm === 0)
    )
      newErrors.lAvailableUpstreamMm = "Please enter L available upstream mm";
    if (!repairDesignLifetime || repairDesignLifetime === 0)
      newErrors.repairDesignLifetime = "Please enter repair design lifetime";

    return newErrors;
  };

  useEffect(() => {
    dispatch(fetchDefectTypeDescription());
  }, [dispatch]);

  useEffect(() => {
    if (wallThicknessMm - form.metalLossMm > 0.99) {
      setField("defectType", "CORROSION");
    } else {
      setField("defectType", "HOLE");
    }
    dispatch(
      calculateMawp({
        defectAxialLengthMm: form.defectAxialLengthMm,
        defectDepthMm: form.metalLossMm,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.metalLossMm]);

  useEffect(() => {
    if (flagNext) {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setFlagNext(false);
      } else {
        setFlagNext(false);
        form.isCustomValue === "NO"
          ? dispatch(
              addValueStep5({
                ...form,
                mawpValue: five.mawpValue,
              })
            )
          : dispatch(addValueStep5(form));
        dispatch(
          addValueTailorMadeStep5({
            defectAxialLengthMm: form.defectAxialLengthMm,
          })
        );
        navigate("/6");
      }
    }

    if (flagPrev) {
      setFlagPrev(false);
      if (form.defectTypeReport) {
        dispatch(addValueStep5(form));
        dispatch(
          addValueTailorMadeStep5({
            defectAxialLengthMm: form.defectAxialLengthMm,
          })
        );
      }
      navigate("/4");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev]);

  return (
    <Row className="gx-0">
      <WarningModal
        showWarningModal={crackWarning}
        setShowWarningModal={setCrackWarning}
      >
        Specification of the standard for the calculation of cracks.
        <br />
        <br />
        For through wall cracks, the crack should be modelled as a Type B
        defect, either a circumferential or axial slot (depending on the crack
        orientation). For non-through wall cracks, the crack should be modelled
        as Type A defect.
      </WarningModal>
      <WarningModal
        showWarningModal={dentWarning}
        setShowWarningModal={setDentWarning}
      >
        Warning
        <br />
        <br />
        The calculation of dent defect cannot be perfomed with R.E.A. software.
        Please contact 3X ENGINEERING for specific calculation with Finite
        Element Analysis.
      </WarningModal>
      <Form className="form-temperature-loads">
        <Col className="panel-wrap">
          <PanelHeader className="min-height" title="Pipe Defect" />
          <PanelBody>
            <div className="row align-items-start">
              <Col className="pipeDefect">
                <Form.Group
                  controlId="formFieldDefectTypeReport"
                  className="formFieldDefects"
                >
                  <Form.Label>Defect type</Form.Label>
                  <div className="formFieldDefects_item">
                    <Form.Label>
                      Metal loss (partial or through wall)
                    </Form.Label>
                    <Form.Check
                      checked={form.defectTypeReport === "METAL_LOSS"}
                      name="groupDefectTypeReport"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectTypeReport", "METAL_LOSS");
                      }}
                    />
                  </div>
                  <div className="formFieldDefects_item">
                    <Form.Label>Crack</Form.Label>
                    <Form.Check
                      checked={form.defectTypeReport === "CRACK"}
                      name="groupDefectTypeReport"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectTypeReport", "CRACK");
                        setCrackWarning(true);
                      }}
                    />
                  </div>
                  <div className="formFieldDefects_item">
                    <Form.Label>Dent</Form.Label>
                    <Form.Check
                      checked={form.defectTypeReport === "DENT"}
                      name="groupDefectTypeReport"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectTypeReport", "DENT");
                        setDentWarning(true);
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  controlId="formFieldDefectOrigin"
                  className="formFieldDefects"
                >
                  <Form.Label>Origin of the defect</Form.Label>
                  <div className="formFieldDefects_item">
                    <Form.Label>Corrosion / Pitting</Form.Label>
                    <Form.Check
                      checked={form.defectOrigin === "CORROSION"}
                      name="groupDefectOrigin"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectOrigin", "CORROSION");
                      }}
                    />
                  </div>
                  <div className="formFieldDefects_item">
                    <Form.Label>Abrasion / erosion</Form.Label>
                    <Form.Check
                      checked={form.defectOrigin === "ABRASION"}
                      name="groupDefectOrigin"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectOrigin", "ABRASION");
                      }}
                    />
                  </div>
                  <div className="formFieldDefects_item">
                    <Form.Label>Impact</Form.Label>
                    <Form.Check
                      checked={form.defectOrigin === "IMPACT"}
                      name="groupDefectOrigin"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectOrigin", "IMPACT");
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="formFieldDefectLocation"
                  className="formFieldDefects"
                >
                  <Form.Label>Defect location</Form.Label>
                  <div className="formFieldDefects_item">
                    <Form.Label>External</Form.Label>
                    <Form.Check
                      checked={form.defectLocation === "EXTERNAL"}
                      name="groupDefectLocation"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectLocation", "EXTERNAL");
                      }}
                    />
                  </div>
                  <div className="formFieldDefects_item">
                    <Form.Label>Internal</Form.Label>
                    <Form.Check
                      checked={form.defectLocation === "INTERNAL"}
                      name="groupDefectLocation"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked)
                          setField("defectLocation", "INTERNAL");
                      }}
                    />
                  </div>
                </Form.Group>
                {form.defectLocation === "EXTERNAL" && (
                  <img src={DefectExternal} alt="view defect external pipe" />
                )}
                {form.defectLocation === "INTERNAL" && (
                  <img src={DefectInternal} alt="view defect internal pipe" />
                )}
              </Col>
            </div>
          </PanelBody>
          <PanelHeader title="Defect dimensions" className="mt-2 min-height" />
          <PanelBody>
            <div className="row align-items-start">
              <Form.Group
                controlId="formFieldDefectDimensions"
                className="formFieldDefectDimensions"
              >
                <div className="form-dimensions-wrapp">
                  <Form.Label>Defect axial length (X)</Form.Label>
                  <div>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.defectAxialLengthMm}
                      isInvalid={!!errors.defectAxialLengthMm}
                      onChange={(e) => {
                        setField("defectAxialLengthMm", e.target.value);
                        setField(
                          "defectAxialLengthInch",
                          parseFloat(
                            (parseFloat(e.target.value) * 0.0393701).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>mm</Form.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.defectAxialLengthInch}
                      onChange={(e) => {
                        setField("defectAxialLengthInch", e.target.value);
                        setField(
                          "defectAxialLengthMm",
                          parseFloat(
                            (parseFloat(e.target.value) * 25.4).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: !!errors.defectAxialLengthMm ? "block" : "none",
                  }}
                >
                  {errors.defectAxialLengthMm}
                </Form.Control.Feedback>
                <div className="form-dimensions-wrapp">
                  <Form.Label>Defect circumferential length (Lt)</Form.Label>
                  <div>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.defectCircumferentialLengthMm}
                      isInvalid={!!errors.defectCircumferentialLengthMm}
                      onChange={(e) => {
                        setField(
                          "defectCircumferentialLengthMm",
                          e.target.value
                        );
                        setField(
                          "defectCircumferentialLengthInch",
                          parseFloat(
                            (parseFloat(e.target.value) * 0.0393701).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>mm</Form.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.defectCircumferentialLengthInch}
                      onChange={(e) => {
                        setField(
                          "defectCircumferentialLengthInch",
                          e.target.value
                        );
                        setField(
                          "defectCircumferentialLengthMm",
                          parseFloat(
                            (parseFloat(e.target.value) * 25.4).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: !!errors.defectCircumferentialLengthMm
                      ? "block"
                      : "none",
                  }}
                >
                  {errors.defectCircumferentialLengthMm}
                </Form.Control.Feedback>
                <div className="form-dimensions-wrapp">
                  <Form.Label>
                    Minimum residual wall thickness in defect area
                  </Form.Label>
                  <div>
                    <Form.Control
                      type="number"
                      min={0}
                      max={wallThicknessMm}
                      value={form.minimumResidualWallThicknessMm}
                      isInvalid={!!errors.minimumResidualWallThicknessMm}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (parseFloat(value) > wallThicknessMm) {
                          value = `${wallThicknessMm}`;
                        }
                        setField(
                          "minimumResidualWallThicknessMm",
                          parseFloat(value)
                        );
                        setField(
                          "minimumResidualWallThicknessInch",
                          parseFloat((parseFloat(value) * 0.0393701).toFixed(2))
                        );
                        setField(
                          "metalLossPercent",
                          parseFloat(
                            (
                              ((wallThicknessMm - parseFloat(value)) * 100) /
                              wallThicknessMm
                            ).toFixed(2)
                          )
                        );
                        setField(
                          "metalLossMm",
                          parseFloat(
                            (wallThicknessMm - parseFloat(value)).toFixed(2)
                          )
                        );
                        setField(
                          "metalLossInch",
                          parseFloat(
                            (
                              (wallThicknessMm - parseFloat(value)) *
                              0.0393701
                            ).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>mm</Form.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      max={wallThicknessInch}
                      value={form.minimumResidualWallThicknessInch}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (parseFloat(value) > wallThicknessInch) {
                          value = `${wallThicknessInch}`;
                        }
                        setField("minimumResidualWallThicknessInch", value);
                        setField(
                          "minimumResidualWallThicknessMm",
                          parseFloat((parseFloat(value) * 25.4).toFixed(2))
                        );
                        setField(
                          "metalLossPercent",
                          ((wallThicknessMm - parseFloat(value) * 25.4) * 100) /
                            wallThicknessMm
                        );
                        setField(
                          "metalLossMm",
                          parseFloat(
                            (
                              wallThicknessMm -
                              parseFloat(value) * 25.4
                            ).toFixed(2)
                          )
                        );
                        setField(
                          "metalLossInch",
                          parseFloat(
                            (
                              (wallThicknessMm - parseFloat(value) * 25.4) *
                              0.0393701
                            ).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: !!errors.minimumResidualWallThicknessMm
                      ? "block"
                      : "none",
                  }}
                >
                  {errors.minimumResidualWallThicknessMm}
                </Form.Control.Feedback>
                <div className="form-dimensions-wrapp">
                  <div className="metal-loss">
                    <Form.Label>Metal loss</Form.Label>
                    <Form.Control
                      className="metal-loss-percent"
                      type="number"
                      min={0}
                      max={100}
                      value={form.metalLossPercent}
                      isInvalid={!!errors.metalLossPercent}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (parseFloat(value) > 100) {
                          value = "100";
                        }
                        setField("metalLossPercent", value);
                        setField(
                          "metalLossMm",
                          parseFloat(
                            (
                              (wallThicknessMm * parseFloat(value)) /
                              100
                            ).toFixed(2)
                          )
                        );
                        setField(
                          "metalLossInch",
                          parseFloat(
                            (
                              ((wallThicknessMm * parseFloat(value)) / 100) *
                              0.0393701
                            ).toFixed(2)
                          )
                        );
                        setField(
                          "minimumResidualWallThicknessMm",
                          parseFloat(
                            (
                              wallThicknessMm -
                              (wallThicknessMm * parseFloat(value)) / 100
                            ).toFixed(2)
                          )
                        );
                        setField(
                          "minimumResidualWallThicknessInch",
                          parseFloat(
                            (
                              (wallThicknessMm -
                                (wallThicknessMm * parseFloat(value)) / 100) *
                              0.0393701
                            ).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>%</Form.Text>
                  </div>
                  <div>
                    <Form.Control
                      type="number"
                      min={0}
                      max={wallThicknessMm}
                      value={form.metalLossMm}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (parseFloat(value) > wallThicknessMm) {
                          value = `${wallThicknessMm}`;
                        }
                        setField("metalLossMm", value);
                        setField(
                          "metalLossInch",
                          parseFloat((parseFloat(value) * 0.0393701).toFixed(2))
                        );
                        setField(
                          "metalLossPercent",
                          parseFloat(
                            (
                              (parseFloat(value) * 100) /
                              wallThicknessMm
                            ).toFixed(2)
                          )
                        );
                        setField(
                          "minimumResidualWallThicknessMm",
                          parseFloat(
                            (wallThicknessMm - parseFloat(value)).toFixed(2)
                          )
                        );
                        setField(
                          "minimumResidualWallThicknessInch",
                          parseFloat(
                            (
                              (wallThicknessMm - parseFloat(value)) *
                              0.0393701
                            ).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>mm</Form.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      max={wallThicknessInch}
                      value={form.metalLossInch}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (parseFloat(value) > wallThicknessInch) {
                          value = `${wallThicknessInch}`;
                        }
                        setField("metalLossInch", e.target.value);
                        setField(
                          "metalLossMm",
                          parseFloat((parseFloat(value) * 25.4).toFixed(2))
                        );
                        setField("metalLossPercent", parseFloat(value) * 254);
                      }}
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                </div>
                <Form.Control.Feedback
                  className="feedback"
                  type="invalid"
                  style={{
                    display: !!errors.metalLossPercent ? "block" : "none",
                  }}
                >
                  {errors.metalLossPercent}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </PanelBody>
          {form.metalLossPercent > 99 && (
            <>
              <PanelHeader
                theme="light"
                title="Sealing procedure"
                className="mt-2 min-height"
              />
              <Form.Group className="formFieldSealingProcedure">
                <div className="formFieldSealingProcedure_item">
                  <Form.Label>
                    Shotdown line repair: FIXOKiT
                    <br />
                    <span>
                      (Optimal sealing procedure, even for non-leaking internal
                      defect)
                    </span>
                  </Form.Label>
                  <Form.Check
                    checked={form.gammaLcl === "LCL_1"}
                    name="lcl_1"
                    type="radio"
                    label=""
                    onChange={(e) => {
                      if (e.target.checked) setField("gammaLcl", "LCL_1");
                    }}
                  />
                </div>
                {environmental !== "OFFSHORE_SUBSEA" && (
                  <div className="formFieldSealingProcedure_item">
                    <Form.Label>
                      Online repair: FIXOKiT
                      <br />
                      <span>
                        (Optimal sealing procedure with residual pressure)
                      </span>
                    </Form.Label>
                    <Form.Check
                      checked={form.gammaLcl === "LCL_2"}
                      name="lcl_2"
                      type="radio"
                      label=""
                      onChange={(e) => {
                        if (e.target.checked) setField("gammaLcl", "LCL_2");
                      }}
                    />
                  </div>
                )}
                <div className="formFieldSealingProcedure_item">
                  <Form.Label>
                    No sealing procedure
                    <br />
                    <span>(Not recommended)</span>
                  </Form.Label>
                  <Form.Check
                    checked={form.gammaLcl === "LCL_3"}
                    name="lcl_3"
                    type="radio"
                    label=""
                    onChange={(e) => {
                      if (e.target.checked) setField("gammaLcl", "LCL_3");
                    }}
                  />
                </div>
              </Form.Group>
            </>
          )}
        </Col>
        <Col>
          <BendDefectLocation
            typePipe={typePipe}
            defectLocation={form.defectLocation}
            bendDefectLocation={form.bendDefectLocation}
            isBendLocalisationDefect={form.isBendLocalisationDefect}
            setField={setField}
          />
          <PanelHeader
            title="MAWP"
            className="mt-2 min-height"
            theme="light"
            isDefectDimensionsMAWP
            isDimensionsCheck={form.isCustomValue}
            setDimensionsCheck={onCustomValueChange}
          />
          <PanelBody theme="light">
            <Form.Group>
              <div className="mawp-wrapp">
                <div>
                  <Form.Label>Calculated value:</Form.Label>
                  <Form.Control
                    type="number"
                    value={(+five.mawpValue).toFixed(2)}
                    readOnly
                  />
                  <Form.Text>MPa</Form.Text>
                </div>
                <div
                  style={{
                    display: form.isCustomValue === "YES" ? "flex" : "none",
                  }}
                >
                  <Form.Label>Custom value:</Form.Label>
                  <Form.Control
                    type="number"
                    value={form.mawpValue}
                    onChange={(e) => setField("mawpValue", e.target.value)}
                  />
                  <Form.Text>MPa</Form.Text>
                </div>
              </div>
              <StopModal
                showStopModal={showStopModal}
                setShowStopModal={setShowStopModal}
              />
            </Form.Group>
          </PanelBody>
          <PanelHeader
            title="Limited wrapping space"
            className="mt-2 panel-background min-height"
            isDefectDimensionsMAWP
            isDimensionsCheck={form.isLimitedWrappingSpace}
            setDimensionsCheck={onLimitedWrappChange}
          />
          {form.isLimitedWrappingSpace === "YES" && (
            <PanelBody theme="light-red">
              <Form.Group>
                <div className="limitedSpaceInputWrapp">
                  <Form.Label>L available downstream</Form.Label>
                  <div>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.lAvailableDownstreamMm || ""}
                      isInvalid={!!errors.lAvailableDownstreamMm}
                      onChange={(e) => {
                        setField(
                          "lAvailableDownstreamMm",
                          parseFloat(e.target.value)
                        );
                        setField(
                          "lAvailableDownstreamInch",
                          parseFloat(
                            (parseFloat(e.target.value) * 0.0393701).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>mm</Form.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.lAvailableDownstreamInch || ""}
                      onChange={(e) => {
                        setField(
                          "lAvailableDownstreamInch",
                          parseFloat(e.target.value)
                        );
                        setField(
                          "lAvailableDownstreamMm",
                          parseFloat(
                            (parseFloat(e.target.value) * 25.4).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: !!errors.lAvailableDownstreamMm ? "block" : "none",
                  }}
                >
                  {errors.lAvailableDownstreamMm}
                </Form.Control.Feedback>
                <div className="limitedSpaceInputWrapp">
                  <Form.Label>L available upstream</Form.Label>
                  <div>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.lAvailableUpstreamMm || ""}
                      isInvalid={!!errors.lAvailableUpstreamMm}
                      onChange={(e) => {
                        setField(
                          "lAvailableUpstreamMm",
                          parseFloat(e.target.value)
                        );
                        setField(
                          "lAvailableUpstreamInch",
                          parseFloat(
                            (parseFloat(e.target.value) * 0.0393701).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>mm</Form.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.lAvailableUpstreamInch || ""}
                      onChange={(e) => {
                        setField(
                          "lAvailableUpstreamInch",
                          parseFloat(e.target.value)
                        );
                        setField(
                          "lAvailableUpstreamMm",
                          parseFloat(
                            (parseFloat(e.target.value) * 25.4).toFixed(2)
                          )
                        );
                      }}
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                </div>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: !!errors.lAvailableUpstreamMm ? "block" : "none",
                  }}
                >
                  {errors.lAvailableUpstreamMm}
                </Form.Control.Feedback>
              </Form.Group>
            </PanelBody>
          )}
          <div className="repairDesignDefectBlock">
            <div className="formFieldSecondaryDefects">
              <Form.Group className="formFieldSecondaryDefects_radio">
                <div>
                  <Form.Label>Defect Type A</Form.Label>
                  <Form.Check
                    checked={form.defectType === "CORROSION"}
                    name="corrosion"
                    type="radio"
                    label=""
                    disabled
                  />
                </div>
                <div>
                  <Form.Label>Defect Type B</Form.Label>
                  <Form.Check
                    checked={form.defectType === "HOLE"}
                    name="hole"
                    type="radio"
                    label=""
                    disabled
                  />
                </div>
              </Form.Group>
              <p>
                {
                  five.defectTypeDescription.find(
                    (item) => item.id === form.defectType
                  )?.name
                }
              </p>
            </div>
          </div>
          <div className="repairDesignLifetimeBlock mt-2">
            <div className="repairDesignLifetimeBlock_item">
              <Form.Label>REPAIR DESIGN LIFETIME</Form.Label>
              <div>
                <Form.Text>
                  {permissions.includes("LIFETIME")
                    ? "Years (0-100)"
                    : "Years (2-20)"}
                </Form.Text>
                <Form.Control
                  type="number"
                  min={permissions.includes("LIFETIME") ? 0 : 2}
                  max={permissions.includes("LIFETIME") ? 100 : 20}
                  isInvalid={!!errors.repairDesignLifetime}
                  value={form.repairDesignLifetime || ""}
                  onChange={(e) =>
                    setField("repairDesignLifetime", e.target.value)
                  }
                />
              </div>
            </div>
            <Form.Control.Feedback
              type="invalid"
              style={{
                display: !!errors.repairDesignLifetime ? "block" : "none",
              }}
            >
              {errors.repairDesignLifetime}
            </Form.Control.Feedback>
          </div>
        </Col>
      </Form>
    </Row>
  );
};

export default DefectDimensions;
