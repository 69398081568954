/* eslint-disable indent */
import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Column } from "react-table";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "app/hook";
import { TableSetup, PanelHeader } from "components/library";

import { Content } from "./project-create.slice.type";
import {
  archiveProjectTable,
  unzipProjectTable,
  fetchAllProjects,
  exportProjectFile,
  selectedProjectId,
  fetchArchiveProjects,
  archiveProjects,
  copyProjects,
  copyProjectTableItems,
  unzipProjects,
} from "./project-create.slice";

type ProjectExistsProps = {
  flagNext: boolean;
  setFlagNext: Function;
  isChecked: boolean;
  flagCheck: string;
  setFlagCheck: Function;
};

export type IdsProjectState = {
  ids: Array<number>;
  projects: Array<string>;
};

const columns: Column<Content>[] = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Client",
    accessor: "client",
  },
  {
    Header: "Engineer manager",
    accessor: "engineer_manager",
  },
  {
    Header: "Project",
    accessor: "project",
  },
];

const ProjectExists = ({
  flagNext,
  setFlagNext,
  isChecked,
  flagCheck,
  setFlagCheck,
}: ProjectExistsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    archiveTableData,
    projectData,
    isExportLoading,
    isIdsLoaded,
    newIds,
  } = useAppSelector((state) => state.projectCreate.one);
  const { token } = useAppSelector((state) => state.loginUser);
  const [isArchive, setIsArchive] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState(
    projectData.content ? projectData.content : []
  );
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<object | null>(null);
  const [modal, setModal] = useState({
    isArchiveOpen: false,
    isCopyOpen: false,
    title: "",
  });
  const [idsWithProjects, setIdsWithProjects] = useState<IdsProjectState>({
    ids: [],
    projects: [],
  });

  useEffect(() => {
    !isArchive
      ? setFilteredData(projectData.content)
      : setFilteredData(archiveTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchive]);

  useEffect(() => {
    if (token) {
      dispatch(
        fetchAllProjects({
          pageIndex: 0,
          pageSize: projectData.totalElements || projectData.size,
        })
      );
      dispatch(
        fetchArchiveProjects({
          pageIndex: 0,
          pageSize: projectData.totalElements || projectData.size,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const ids = [];
    const projects = [];

    for (let key in selectedIds) {
      ids.push(+key);
      projects.push(
        !isArchive
          ? projectData.content[+key].project
          : archiveTableData[+key].project
      );
    }
    setIdsWithProjects({
      ids: ids,
      projects: projects,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  useEffect(() => {
    if (projectData.content) {
      const searchData = !isArchive
        ? projectData.content.filter(
            (item) =>
              item.project.includes(searchValue) ||
              item.client.includes(searchValue) ||
              item.engineer_manager.includes(searchValue) ||
              `${item.id}`.includes(searchValue)
          )
        : archiveTableData.filter(
            (item) =>
              item.project.includes(searchValue) ||
              item.client.includes(searchValue) ||
              item.engineer_manager.includes(searchValue) ||
              `${item.id}`.includes(searchValue)
          );
      setFilteredData(searchData);
    } else {
      setFilteredData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, projectData.content]);

  useEffect(() => {
    if (isIdsLoaded) {
      dispatch(
        copyProjectTableItems({
          selectedIds: idsWithProjects.ids,
          newIds: newIds,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdsLoaded]);

  const onArchive = () => {
    dispatch(archiveProjects(idsWithProjects.ids));
    dispatch(archiveProjectTable(idsWithProjects.ids));
    setModal({
      ...modal,
      isArchiveOpen: false,
    });
  };

  const onUnzip = () => {
    if (idsWithProjects.ids.length > 1) {
      setModal({
        ...modal,
        isArchiveOpen: false,
      });
      setIsWarning(true);
    } else {
      dispatch(unzipProjects(idsWithProjects.ids));
      dispatch(unzipProjectTable(idsWithProjects.ids));
      setModal({
        ...modal,
        isArchiveOpen: false,
      });
    }
  };

  const onCopy = () => {
    dispatch(copyProjects(idsWithProjects.ids));
    setModal({
      ...modal,
      isCopyOpen: false,
    });
  };

  const exportFile = () => {
    if (idsWithProjects.ids.length > 1) {
      setIsWarning(true);
    } else {
      dispatch(exportProjectFile(idsWithProjects.ids[0]));
    }
  };

  useEffect(() => {
    if (flagNext && isChecked) {
      if (idsWithProjects.ids.length !== 1) {
        setIsWarning(true);
      } else {
        dispatch(selectedProjectId(idsWithProjects.ids[0]));
        navigate("/2");
      }
    }
    setFlagNext(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, isChecked]);

  return (
    <Row
      onClick={() => setFlagCheck("exist")}
      className={!isChecked ? "gx-0 mt-4 opacity-50" : "gx-0 mt-4"}
    >
      <PanelHeader
        title="Select existing project"
        theme="light"
        isChecked={isChecked}
        flagCheck={flagCheck}
        setFlagCheck={setFlagCheck}
        isTableHeader
        isArchive={isArchive}
        setIsArchive={setIsArchive}
        setModal={setModal}
        selected={idsWithProjects.ids.length}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        isWarning={isWarning}
        setIsWarning={setIsWarning}
        exportFile={exportFile}
        isExportLoading={isExportLoading}
      />
      {!isArchive ? (
        <TableSetup
          columns={columns}
          data={filteredData}
          id="projectStatus"
          headerTitle="Project Status"
          setSelectedIds={setSelectedIds}
          modal={modal}
          setModal={setModal}
          projects={idsWithProjects.projects}
          onArchive={onArchive}
          onCopy={onCopy}
        />
      ) : (
        <TableSetup
          columns={columns}
          data={filteredData}
          id="projectStatus"
          headerTitle="Project Status"
          setSelectedIds={setSelectedIds}
          modal={modal}
          setModal={setModal}
          projects={idsWithProjects.projects}
          onUnzip={onUnzip}
          isArchive
        />
      )}
    </Row>
  );
};

export default ProjectExists;
