import { Button, Modal } from "react-bootstrap";

import Avatar from "assets/images/avatar.svg";
import { useAppDispatch, useAppSelector } from "app/hook";
import { authLogout } from "features/auth/auth-slice";
import { resetStateProjectCreate } from "features/project/project-create.slice";
import { resetStateImportFile } from "features/project/project-import-file.slice";
import { cleanValueTailorMade } from "features/tailor-made/tailor-made.slice";

import "./modal-info-user.scss";

type ModalInfoUserProps = {
  handleClose: Function;
};

const ModalInfoUser = ({ handleClose }: ModalInfoUserProps) => {
  const loginUser = useAppSelector((state) => state.loginUser);
  const { name, email, continent, phone, location, saleManagerName } =
    loginUser;

  const dispatch = useAppDispatch();

  const handleExit = () => {
    handleClose(false);
    dispatch(authLogout());
    dispatch(resetStateProjectCreate());
    dispatch(resetStateImportFile());
    dispatch(cleanValueTailorMade());
  };

  return (
    <Modal.Dialog className="modal-info-user">
      <Modal.Header closeButton onHide={() => handleClose(false)} />
      {/* <Modal.Title>
          
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Body>
        <div>
          <img src={Avatar} width="87px" alt="avatar user" />
        </div>
        <div className="modal-info-user_fio">{name}</div>
        <div className="modal-info-user_email">{email}</div>
        <div className="modal-info-user_subtitle">{continent}</div>
        <div className="modal-info-user_subtitle">{location}</div>
        <div className="modal-info-user_subtitle">{phone}</div>

        {saleManagerName !== null && saleManagerName !== undefined && (
          <div className="modal-info-user_wrap-manager">
            <div className="modal-info-user_fio-manager">Adam Drampe</div>
            <div className="modal-info-user_position">(sales manager)</div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => handleExit()}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
};

export default ModalInfoUser;
