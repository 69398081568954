import { Form } from "react-bootstrap";

import { PanelBody, PanelHeader } from "components/library";
import DefectExternalTee from "assets/images/tee_external.png";
import DefectInternalTee from "assets/images/tee_internal.png";
import BendInternalExternal from "assets/images/bend_internal_external.png";
import BendExternalExternal from "assets/images/bend_external_external.png";
import BendInternalInternal from "assets/images/bend_internal_internal.png";
import BendExternalInternal from "assets/images/bend_external_internal.png";
import BendExternalNeutral from "assets/images/bend_external_neutral.png";
import BendInternalNeutral from "assets/images/bend_internal_neutral.png";
import StraightExternal from "assets/images/straight_external.png";
import StraightInternal from "assets/images/straight_internal.png";

type BendDefectLocationProps = {
  typePipe: string;
  defectLocation: string;
  bendDefectLocation: string;
  isBendLocalisationDefect: string;
  setField: (field: string, value: string | boolean | number) => void;
}

const BendDefectLocation = ({
  typePipe,
  defectLocation,
  bendDefectLocation,
  isBendLocalisationDefect,
  setField,
}: BendDefectLocationProps) => {
  return (
    <>
      <PanelHeader
        title={typePipe === "BEND" ? "Bend defect location" : "Defect location"}
        theme="white"
        className="min-height"
      />
      <PanelBody theme="white">
        {typePipe === "STRAIGHT" && (
          defectLocation === "EXTERNAL" ? (
            <img src={StraightExternal} alt="view defect external pipe" />
          ) :
            defectLocation === "INTERNAL" && (
              <img src={StraightInternal} alt="view defect internal pipe" />
            )
        )}
        {typePipe === "BEND" && (
          <Form.Group className="typePipeBend">
            <p>You know the defect location?</p>
            <div className="defect-location-check">
              <Form.Check
                label="Yes"
                checked={isBendLocalisationDefect === "YES"}
                onChange={(e) => {
                  if (e.target.checked)
                    setField("isBendLocalisationDefect", "YES");
                }}
              />
              <Form.Check
                label="No"
                checked={isBendLocalisationDefect === "NO"}
                onChange={(e) => {
                  if (e.target.checked)
                    setField("isBendLocalisationDefect", "NO");
                }}
              />
            </div>
          </Form.Group>
        )}
        {typePipe === "BEND" && (
          isBendLocalisationDefect === "NO" ? (
            defectLocation === "EXTERNAL" ? 
              <img src={BendExternalExternal} alt="view bend internal external" /> : 
              <img src={BendInternalExternal} alt="view bend internal external" />
          ) : (
            <div className="typePipeBendKnowLocation">
              <Form.Group>
                <div className="bendDefectLocation">
                  <Form.Label>External</Form.Label>
                  <Form.Check
                    checked={bendDefectLocation === "EXTERNAL"}
                    name="external"
                    type="radio"
                    label=""
                    onChange={(e) => {
                      if (e.target.checked)
                        setField("bendDefectLocation", "EXTERNAL");
                    }}
                  />
                </div>
                <div className="bendDefectLocation">
                  <Form.Label>Internal</Form.Label>
                  <Form.Check
                    checked={bendDefectLocation === "INTERNAL"}
                    name="internal"
                    type="radio"
                    label=""
                    onChange={(e) => {
                      if (e.target.checked)
                        setField("bendDefectLocation", "INTERNAL");
                    }}
                  />
                </div>
                <div className="bendDefectLocation">
                  <Form.Label>Neutral</Form.Label>
                  <Form.Check
                    checked={bendDefectLocation === "NEUTRAL"}
                    name="neutral"
                    type="radio"
                    label=""
                    onChange={(e) => {
                      if (e.target.checked)
                        setField("bendDefectLocation", "NEUTRAL");
                    }}
                  />
                </div>
              </Form.Group>
              {bendDefectLocation === "EXTERNAL" && (
                defectLocation === "EXTERNAL" ?
                  <img src={BendExternalExternal} alt="view bend external external" /> :
                  <img src={BendInternalExternal} alt="view bend internal external" />
              )}
              {bendDefectLocation === "INTERNAL" && (
                defectLocation === "EXTERNAL" ?
                  <img src={BendExternalInternal} alt="view bend internal external" /> :
                  <img src={BendInternalInternal} alt="view bend internal internal" />
              )}
              {bendDefectLocation === "NEUTRAL" && (
                defectLocation === "EXTERNAL" ?
                  <img src={BendExternalNeutral} alt="view bend external neutral" /> :
                  <img src={BendInternalNeutral} alt="view bend internal neutral" />
              )}
            </div>
          )
        )}
        {typePipe === "TEE" && (
          defectLocation === "EXTERNAL" ? (
            <img src={DefectExternalTee} alt="view defect external tee" />
          ) :
            defectLocation === "INTERNAL" && (
              <img src={DefectInternalTee} alt="view defect internal tee" />
            )
        )}
      </PanelBody>
    </>
  );
};

export default BendDefectLocation;
