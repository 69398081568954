import "./radio.scss";

type RadioProps = {
  label: string;
  checked: boolean;
  flagCheck: string;
  setFlagCheck: Function;
};

const Radio = ({ label, checked, flagCheck, setFlagCheck }: RadioProps) => {
  return (
    <div className="radio-wrapper">
      <label htmlFor={label}>
        <input
          type="radio"
          id={label}
          name={label}
          checked={checked}
          onChange={() => setFlagCheck(flagCheck)}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Radio;
