import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import { PanelBody, PanelHeader, Alert } from "components/library";
import { IAlertConfig } from "components/library/alert/alert";
import { checkFormat } from "helpers/checkFormat";
import UploadFile from "assets/images/upload-file.png";

import { addValueStep2 } from "../project-create.slice";
import { fetchFile } from "../project-import-file.slice";

import "../project-create.scss";

type ProposalCreateProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
  isChecked: boolean;
  flagCheck: string;
  setFlagCheck: Function;
};

interface IProposalErrors {
  proposalName?: string;
}

const ProposalCreate = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
  isChecked,
  flagCheck,
  setFlagCheck,
}: ProposalCreateProps) => {
  const { accountType } = useAppSelector((state) => state.loginUser);
  const two = useAppSelector((state) => state.projectCreate.two);
  const [form, setForm] = useState(two);
  const [errors, setErrors] = useState<IProposalErrors>({});
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [config, setConfig] = useState<IAlertConfig>({
    isShow: false,
    variant: "danger",
    error: "",
  });

  const { isLoading, error } = useAppSelector((state) => state.fetchFile.file);
  const fileRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setField = (field: string, value: string) => {
    setForm((form) => ({
      ...form,
      [field]: value,
    }));

    if (!!errors[field as keyof IProposalErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { proposalName } = form;
    const newErrors: IProposalErrors = {};

    if (!proposalName || proposalName === "")
      newErrors.proposalName = "Please enter technical proposal name";

    return newErrors;
  };

  useEffect(() => {
    if (flagNext && isChecked) {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        dispatch(addValueStep2(form));
        navigate("/3");
      }
    }

    if (flagPrev && isChecked) {
      if (form.proposalName) {
        dispatch(addValueStep2(form));
      }
      navigate("/");
    }
    setFlagNext(false);
    setFlagPrev(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev, isChecked]);

  useEffect(() => {
    error &&
      setConfig({
        ...config,
        isShow: true,
        error: error,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleSubmit = () => {
    document.body.onfocus = handleFileUpload as any;
    if (fileRef.current) {
      fileRef.current.click();
      setIsUpload(true);
      setConfig({
        ...config,
        isShow: false,
      });
    }
  };

  const handleFileUpload = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.files && !checkFormat(event.target.files[0].name)) {
      setConfig({
        ...config,
        isShow: true,
        error: "Please choose the file in xlsx format",
      });
      setIsUpload(false);
    } else if (event.target.files && checkFormat(event.target.files[0].name)) {
      dispatch(fetchFile(event.target.files[0]));
      setIsUpload(false);
    } else {
      setIsUpload(false);
      document.body.onfocus = null;
    }
  };

  const getClasses = () => {
    let result = "gx-0";
    if (isUpload || isLoading) result += " create-project-form";
    if (!isChecked) result += " opacity-50";

    return result;
  };

  return (
    <Row className={getClasses()}>
      <PanelHeader
        title="Create new technical proposal"
        isChecked={isChecked}
        flagCheck={flagCheck}
        setFlagCheck={setFlagCheck}
      />
      <PanelBody>
        <Form className="formProposalName">
          <Card.Img
            className={
              isUpload ? "upload-file-image-active" : "upload-file-image"
            }
            src={UploadFile}
          />
          {isLoading && (
            <div className="loading">
              <Spinner className="spinner" animation="border" />
              <span className="loading-text">LOADING...</span>
            </div>
          )}
          <div className="row align-items-end pb-3">
            <Col className="col-6">
              <Form.Group controlId="formFieldProposalName">
                <Form.Label>Technical proposal name :</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={form.proposalName}
                  onChange={(e) => setField("proposalName", e.target.value)}
                  isInvalid={!!errors.proposalName}
                  disabled={!isChecked}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.proposalName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="button-import-wrap">
              <div>
                <Form.Control
                  ref={fileRef}
                  onChange={handleFileUpload}
                  type="file"
                  accept=".xlsx"
                  style={{ display: "none" }}
                />
              </div>
              {accountType === "ADMINISTRATOR" ||
              accountType === "TECHNICAL_ADMINISTRATOR" ? (
                <Button
                  className="import-project-file-btn"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={!isChecked}
                >
                  Import many defects
                </Button>
              ) : null}
            </Col>
          </div>
        </Form>
      </PanelBody>
      {config.isShow && <Alert config={config} setConfig={setConfig} />}
    </Row>
  );
};

export default ProposalCreate;
