import { useEffect } from "react";
import { InputGroup, FormControl, Button, Form, Modal, Spinner } from "react-bootstrap";

import {
  Radio,
  ArchiveOpenSvg,
  ArchiveSvg,
  BackSvg,
  CopySvg,
  UnzipSvg,
} from "components/library";
import { useAppDispatch } from "app/hook";
import { FetchMaterialFilter } from "features/project/project-create.slice.type";
import { setIsFirstTime } from "features/project/project-create.slice";
import Find from "assets/images/find.svg";

import "./panel-header.scss";

type PanelHeaderProps = {
  title: string;
  theme?: string;
  isChecked?: boolean;
  flagCheck?: string;
  setFlagCheck?: Function;
  className?: string;
  isTableHeader?: boolean;
  isProposal?: boolean;
  isDefectDimensionsMAWP?: boolean;
  isDimensionsCheck?: string;
  setDimensionsCheck?: (a: string) => void;
  setIsArchive?: (a: boolean) => void;
  isArchive?: boolean;
  setModal?: (a: {
    isArchiveOpen: boolean;
    isCopyOpen: boolean;
    title: string;
  }) => void;
  isSolutionSelection?: boolean;
  setField?: (field: string, value: string) => void;
  solutionSelection?: string;
  materials?: Array<string>;
  errors?: {
    solutionSelection?: string;
  };
  selected?: number;
  searchValue?: string;
  setSearchValue?: (a: string) => void;
  isWarning?: boolean;
  setIsWarning?: (a: boolean) => void;
  exportFile?: () => void;
  isExportLoading?: boolean;
  getFilterMaterial?: (values: FetchMaterialFilter) => void;
};

const PanelHeader = ({
  title,
  theme = "dark",
  isChecked,
  flagCheck,
  setFlagCheck,
  className = "",
  isTableHeader = false,
  isProposal = false,
  isDefectDimensionsMAWP = false,
  isDimensionsCheck = "NO",
  setDimensionsCheck = () => {},
  setIsArchive = () => {},
  isArchive = false,
  setModal = () => {},
  isSolutionSelection = false,
  setField = () => {},
  solutionSelection,
  materials = [],
  errors,
  selected = 0,
  searchValue = "",
  setSearchValue = () => {},
  isWarning = false,
  setIsWarning = () => {},
  exportFile = () => {},
  isExportLoading = false,
  getFilterMaterial = () => {},
}: PanelHeaderProps) => {
  const dispatch = useAppDispatch();
  const getClasses = () => {
    if (theme === "dark") return `panel-header ${className}`;
    if (theme === "light")
      return `panel-header panel-header-light ${className}`;
    if (theme === "white")
      return `panel-header panel-header-white ${className}`;
    return `panel-header panel-header-light2 ${className}`;
  };

  useEffect(() => {
    if (selected > 1 && isArchive) {
      setIsWarning(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className={getClasses()}>
      {isChecked !== undefined && flagCheck && setFlagCheck ? (
        <div className="radio-and-primary">
          <Radio
            label={title}
            checked={isChecked}
            flagCheck={flagCheck}
            setFlagCheck={setFlagCheck}
          />
          {isTableHeader && (
            <InputGroup>
              <img alt="find" src={Find} />
              <FormControl
                disabled={!isChecked}
                type="text"
                aria-label="Small"
                placeholder="Browse"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </InputGroup>
          )}
        </div>
      ) : (
        <div>{title}</div>
      )}
      {isTableHeader ? (
        <div className="buttons-wrapper">
          {selected ?
            <span className="selected-text">
              {selected} {isProposal ? "proposal selected" : "project selected"}
            </span> : null}
          {isProposal ? (
            <Button className="export-btn" disabled={!isChecked} variant="primary" onClick={() => {}}>
              Export tech offers
            </Button>
          ) : (
            <Button
              className="export-btn"
              disabled={!isChecked || selected < 1}
              variant="primary"
              onClick={() => exportFile()}
            >
              Export project file
              {isExportLoading && <Spinner className="export-spinner" animation="border" size="sm" />}
            </Button>
          )}
          <div className="btn-header-group">
            {!isArchive ? (
              <>
                {!isProposal && (
                  <button className="hover-btn archive-svg" disabled={!isChecked || !selected} onClick={() => setModal({
                    isArchiveOpen: true,
                    isCopyOpen: false,
                    title: "Archive",
                  })}>
                    <ArchiveSvg isHover />
                    <ArchiveSvg />
                  </button>
                )}
                <button className="hover-btn copy-svg" disabled={!isChecked || !selected} onClick={() => setModal({
                  isArchiveOpen: false,
                  isCopyOpen: true,
                  title: "Copy",
                })}>
                  <CopySvg isHover />
                  <CopySvg />
                </button>
              </>
            ) : (
              <button className="hover-btn unzip-svg" disabled={!isChecked || !selected} onClick={() => setModal({
                isArchiveOpen: true,
                isCopyOpen: false,
                title: "Unzip",
              })}>
                <UnzipSvg isHover />
                <UnzipSvg />
              </button>
            )}
          </div>
          {!isArchive ? (
            !isProposal && (
              <button className="hover-btn open-svg" disabled={!isChecked} onClick={() => setIsArchive(true)}>
                <ArchiveOpenSvg isHover />
                <ArchiveOpenSvg />
              </button>
            )
          ) : (
            <button className="hover-btn back-svg" disabled={!isChecked} onClick={() => setIsArchive(false)}>
              <BackSvg isHover />
              <BackSvg />
            </button>
          )}
        </div>
      ) : null}
      {isDefectDimensionsMAWP ? (
        <Form.Group>
          <div className="custom-value-ckeck">
            <Form.Check
              label="Yes"
              checked={isDimensionsCheck === "YES"}
              onChange={() => setDimensionsCheck("YES")}
            />
            <Form.Check
              label="No"
              checked={isDimensionsCheck === "NO"}
              onChange={() => setDimensionsCheck("NO")}
            />
          </div>
        </Form.Group>
      ) : null}
      {isSolutionSelection ? (
        <Form.Group controlId="formFieldSolutionSelection" className="formFieldSolutionSelection">
          <Form.Select
            value={solutionSelection || ""}
            onChange={(e) => {
              dispatch(setIsFirstTime(true));
              setField("solutionSelection", e.target.value);
              getFilterMaterial({
                selection: e.target.value,
                type: null,
                width: null,
              });
            }}
            isInvalid={!!errors?.solutionSelection}
          >
            {materials.map((item) => {
              return (
                <option
                  value={item}
                  key={item}
                >
                  {item}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback
            type="invalid"
            style={{
              display: !!errors?.solutionSelection ? "block" : "none",
            }}
          >
            {errors?.solutionSelection}
          </Form.Control.Feedback>
        </Form.Group>
      ) : null}
      <Modal className="warning-modal" show={isWarning} onHide={() => setIsWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>WARNING!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You {selected < 1 ? "need select 1 " : "can select only 1 "}
          {isProposal ? "tech proposal" : "project"} for this action
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PanelHeader;
