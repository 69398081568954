import { useState } from "react";
import { Container } from "react-bootstrap";

import { RowButtons } from "components/library";
import { ProjectCreate, ProjectExists } from "features/project";

const Home = () => {
  const [flagNext, setFlagNext] = useState(false);
  const [flagCheck, setFlagCheck] = useState("create");

  return (
    <Container>
      <ProjectCreate
        flagNext={flagNext}
        setFlagNext={setFlagNext}
        isChecked={flagCheck === "create"}
        flagCheck="create"
        setFlagCheck={setFlagCheck}
      />
      <ProjectExists
        flagNext={flagNext}
        setFlagNext={setFlagNext}
        isChecked={flagCheck === "exist"}
        flagCheck="exist"
        setFlagCheck={setFlagCheck}
      />
      <RowButtons textNext="Next" onClickNext={setFlagNext} />
    </Container>
  );
};

export default Home;
