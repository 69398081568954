interface IGetFetchProps {
  method?: string;
  token?: string;
  contentType?: string;
  body?: any;
}

interface IFetchPropsObject {
  method: string;
  headers: {
    country: string;
    language: string;
    [key: string]: any;
  };
  [key: string]: any;
}

export const getFetchProp = ({
  method,
  token,
  contentType,
  body,
}: IGetFetchProps) => {
  const fetchProps: IFetchPropsObject = {
    method: "GET",
    headers: {
      country: "UK",
      language: "en",
      accept: "*/*",
    },
  };
  if (method) {
    fetchProps.method = method;
  }
  if (body) {
    fetchProps.body = body;
  }
  if (token) {
    fetchProps.headers.authorization = token;
  }
  if (contentType) {
    fetchProps.headers["Content-Type"] = contentType;
  }
  return fetchProps;
};
