import { apiUrl } from "constants/index";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FileSaver from "file-saver";

import { LoginUserState } from "features/auth/auth-slice";

import { getFetchProp } from "../../helpers/getFetchProp";

import { fetchAllProjects } from "./project-create.slice";
import ProjectCreateState from "./project-create.slice.type";

type ImportFileState = {
  file: {
    isLoading: boolean;
    error: string;
  };
};

const initialState: ImportFileState = {
  file: {
    isLoading: false,
    error: "",
  },
};

export const fetchFile = createAsyncThunk(
  "import file",
  async (file: Blob, { getState }) => {
    const formData = new FormData();
    formData.append("file", file);
    const { loginUser } = getState() as { loginUser: LoginUserState };

    await fetch(`${apiUrl}/rea/v1.0/api/report/lotdamage`, {
      ...getFetchProp({
        method: "POST",
        token: loginUser.token,
        body: formData,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => FileSaver.saveAs(blob, "report.zip"));
  }
);

export const importProjectFile = createAsyncThunk(
  "import file",
  async (file: Blob, { dispatch, getState }) => {
    const formData = new FormData();
    formData.append("file", file);
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const { projectCreate } = getState() as {
      projectCreate: ProjectCreateState;
    };

    const { projectData } = projectCreate.one;

    const res = await fetch(`${apiUrl}/rea/v1.0/api/report/import_project`, {
      ...getFetchProp({
        method: "POST",
        token: loginUser.token,
        body: formData,
      }),
    });

    if (res.ok) {
      const data = await res.json();
      if (Array.isArray(data) && data.length > 0) {
        dispatch(
          fetchAllProjects({
            pageIndex: 0,
            pageSize: projectData.totalElements || projectData.size,
          })
        );
      }
    }
  }
);

const importFileSlice = createSlice({
  name: "import file",
  initialState,
  reducers: {
    resetStateImportFile() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFile.pending, (state) => {
      state.file.isLoading = true;
    });
    builder.addCase(fetchFile.fulfilled, (state) => {
      state.file.isLoading = false;
    });
    builder.addCase(fetchFile.rejected, (state) => {
      state.file.error = "Uploading file error!";
      state.file.isLoading = false;
    });
  },
});

export const { resetStateImportFile } = importFileSlice.actions;

export default importFileSlice.reducer;
