import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";

import { addValueTailorMadeStep7 } from "features/tailor-made/tailor-made.slice";
import { Alert, WarningModal, StopModal } from "components/library";
import { IAlertConfig } from "components/library/alert/alert";

import {
  addValueStep7,
  calculation,
  createProject,
  fetchDetail,
} from "../project-create.slice";

import { ResultsHeader, ResultsOperations, ResultsReport } from "./components";

import "./results.scss";

type ResultsProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
};

interface IResultsErrors {
  solutionSelection?: string;
  tapeType?: string;
  tapeWidth?: string;
  resinSelection?: string;
}

const Results = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
}: ResultsProps) => {
  const seven = useAppSelector((state) => state.projectCreate.seven);
  const six = useAppSelector((state) => state.projectCreate.six);
  const three = useAppSelector((state) => state.projectCreate.three);
  const loading = useAppSelector((state) => state.projectCreate.loading);
  const { option, numberLayers, repairLength } = useAppSelector(
    (state) => state.tailorMade.main
  );

  const [form, setForm] = useState({ ...seven, tapeReference: six.tapeType });
  const [errors, setErrors] = useState<IResultsErrors>({});
  const [flagSetTapeReference, setFlagTapeReference] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);
  const [config, setConfig] = useState<IAlertConfig>({
    isShow: false,
    variant: "danger",
    error: "",
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setField = (field: string, value: string | boolean) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field as keyof IResultsErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  if (six.tapeType !== form.tapeReference && !flagSetTapeReference) {
    setField("tapeReference", six.tapeType);
    setFlagTapeReference(true);
  }

  const validateForm = () => {
    // const { tapeReference } = form;
    const newErrors: IResultsErrors = {};

    // if (!tapeReference || tapeReference === "")
    //   newErrors.tapeReference = "Please select solution selection";

    return newErrors;
  };

  useEffect(() => {
    seven.error
      ? setConfig({
          ...config,
          isShow: true,
          error: seven.error,
        })
      : setConfig({
          ...config,
          isShow: false,
          error: "",
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seven.error]);

  useEffect(() => {
    if (flagNext) {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setFlagNext(false);
      } else {
        setFlagNext(false);
        dispatch(addValueStep7(form));
        if (seven.result !== null && seven.result.id > 0) {
          let calcLover = seven.result.result[form.tapeReference].lover_iso;
          if (form.reportStandart === "ASME") {
            calcLover = seven.result.result[form.tapeReference].lover_asme;
          }

          dispatch(
            addValueTailorMadeStep7({
              lover: calcLover,
            })
          );
        }
        navigate("/8");
      }
    }

    if (flagPrev) {
      setFlagPrev(false);
      if (form.tapeReference) {
        dispatch(addValueStep7(form));
        if (seven.result !== null && seven.result.id > 0) {
          let calcLover = seven.result.result[form.tapeReference].lover_iso;
          if (form.reportStandart === "ASME") {
            calcLover = seven.result.result[form.tapeReference].lover_asme;
          }

          dispatch(
            addValueTailorMadeStep7({
              lover: calcLover,
            })
          );
        }
      }
      navigate("/6");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev]);

  const calculate = async () => {
    const postAction = await dispatch(createProject());
    const projectId = postAction.payload;

    if (projectId > 0) {
      dispatch(
        calculation({
          tailorOption: option,
          tailorRepairLength: repairLength,
          tailorNumberLayers: numberLayers,
        })
      );
    }
  };

  const detailedEquilations = () => {
    dispatch(
      fetchDetail({
        tapeReference: form.tapeReference,
        reportStandart: form.reportStandart,
        reportType: form.reportType,
      })
    );
  };

  const getCheckBtn = (value: string) => {
    if (
      seven.result !== null &&
      seven.result.result !== undefined &&
      seven.result.result[`${value}`] !== undefined
    )
      return true;

    return false;
  };

  const getResultView = () => {
    if (
      seven.result !== null &&
      seven.result.result !== undefined &&
      seven.result.result[`${form.tapeReference}`] !== undefined
    ) {
      if (form.typeReport === "full") {
        return seven.result.result[`${form.tapeReference}`].result_view;
      }

      return seven.result.result[`${form.tapeReference}`].result_view.filter(
        (item) => item.view_priority >= 100
      );
    }

    return null;
  };

  return (
    <Row className="gx-0">
      {config.isShow && <Alert config={config} setConfig={setConfig} />}
      <WarningModal
        showWarningModal={showWarningModal}
        setShowWarningModal={setShowWarningModal}
      >
        An error in the calculation, it is impossible with these initial data
      </WarningModal>
      <StopModal
        showStopModal={showStopModal}
        setShowStopModal={setShowStopModal}
        isCalculation
      />
      <div className="form-results">
        <Col className="col-9">
          <ResultsHeader form={form} setField={setField} />
          <ResultsReport resultView={getResultView()} />
        </Col>
        <Col className="results-operation-column">
          <ResultsOperations
            form={form}
            loading={loading}
            setField={setField}
            typePipe={three.typePipe}
            calculate={calculate}
            detailedEquilations={detailedEquilations}
            is5050={getCheckBtn("5050")}
            is6040={getCheckBtn("6040")}
            is8020={getCheckBtn("8020")}
          />
        </Col>
      </div>
    </Row>
  );
};

export default Results;
