type ISvgProps = {
  isHover?: boolean;
};

const ArchiveSvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="20" height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.125 2.38086H1.875C1.5 2.38086 1.25 2.63086 1.25 3.00586V6.56836C1.25 6.94336 1.5 
          7.19336 1.875 7.19336H2.4375V18.0684C2.4375 18.4434 2.6875 18.6934 3.0625 18.6934H16.9375C17.3125 
          18.6934 17.5625 18.4434 17.5625 18.0684V7.19336H18.125C18.5 7.19336 18.75 6.94336 18.75 6.56836V3.00586C18.75
          2.69336 18.5 2.38086 18.125 2.38086ZM16.3125 17.5059H3.6875V7.19336H16.3125V17.5059ZM17.5
          5.94336H2.5V3.63086H17.5V5.94336Z" fill="#a80d10"
        />
        <path d="M7.5 11.1934H12.5C12.875 11.1934 13.125 10.9434 13.125 10.5684C13.125 10.1934 12.875 
          9.94336 12.5 9.94336H7.5C7.125 9.94336 6.875 10.1934 6.875 10.5684C6.875 10.9434 7.125
          11.1934 7.5 11.1934Z" fill="#a80d10"
        />
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="20" height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.125 2.38086H1.875C1.5 2.38086 1.25 2.63086 1.25 3.00586V6.56836C1.25 6.94336 1.5 
        7.19336 1.875 7.19336H2.4375V18.0684C2.4375 18.4434 2.6875 18.6934 3.0625 18.6934H16.9375C17.3125 
        18.6934 17.5625 18.4434 17.5625 18.0684V7.19336H18.125C18.5 7.19336 18.75 6.94336 18.75 6.56836V3.00586C18.75
        2.69336 18.5 2.38086 18.125 2.38086ZM16.3125 17.5059H3.6875V7.19336H16.3125V17.5059ZM17.5
        5.94336H2.5V3.63086H17.5V5.94336Z" fill="black"
      />
      <path d="M7.5 11.1934H12.5C12.875 11.1934 13.125 10.9434 13.125 10.5684C13.125 10.1934 12.875 
        9.94336 12.5 9.94336H7.5C7.125 9.94336 6.875 10.1934 6.875 10.5684C6.875 10.9434 7.125
        11.1934 7.5 11.1934Z" fill="black"
      />
    </svg>
  );
};

export default ArchiveSvg;
