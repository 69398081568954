import { memo, useState } from "react";
import { PanelHeader } from "components/library";

import "./reports-info.scss";

type ReportsInfoProps = {
  conditions: String[];
  setField: Function;
};

const ReportsInfo = ({ conditions, setField }: ReportsInfoProps) => {
  const [keyId, setKeyId] = useState(1);

  return (
    <>
      <PanelHeader className="min-height" theme="white" title="reports" />
      <div className="reports-info">
        <section
          id="textarea"
          contentEditable="true"
          suppressContentEditableWarning={true}
          onBlur={(e) => {
            const textarea = e.target as HTMLElement;
            setKeyId(keyId + 1);
            setField(textarea.innerText.split("\n"));
          }}
          // onInput={(e) => {
          //   const textarea = e.target as HTMLElement;
          //   console.log("textarea=", textarea);
          //   // setKeyId(keyId + 1);
          //   // setField(textarea.innerText.split("\n"));
          // }}
        >
          <ul key={keyId}>
            {conditions.length > 0 &&
              conditions.map((item, index) => {
                return (
                  <li key={`${index} - ${new Date().toUTCString()}`}>{item}</li>
                );
              })}
          </ul>
        </section>
      </div>
    </>
  );
};

export default memo(ReportsInfo);
