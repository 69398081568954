import { Row } from "react-bootstrap";

import { Button } from "components/library";

import "./row-buttons.scss";

type RowButtonsProps = {
  textPrev?: string;
  onClickPrev?: Function;
  textNext?: string;
  onClickNext?: Function;
};

const RowButtons = ({
  textPrev,
  onClickPrev,
  textNext,
  onClickNext,
}: RowButtonsProps) => {
  return (
    <Row className="gx-0 pt-3 justify-content-end">
      {textPrev && onClickPrev && (
        <Button
          className="btn-prev me-4"
          text={textPrev}
          onClick={() => onClickPrev(true)}
        />
      )}
      {textNext && onClickNext && (
        <Button
          className="btn-next"
          text={textNext}
          onClick={() => onClickNext(true)}
        />
      )}
    </Row>
  );
};

export default RowButtons;
