import { Form } from "react-bootstrap";

import { TailorMadeResults as TTailorMadeResults } from "features/tailor-made/tailor-made.slice.type";

import "./tailor-made-results.scss";

type TailorMadeResultsProps = {
  form: TTailorMadeResults;
};

const TailorMadeResults = ({ form }: TailorMadeResultsProps) => {
  return (
    <div className="tailor-made-results">
      <Form className="form-tailor-made-results">
        <Form.Group
          controlId="formFieldTotalLength"
          className="formFieldTotalLength"
        >
          <Form.Label>Total length of repair</Form.Label>
          <Form.Control type="text" value={form.totalLength || ""} readOnly />
          <Form.Text>m</Form.Text>
        </Form.Group>
        <Form.Group
          controlId="formFieldQuantityTapes"
          className="formFieldQuantityTapes"
        >
          <Form.Label>Quantity of tapes</Form.Label>
          <Form.Control type="text" value={form.quantityTapes || ""} readOnly />
        </Form.Group>
        <Form.Group
          controlId="formFieldQuantityBuckets"
          className="formFieldQuantityBuckets"
        >
          <Form.Label>Quantity of resin buckets (part A and part B)</Form.Label>
          <Form.Control
            type="text"
            value={form.quantityBuckets || ""}
            readOnly
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default TailorMadeResults;
