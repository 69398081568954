import { ChangeEvent, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ModalWindow } from "components/library";
import { AddGradeValues } from "features/project/pipe-info/pipe-info";

import EditSvg from "../../svg/edit";

type IModalProps = {
  onClose: (values: AddGradeValues) => void;
  showGradeModal: boolean;
  setShowGradeModal: (a: boolean) => void;
};

type AddGradeFormErrors = {
  name?: string;
  smys?: string;
  mby?: string;
}

const AddGradeModal = ({ onClose, showGradeModal, setShowGradeModal }: IModalProps) => {
  const [values, setValues] = useState<AddGradeValues>({
    name: "",
    smys: null,
    mby: null,
  });
  const [errors, setErrors] = useState<AddGradeFormErrors>({});

  const validateForm = () => {
    const newErrors: AddGradeFormErrors = {};

    if (!values.name || values.name === "")
      newErrors.name = "Please enter grade name";
    if (!values.smys || values.smys === 0)
      newErrors.smys = "Please enter specific minimum yield stress";
    if (!values.mby || values.mby === 0)
      newErrors.mby = "Please enter MBY";

    return newErrors;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      onClose(values);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.id]: e.target.value,
    });

    if (!!errors[e.target.id as keyof AddGradeFormErrors]) {
      setErrors({
        ...errors,
        [e.target.id]: null,
      });
    };
  };

  return (
    <ModalWindow
      isOpen={showGradeModal}
      title="Grade"
      handleClose={() => setShowGradeModal(false)}
      className="add-grade-modal"
    >
      <table>
        <thead>
          <tr className="add-grade-modal_header">
            <th>Field</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><Form.Label>Name</Form.Label></td>
            <td>
              <Form.Control
                id="name"
                className={!!errors.name ? "error" : ""}
                value={values.name}
                onChange={handleChange}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: !!errors.name ? "block" : "none",
                }}
              >
                {errors.name}
              </Form.Control.Feedback>
            </td>
            <td>
              <button className="hover-btn" onClick={() => {
                document.getElementById("name")?.focus();
              }}>
                <EditSvg isHover />
                <EditSvg />
              </button>
            </td>
          </tr>
          <tr>
            <td><Form.Label>Specific Minimum Yield Stress (psi)</Form.Label></td>
            <td>
              <Form.Control
                id="smys"
                className={!!errors.smys ? "error" : ""}
                type="number"
                value={values.smys || ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: !!errors.smys ? "block" : "none",
                }}
              >
                {errors.smys}
              </Form.Control.Feedback>
            </td>
            <td>
              <button className="hover-btn" onClick={() => {
                document.getElementById("smys")?.focus();
              }}>
                <EditSvg isHover />
                <EditSvg />
              </button>
            </td>
          </tr>
          <tr>
            <td><Form.Label>MBY (psi)</Form.Label></td>
            <td>
              <Form.Control
                id="mby"
                className={!!errors.mby ? "error" : ""}
                type="number"
                value={values.mby  || ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: !!errors.mby ? "block" : "none",
                }}
              >
                {errors.mby}
              </Form.Control.Feedback>
            </td>
            <td>
              <button className="hover-btn" onClick={() => {
                document.getElementById("mby")?.focus();
              }}>
                <EditSvg isHover />
                <EditSvg />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="add-grade-modal_buttons">
        <Button variant="light" onClick={handleSubmit}>
          Add
        </Button>
        <Button variant="light" onClick={() => setShowGradeModal(false)}>
          Close
        </Button>
      </div>
    </ModalWindow>
  );
};

export default AddGradeModal;
