import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

import { useAppDispatch, useAppSelector } from "app/hook";

import { PanelBody, PanelHeader, Radio } from "components/library";

import { fetchMaterials } from "features/tailor-made/tailor-made.slice";

import {
  addValueStep9,
  fetchGeneral,
  fetchResultsToFile,
  fetchСonditionDescription,
  fetchMissingInformationDescription,
} from "../project-create.slice";

import { ReportsInfo, ReportsMissing } from "./components";

import "./reports.scss";

type ReportsProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
};

const Reports = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
}: ReportsProps) => {
  const { accountType, roles } = useAppSelector((state) => state.loginUser);
  const nine = useAppSelector((state) => state.projectCreate.nine);
  const seven = useAppSelector((state) => state.projectCreate.seven);
  const loading = useAppSelector((state) => state.projectCreate.loading);
  const { materials } = useAppSelector((state) => state.tailorMade);

  const [form, setForm] = useState({ ...nine });
  const [selected, setSelected] = useState<{ label: string; value: number }[]>(
    []
  );

  const [repairCompositeIds, setRepairCompositeIds] = useState<number[]>([]);
  const [missingText, setMissingText] = useState<string>(form.missingText);

  const options = materials.map((item) => {
    return { label: item.composite_name, value: item.id };
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setFieldMissingText = (value: string) => {
    setForm({ ...form, missingText: value });
    setMissingText(value);
  };

  const setFieldConditions = useCallback(
    (value: string[]) => {
      setForm({
        ...form,
        conditions: value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setForm]
  );

  useEffect(() => {
    dispatch(fetchСonditionDescription());
    dispatch(fetchMissingInformationDescription());
  }, [dispatch]);

  useEffect(() => {
    if (materials.length === 0) {
      dispatch(fetchMaterials());
    }
  }, [materials, dispatch]);

  useEffect(() => {
    if (flagNext) {
      setFlagNext(false);
      dispatch(
        addValueStep9({
          ...form,
          missingText: missingText !== "" ? missingText : nine.missingText,
          conditions:
            Array.isArray(form.conditions) &&
            form.conditions.length > 0 &&
            form.conditions[0] !== ""
              ? form.conditions
              : nine.conditions,
        })
      );
      navigate("/");
    }

    if (flagPrev) {
      setFlagPrev(false);
      dispatch(
        addValueStep9({
          ...form,
          missingText: missingText !== "" ? missingText : nine.missingText,
          conditions:
            Array.isArray(form.conditions) &&
            form.conditions.length > 0 &&
            form.conditions[0] !== ""
              ? form.conditions
              : nine.conditions,
        })
      );
      navigate("/8");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev]);

  const getGeneralReport = () => {
    dispatch(fetchGeneral(form.reportType));
  };

  const getSaveResultsToFile = () => {
    dispatch(fetchResultsToFile({ repairCompositeIds }));
  };

  return (
    <Row className="gx-0">
      <div className="form-reports">
        <Col>
          <ReportsInfo
            conditions={
              Array.isArray(form.conditions) &&
              ((form.conditions.length === 1 && form.conditions[0] !== "") ||
                form.conditions.length > 1)
                ? form.conditions
                : nine.conditions
            }
            setField={setFieldConditions}
          />
        </Col>
        <Col>
          <ReportsMissing
            missingText={missingText || nine.missingText}
            setField={setFieldMissingText}
          />
          <PanelHeader
            className="min-height"
            title="Calculation according to the standards"
          />
          <PanelBody
            style={
              accountType === "ADMINISTRATOR" ||
              accountType === "TECHNICAL_ADMINISTRATOR" ||
              roles.includes("LOT_DAMAGE")
                ? { alignItems: "center" }
                : { alignItems: "center", display: "flex" }
            }
          >
            <div className="radio-group">
              <Radio
                label="DOCX"
                checked={form.reportType === "DOCX"}
                flagCheck="DOCX"
                setFlagCheck={() => setForm({ ...form, reportType: "DOCX" })}
              />
              <Radio
                label="PDF"
                checked={form.reportType === "PDF"}
                flagCheck="PDF"
                setFlagCheck={() => setForm({ ...form, reportType: "PDF" })}
              />
            </div>
            <div
              className="button-group"
              style={
                accountType === "ADMINISTRATOR" ||
                accountType === "TECHNICAL_ADMINISTRATOR" ||
                roles.includes("LOT_DAMAGE")
                  ? {}
                  : { justifyContent: "flex-end", marginTop: "20px", flex: 1 }
              }
            >
              <Button
                className="btn-prev"
                onClick={() => {
                  dispatch(
                    addValueStep9({
                      ...form,
                      missingText:
                        missingText !== "" ? missingText : nine.missingText,
                      conditions:
                        Array.isArray(form.conditions) &&
                        form.conditions.length > 0 &&
                        form.conditions[0] !== ""
                          ? form.conditions
                          : nine.conditions,
                    })
                  );
                  getGeneralReport();
                }}
                disabled={seven.result === null}
              >
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="light"
                      size="sm"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>{" "}
                    Generate report
                  </>
                ) : (
                  <>Generate report</>
                )}
              </Button>
              {(accountType === "ADMINISTRATOR" ||
                accountType === "TECHNICAL_ADMINISTRATOR" ||
                roles.includes("LOT_DAMAGE")) && (
                <>
                  <Button
                    className="btn-prev"
                    onClick={() => getSaveResultsToFile()}
                    disabled={
                      seven.result === null || repairCompositeIds.length === 0
                    }
                  >
                    {loading ? (
                      <>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="light"
                          size="sm"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>{" "}
                        Save results to file
                      </>
                    ) : (
                      <>Save results to file</>
                    )}
                  </Button>
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={(items: { label: string; value: number }[]) => {
                      setSelected(items);
                      setRepairCompositeIds(
                        items.map((item) => {
                          return item.value;
                        })
                      );
                    }}
                    labelledBy="Select products"
                    overrideStrings={{
                      allItemsAreSelected: "All items are selected.",
                      clearSearch: "Clear Search",
                      clearSelected: "Clear Selected",
                      noOptions: "No options",
                      search: "Search",
                      selectAll: "Select All",
                      selectAllFiltered: "Select All (Filtered)",
                      selectSomeItems: "Select products...",
                      create: "Create",
                    }}
                  />
                </>
              )}
            </div>
          </PanelBody>
        </Col>
      </div>
    </Row>
  );
};

export default Reports;
