import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Column } from "react-table";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TableSetup, PanelHeader } from "components/library";
import {
  completeTailorMade,
  fetchMaterials,
} from "features/tailor-made/tailor-made.slice";

import { IdsProjectState } from "../project-exists";
import { ProposalTableData } from "../project-create.slice.type";
import {
  archiveProposalTable,
  unzipProposalTable,
  fetchProposalsById,
  fetchTechProposalById,
  completeProjectState,
  copyProposalTableItems,
  copyProposal,
} from "../project-create.slice";

type ProposalExistsProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
  isChecked: boolean;
  flagCheck: string;
  setFlagCheck: Function;
};

const columns: Column<ProposalTableData>[] = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Tech Proposal Name",
    accessor: "tech_proposal_name",
  },
  {
    Header: "Technical Offer",
    accessor: "offer_number",
  },
  // {
  //   Header: "Reparation Number",
  //   accessor: "repair_reference",
  // },
];

const ProposalExists = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
  isChecked,
  flagCheck,
  setFlagCheck,
}: ProposalExistsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    archiveTableData,
    projectData,
    selectedId,
    techProposal: { data },
    isLoadedProposal,
    isChanged,
    isIdsLoaded,
    newIds,
  } = useAppSelector((state) => state.projectCreate.two);
  const { materials } = useAppSelector((state) => state.tailorMade);
  const [filteredData, setFilteredData] = useState(
    projectData ? projectData : []
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [isArchive, setIsArchive] = useState<boolean>(false);
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [proposalId, setProposalId] = useState<number | null>(null);
  const [selectedIds, setSelectedIds] = useState<object | null>(null);
  const [modal, setModal] = useState({
    isArchiveOpen: false,
    isCopyOpen: false,
    title: "",
  });
  const [idsWithProjects, setIdsWithProjects] = useState<IdsProjectState>({
    ids: [],
    projects: [],
  });

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchProposalsById(selectedId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (materials.length === 0) {
      dispatch(fetchMaterials());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !isArchive
      ? setFilteredData(projectData)
      : setFilteredData(archiveTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchive]);

  useEffect(() => {
    const ids = [];
    const projects = [];
    for (let key in selectedIds) {
      ids.push(+key);
      projects.push(
        !isArchive
          ? projectData[+key].tech_proposal_name
          : archiveTableData[+key].tech_proposal_name
      );
    }
    setProposalId(projectData[ids[0]]?.id);
    setIdsWithProjects({
      ids: ids,
      projects: projects,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  useEffect(() => {
    if (projectData) {
      const searchData = !isArchive
        ? projectData.filter(
            (item) =>
              item.tech_proposal_name.includes(searchValue) ||
              item.repair_reference.includes(searchValue)
          )
        : archiveTableData.filter(
            (item) =>
              item.tech_proposal_name.includes(searchValue) ||
              item.repair_reference.includes(searchValue)
          );
      setFilteredData(searchData);
    } else {
      setFilteredData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, projectData]);

  useEffect(() => {
    if (isIdsLoaded) {
      dispatch(
        copyProposalTableItems({
          selectedIds: idsWithProjects.ids,
          newIds: newIds,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdsLoaded]);

  const onArchive = () => {
    dispatch(archiveProposalTable(idsWithProjects.ids));
    setModal({
      ...modal,
      isArchiveOpen: false,
    });
  };

  const onUnzip = () => {
    dispatch(unzipProposalTable(idsWithProjects.ids));
    setModal({
      ...modal,
      isArchiveOpen: false,
    });
  };

  const onCopy = () => {
    dispatch(copyProposal(idsWithProjects.ids));
    setModal({
      ...modal,
      isCopyOpen: false,
    });
  };

  useEffect(() => {
    if (flagNext && isChecked) {
      if (idsWithProjects.ids.length !== 1) {
        setIsWarning(true);
      } else {
        dispatch(fetchTechProposalById(proposalId));
      }
    }
    if (isLoadedProposal && !!materials.length) {
      dispatch(completeProjectState(materials));
      dispatch(
        completeTailorMade({
          numberLayers: data.tailor_made_number_of_layer,
          option: data.tailor_made_option,
          reasonRepair: data.tailor_made_reason,
          repairLength: data.tailor_made_total_length_repair,
        })
      );
    }
    if (isChanged) {
      navigate("/3");
    }

    if (flagPrev && isChecked) {
      navigate("/");
      setFlagPrev(false);
    }

    setFlagNext(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev, isChecked, isLoadedProposal, isChanged]);

  return (
    <Row
      onClick={() => setFlagCheck("exist")}
      className={!isChecked ? "gx-0 mt-4 opacity-50" : "gx-0 mt-4"}
    >
      <PanelHeader
        title="Select technical proposal"
        theme="light"
        isChecked={isChecked}
        flagCheck={flagCheck}
        setFlagCheck={setFlagCheck}
        isTableHeader
        isProposal
        isArchive={isArchive}
        setIsArchive={setIsArchive}
        setModal={setModal}
        isWarning={isWarning}
        setIsWarning={setIsWarning}
        selected={idsWithProjects.ids.length}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
      {!isArchive ? (
        <TableSetup
          columns={columns}
          data={filteredData}
          id="technicalOfferStatus"
          headerTitle="Technical Offer Status"
          setSelectedIds={setSelectedIds}
          modal={modal}
          setModal={setModal}
          projects={idsWithProjects.projects}
          onArchive={onArchive}
          onCopy={onCopy}
          isProposal
        />
      ) : (
        <TableSetup
          columns={columns}
          data={filteredData}
          id="technicalOfferStatus"
          headerTitle="Technical Offer Status"
          setSelectedIds={setSelectedIds}
          modal={modal}
          setModal={setModal}
          projects={idsWithProjects.projects}
          onUnzip={onUnzip}
          isArchive
          isProposal
        />
      )}
    </Row>
  );
};

export default ProposalExists;
