import { Button, Modal } from "react-bootstrap";

import Stop from "assets/images/stop.svg";

type IModalProps = {
  showStopModal: boolean;
  setShowStopModal: (a: boolean) => void;
  isCalculation?: boolean;
};

const StopModal = ({ showStopModal, setShowStopModal, isCalculation = false }: IModalProps) => {
  return (
    <Modal className="stop-modal" show={showStopModal} onHide={() => setShowStopModal(false)}>
      <Modal.Header closeButton>
        <div className="image-wrapp">
          <img src={Stop} alt="stop" />
        </div>
        <div className="title-wrapp">
          <Modal.Title>Stop!</Modal.Title>
          <span>absolutly block</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {isCalculation ? 
          "An error in the calculation, there is no decision with these initial data" :
          "Please contact 3X for this calculation"
        }
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-stop-btn" variant="danger" onClick={() => setShowStopModal(false)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StopModal;
