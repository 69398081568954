import { menuRoutes } from "constants/";

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";

import "./main-menu.scss";

const MainMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("/");

  useEffect(() => {
    menuRoutes.includes(location.pathname)
      ? setActiveKey(location.pathname)
      : setActiveKey("/");
  }, [location]);

  return (
    <Nav
      activeKey={activeKey}
      className="main-menu"
      onSelect={(selectedKey) => {
        setActiveKey(selectedKey || "/");
        navigate(selectedKey || "/");
      }}
    >
      <Nav.Item>
        <Nav.Link eventKey="/">
          <div className="menu-list">
            <div
              className={
                activeKey === "/"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <div className={
                activeKey === "/"
                  ? "rectangle-active"
                  : "rectangle"
              }
              ></div>
              <span>Project</span>
            </div>
            <div
              className={
                activeKey === "/" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/2">
          <div className="menu-list">
            <div
              className={
                activeKey === "/2"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>
                Technical
                <br />
                proposal
              </span>
            </div>
            <div
              className={
                activeKey === "/2" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/3">
          <div className="menu-list">
            <div
              className={
                activeKey === "/3"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>
                Pipe
                <br />
                Information
              </span>
            </div>
            <div
              className={
                activeKey === "/3" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/4">
          <div className="menu-list">
            <div
              className={
                activeKey === "/4"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>
                Temperature
                <br />
                and Loads
              </span>
            </div>
            <div
              className={
                activeKey === "/4" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/5">
          <div className="menu-list">
            <div
              className={
                activeKey === "/5"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>
                Defect
                <br />
                Dimensions
              </span>
            </div>
            <div
              className={
                activeKey === "/5" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/6">
          <div className="menu-list">
            <div
              className={
                activeKey === "/6"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>
                Select
                <br />
                Products
              </span>
            </div>
            <div
              className={
                activeKey === "/6" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/7">
          <div className="menu-list">
            <div
              className={
                activeKey === "/7"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>Results</span>
            </div>
            <div
              className={
                activeKey === "/7" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/8">
          <div className="menu-list">
            <div
              className={
                activeKey === "/8"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>
                Tailor made
                <br />
                (Optional)
              </span>
            </div>
            <div
              className={
                activeKey === "/8" ? "arrow-right_active" : "arrow-right"
              }
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/9">
          <div className="menu-list">
            <div
              className={
                activeKey === "/9"
                  ? "main-menu_item main-menu_item-active"
                  : "main-menu_item"
              }
            >
              <span>
                Conditions
                <br />
                and Reports
              </span>
            </div>
          </div>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default MainMenu;
