import { apiUrl } from "constants/";

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  AnyAction,
} from "@reduxjs/toolkit";

import { getFetchProp } from "helpers/getFetchProp";
import { calculation } from "features/project/project-create.slice";
import { LoginUserState } from "features/auth/auth-slice";

import TailorMadeState, {
  TailorMadeStateStep3,
  TailorMadeStateStep6,
  TailorMadeStateStep7,
  TailorMadeCompleteState,
} from "./tailor-made.slice.type";

export const calculateTailorMade = createAsyncThunk(
  "calculation/tailor_made",
  async (form: TailorMadeState, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/calculation/tailor_made?lover=${form.header.lover}`,
      {
        ...getFetchProp({
          method: "PUT",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify({
            chosen_length_repair: form.main.repairLength,
            defect_axial_length: form.header.defectAxialLength,
            number_imposed_layers: form.main.numberLayers,
            original_diameter_mm: form.header.originalDiameterMm,
            reason_tailor_made_repair: form.footer.reasonRepair,
            repair_composite_id: form.header.repairCompositeId,
            tailor_made_option: form.main.option,
          }),
        }),
      }
    );

    if (res.ok) {
      dispatch(
        calculation({
          tailorOption: form.main.option,
          tailorRepairLength: form.main.repairLength,
          tailorNumberLayers: form.main.numberLayers,
        })
      );
    }

    return res.json();
  }
);

export const fetchMaterials = createAsyncThunk(
  "material",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/material`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });
    return res.json();
  }
);

export const fetchWarningText = createAsyncThunk(
  "tailor_made_warning",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/description/tailor_made_warning`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );

    return res.text();
  }
);

const initialState: TailorMadeState = {
  header: {
    originalDiameterMm: 0,
    originalDiameterInch: 0,
    defectAxialLength: 0,
    repairCompositeId: 1,
    tapeWidth: 100,
    lover: 0,
  },
  main: {
    option: "LTAPER_INCLUDED",
    repairLength: 0,
    numberLayers: 0,
    ltaper: 0,
  },
  footer: {
    reasonRepair: "",
  },
  results: {
    ltaper: 0,
    totalLength: "",
    quantityTapes: "",
    quantityBuckets: "",
  },
  materials: [],
  warningText: "",
  loading: false,
  error: null,
};

const tailorMadeSlice = createSlice({
  name: "tailorMade",
  initialState,
  reducers: {
    addValueTailorMade(state, action: PayloadAction<TailorMadeState>) {
      state.header = {
        ...state.header,
        ...action.payload.header,
      };
      state.main = {
        ...state.main,
        ...action.payload.main,
      };
      state.footer = {
        ...state.footer,
        ...action.payload.footer,
      };
      state.results = {
        ...state.results,
        ...action.payload.results,
      };
    },
    addValueTailorMadeStep3(
      state,
      action: PayloadAction<TailorMadeStateStep3>
    ) {
      state.header = {
        ...state.header,
        originalDiameterMm: action.payload.originalDiameterMm,
        originalDiameterInch: action.payload.originalDiameterInch,
      };
    },
    addValueTailorMadeStep5(
      state,
      action: PayloadAction<{ defectAxialLengthMm: number }>
    ) {
      state.header = {
        ...state.header,
        defectAxialLength: action.payload.defectAxialLengthMm,
      };
    },
    addValueTailorMadeStep6(
      state,
      action: PayloadAction<TailorMadeStateStep6>
    ) {
      state.header = {
        ...state.header,
        repairCompositeId: action.payload.repairCompositeId,
        tapeWidth: action.payload.tapeWidth,
      };
    },
    addValueTailorMadeStep7(
      state,
      action: PayloadAction<TailorMadeStateStep7>
    ) {
      state.header = {
        ...state.header,
        lover: action.payload.lover,
      };
    },
    completeTailorMade(state, action: PayloadAction<TailorMadeCompleteState>) {
      state.main.numberLayers = action.payload.numberLayers;
      state.main.repairLength = action.payload.repairLength;
      state.main.option = action.payload.option;
      state.footer.reasonRepair = action.payload.reasonRepair;
    },
    cleanValueTailorMade: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateTailorMade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(calculateTailorMade.fulfilled, (state, action) => {
        state.results = {
          ...state.results,
          ltaper: action.payload.ltaper,
          totalLength: action.payload.total_length_tape,
          quantityTapes: action.payload.quantity_tape,
          quantityBuckets: action.payload.quantity_resin_bucket,
        };
        state.loading = false;
      })
      .addCase(fetchMaterials.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMaterials.fulfilled, (state, action) => {
        state.materials = [...action.payload];
        state.loading = false;
      })
      .addCase(fetchWarningText.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWarningText.fulfilled, (state, action) => {
        state.warningText = action.payload;
        state.loading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const {
  addValueTailorMade,
  addValueTailorMadeStep3,
  addValueTailorMadeStep5,
  addValueTailorMadeStep6,
  addValueTailorMadeStep7,
  completeTailorMade,
  cleanValueTailorMade,
} = tailorMadeSlice.actions;

export default tailorMadeSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith("rejected");
}
