import styled from "styled-components";

export const TableStyles = styled.div`
  table {
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.4rem;
      border-bottom: 1px solid #f4f4f4;
      width: 200px;

      &:first-child {
        width: 70px;
      }

      :last-child {
        width: 70px;
        border-right: 0;
      }
    }
  }

  .proposal-table {
    th,
    td {
      :nth-child(7) {
        display: none;
      }

      &:first-child {
        width: 55px;
      }

      :last-child {
        width: 55px;
        border-right: 0;
      }
    }
  }

  .checkbox {
    -webkit-appearance: auto;
    display: flex;
    justify-content: center;
  }

  .pagination {
    padding: 0.5rem;
    display: flex;
    aligh-items: center;
  }

  .checkbox-wrapper {
    display: flex;
    justify-content: center;
  }

  .line {
    background-color: #f4f4f4;
  }

  .rows-per-page {
    border: none;
    padding: 0 10px;
    outline: 0;
  }

  .pages-counter {
    padding: 0 40px;
  }

  .btn-group button {
    cursor: pointer;
    border: none;
    width: 9px;
    height: 9px;
    background-color: #fff;
    border-top: 1px solid #94a1a2;
    border-right: 1px solid #94a1a2;
    margin: 0 25px;
  }

  .left {
    transform: rotate(-135deg);
  }

  .right {
    transform: rotate(45deg);
  }

  .btn-group {
    display: flex;
    align-items: center;
  }

  .sorting-arrow span {
    font-size: 20px;
    padding-left: 6px;
  }

  .edit-button {
    width: 16px;
    height: 16px;
    border: 0;
    outline: 0;
    background-color: #f4f4f4;
  }

  .edit-button svg {
    vertical-align: top;
  }

  .save-button svg {
    vertical-align: top;
  }

  .save-button {
    width: 17px;
    height: 17px;
    border: 0;
    outline: 0;
    background-color: #f4f4f4;
  }

  .edit-input {
    border: none;
    background-color: #f4f4f4;
  }

  .focus {
    border: 1px solid #a80d10;
    background-color: #fff;
    outline: 0;
    padding-left: 5px;
  }

  .edit-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-form-select {
    border-radius: 30px;
    padding: 0 0 0 15px;
    cursor: pointer;
  }

  .orange {
    background-color: rgba(243, 165, 12, 0.15);
    color: #f3a50c;
    border: 1px solid #f3a50f;
  }

  .light-blue {
    background-color: #cce6ed;
    color: #0c9cc9;
    border: 1px solid #0c9cc9;
  }

  .blue {
    color: #295cca;
    background-color: #e7ecf7;
    border: 1px solid #295cca;
  }

  .green {
    color: #1da484;
    background-color: #c0eee3;
    border: 1px solid #1da484;
  }

  .purple {
    color: #b843ba;
    background-color: #f7ddf8;
    border: 1px solid #b843ba;
  }

  .red {
    color: #f65761;
    background-color: #f9eff0;
    border: 1px solid #f65761;
  }

  .reason-form-select {
    background-color: #e3e9ea;
    border: 1px solid #6a7676;
    color: #6a7676;
    border-radius: 30px;
    padding: 0 35px 0 15px;
    cursor: pointer;
    margin-left: 10px;
  }

  .project-status {
    width: 280px;
    height: 35px;
    display: flex;
  }

  .span-select {
    border-radius: 30px;
    padding: 5px 17px;
  }

  .span-rejected {
    background: rgba(246, 87, 97, 0.15);
    border: 1px solid #f65761;
    color: #f65761;
    border-radius: 30px;
    padding: 5px 17px;
  }

  .select-wrapper {
    display: flex;
  }
`;
