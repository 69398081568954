import { useState } from "react";
import { Form } from "react-bootstrap";

import "./impact.scss";

type ImpactProps = {
  impact: number;
  setField: Function;
};

const Impact = ({ impact, setField }: ImpactProps) => {
  const [yes, setYes] = useState(false);
  const [no, setNo] = useState(true);

  return (
    <div className="impact mt-2">
      <div className="impact_header">
        <Form.Label>Impact</Form.Label>
        <div className="form-check_wrap">
          <Form.Check
            label="Yes"
            checked={yes}
            onChange={(e) => {
              if (e.target.checked) {
                setYes(true);
                setNo(false);
              }
            }}
          />
          <Form.Check
            label="No"
            checked={no}
            onChange={(e) => {
              if (e.target.checked) {
                setYes(false);
                setNo(true);
                setField("impact", 5);
              }
            }}
          />
        </div>
      </div>
      <div className="impact_footer">
        <Form.Group controlId="formFieldImpact" className="formFieldImpact">
          <div className="formFieldImpact_wrap-control">
            <div className="formFieldImpact_wrap-number">
              <Form.Control
                type="number"
                min={0}
                value={impact}
                onChange={(e) => {
                  setField("impact", parseFloat(e.target.value));
                }}
                readOnly={no}
              />
              <Form.Text>mm</Form.Text>
            </div>
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

export default Impact;
