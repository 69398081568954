import { useEffect, useState } from "react";

import { Radio } from "components/library";

import { Results } from "features/project/project-create.slice.type";

import "./results-header.scss";

type ResultsHeaderProps = {
  form: Results;
  setField: Function;
};

const ResultsHeader = ({ form, setField }: ResultsHeaderProps) => {
  const [flagCheck, setFlagCheck] = useState(form.reportStandart);

  useEffect(() => {
    if (flagCheck !== form.reportStandart) {
      setField("reportStandart", flagCheck);
    }
  }, [flagCheck, form.reportStandart, setField]);

  return (
    <div className="results-header">
      <div>Description</div>

      <div className="radio-group">
        <Radio
          label="Iso 24817"
          checked={flagCheck === "ISO"}
          flagCheck="ISO"
          setFlagCheck={setFlagCheck}
        />
        <Radio
          label="Asme pcc-2"
          checked={flagCheck === "ASME"}
          flagCheck="ASME"
          setFlagCheck={setFlagCheck}
        />
      </div>
    </div>
  );
};

export default ResultsHeader;
