type ISvgProps = {
    isHover?: boolean;
  };
  
const SaveSvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="15" height="13"
        viewBox="0 0 20 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M18.6234 0.748129C19.166 1.37989 19.1331 2.36857 18.55
          2.9564L9.24956 12.3314C8.69587 12.8895 7.83825 12.8895 7.28456 12.3314L0.719577 5.71376C0.136415
          5.12592 0.103551 4.13724 0.646171 3.50548C1.18879 2.87372 2.10142 2.83812 2.68458 3.42596L8.26706
          9.05322L16.585 0.668606C17.1681 0.0807671 18.0807 0.116371 18.6234 0.748129Z" fill="#A80D10"
        />
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="15" height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M18.6234 0.748129C19.166 1.37989 19.1331 2.36857 18.55
        2.9564L9.24956 12.3314C8.69587 12.8895 7.83825 12.8895 7.28456 12.3314L0.719577 5.71376C0.136415
        5.12592 0.103551 4.13724 0.646171 3.50548C1.18879 2.87372 2.10142 2.83812 2.68458 3.42596L8.26706
        9.05322L16.585 0.668606C17.1681 0.0807671 18.0807 0.116371 18.6234 0.748129Z" fill="black"
      />
    </svg>
  );
};

export default SaveSvg;
