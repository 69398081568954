import { Button, Modal } from "react-bootstrap";

import "./modal-window.scss";

type ModalWindowProps = {
  isOpen: boolean;
  title: string;
  handleClose: () => void;
  onClose?: () => void;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

const ModalWindow = ({
  isOpen,
  title,
  handleClose,
  onClose = () => {},
  children,
  className = "",
}: ModalWindowProps) => {
  return (
    <>
      <Modal className={className} show={isOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onClose}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalWindow;
