import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchMaterials } from "features/tailor-made/tailor-made.slice";
import {
  TailorMadeHeader as TTailorMadeHeader,
  TailorMadeErrors,
} from "features/tailor-made/tailor-made.slice.type";

import "./tailor-made-header.scss";

type TailorMadeHeaderProps = {
  form: TTailorMadeHeader;
  setField: Function;
  errors: TailorMadeErrors;
};

const TailorMadeHeader = ({
  form,
  setField,
  errors,
}: TailorMadeHeaderProps) => {
  const { materials } = useAppSelector((state) => state.tailorMade);
  const { seven } = useAppSelector((state) => state.projectCreate);
  const dispatch = useAppDispatch();

  const [yes, setYes] = useState(seven.result === null);
  const [no, setNo] = useState(!yes);

  useEffect(() => {
    dispatch(fetchMaterials());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tailor-made-header">
      <Form className="form-tailor-made-header">
        <div className="header-first-block">
          <Form.Group
            controlId="formFieldOriginalDiameter"
            className="formFieldOriginalDiameter"
          >
            <Form.Label>D: Original diameter</Form.Label>
            <div className="formFieldOriginalDiameter_wrap-control">
              <div className="formFieldOriginalDiameter_wrap-number">
                <Form.Control
                  type="number"
                  min={0}
                  value={form.originalDiameterMm || ""}
                  onChange={(e) => {
                    setField("originalDiameterMm", parseFloat(e.target.value));
                    setField(
                      "originalDiameterInch",
                      parseFloat(
                        (parseFloat(e.target.value) * 0.0393701).toFixed(2)
                      )
                    );
                  }}
                  isInvalid={!!errors.originalDiameterMm}
                  readOnly={no}
                />
                <Form.Text>mm</Form.Text>
                <Form.Control
                  type="number"
                  min={0}
                  value={form.originalDiameterInch || ""}
                  onChange={(e) => {
                    setField(
                      "originalDiameterInch",
                      parseFloat(e.target.value)
                    );
                    setField(
                      "originalDiameterMm",
                      parseFloat((parseFloat(e.target.value) * 25.4).toFixed(2))
                    );
                  }}
                  isInvalid={!!errors.originalDiameterInch}
                  readOnly={no}
                />
                <Form.Text>inch</Form.Text>
              </div>
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: !!errors.originalDiameterMm ? "block" : "none",
                }}
              >
                {errors.originalDiameterMm || errors.originalDiameterInch}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group controlId="formFieldOption" className="formFieldOption">
            <Form.Label>Please select resin</Form.Label>
            <Form.Select
              aria-label="Default select resin"
              onChange={(e) => {
                setField("repairCompositeId", e.target.value);
                setField(
                  "tapeWidth",
                  e.target[parseInt(e.target.value)].dataset.tapeWidth
                );
              }}
              value={form.repairCompositeId}
              disabled={no}
            >
              {materials.map((item) => {
                return (
                  <option
                    value={item.id}
                    data-tape-width={item.tape_width}
                    key={item.id}
                  >
                    {item.resin_name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="col-3 header-second-block">
          <Form.Group
            controlId="formFieldDefectAxialLength"
            className="formFieldDefectAxialLength"
          >
            <Form.Label>Defect axial length</Form.Label>
            <div className="formFieldDefectAxialLength_wrap-control">
              <div className="formFieldDefectAxialLength_wrap-number">
                <Form.Control
                  type="number"
                  min={0}
                  value={form.defectAxialLength || ""}
                  onChange={(e) =>
                    setField("defectAxialLength", parseFloat(e.target.value))
                  }
                  isInvalid={!!errors.defectAxialLength}
                  readOnly={no}
                />
                <Form.Text>mm</Form.Text>
              </div>
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: !!errors.defectAxialLength ? "block" : "none",
                }}
              >
                {errors.defectAxialLength}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group
            controlId="formFieldTapeWidth"
            className="formFieldTapeWidth"
          >
            <Form.Label>Tape width</Form.Label>
            <div className="formFieldTapeWidth_wrap-control">
              <div className="formFieldTapeWidth_wrap-number">
                <Form.Control
                  type="text"
                  value={form.tapeWidth || "0"}
                  readOnly
                />
                <Form.Text>mm</Form.Text>
              </div>
            </div>
          </Form.Group>
        </div>
        <div className="col-2 header-third-block">
          <Form.Group controlId="formFieldLover" className="formFieldLover">
            <div className="formFieldLoverCorrect">
              <Form.Label>Correct</Form.Label>
              <div className="formFieldLoverCorrect_wrap">
                <Form.Check
                  label="Yes"
                  checked={yes}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setYes(true);
                      setNo(false);
                    }
                  }}
                />
                <Form.Check
                  label="No"
                  checked={no}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setYes(false);
                      setNo(true);
                    }
                  }}
                />
              </div>
            </div>
            <div className="formFieldLoverControl">
              <Form.Label>Lover</Form.Label>
              <Form.Control
                type="number"
                min={0}
                value={
                  form.lover && typeof form.lover === "number"
                    ? parseFloat(form.lover.toFixed(2))
                    : ""
                }
                onChange={(e) =>
                  setField(
                    "lover",
                    parseFloat(parseFloat(e.target.value).toFixed(2))
                  )
                }
                isInvalid={!!errors.lover}
                readOnly={no}
              />
            </div>
            <Form.Control.Feedback
              type="invalid"
              style={{
                display: !!errors.lover ? "block" : "none",
                textAlign: "center",
              }}
            >
              {errors.lover}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Form>
    </div>
  );
};

export default TailorMadeHeader;
