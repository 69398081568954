import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";

import Avatar from "assets/images/avatar.svg";
import Logo from "assets/images/logo.png";

import ModalInfoUser from "./components/modal-info-user/modal-info-user";

import "./header.scss";

const Header = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <header className="header">
      <Container>
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <div>Calculation Engine</div>
        <div>
          <Button variant="link" onClick={() => setShowInfo(true)}>
            <img className="avatar-user" src={Avatar} alt="avatar user" />
          </Button>
        </div>
      </Container>
      {showInfo && <ModalInfoUser handleClose={setShowInfo} />}
    </header>
  );
};

export default Header;
