import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import { PanelHeader, WarningModal } from "components/library";

import {
  TailorMadeHeader,
  TailorMadeMain,
  TailorMadeFooter,
  TailorMadeResults,
} from "./components";

import {
  addValueTailorMade,
  cleanValueTailorMade,
  calculateTailorMade,
  fetchWarningText,
} from "./tailor-made.slice";
import { TailorMadeErrors } from "./tailor-made.slice.type";

import "./tailor-made.scss";

type TailorMadeProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
};

const TailorMade = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
}: TailorMadeProps) => {
  const tailorMade = useAppSelector((state) => state.tailorMade);
  const [form, setForm] = useState(tailorMade);
  const [errors, setErrors] = useState<TailorMadeErrors>({});
  const [showWarningModal, setShowWarningModal] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setFieldHeader = (field: string, value: string) => {
    setForm((form) => ({
      ...form,
      header: {
        ...form.header,
        [field]: value,
      },
    }));

    if (!!errors[field as keyof TailorMadeErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const setFieldMain = (field: string, value: string) => {
    setForm((form) => ({
      ...form,
      main: {
        ...form.main,
        [field]: value,
      },
    }));

    if (!!errors[field as keyof TailorMadeErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const setFieldFooter = (field: string, value: string) => {
    setForm((form) => ({
      ...form,
      footer: {
        ...form.footer,
        [field]: value,
      },
    }));

    if (!!errors[field as keyof TailorMadeErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { header, main } = form;
    const { originalDiameterMm, defectAxialLength, lover } = header;
    const { repairLength, numberLayers } = main;

    const newErrors: TailorMadeErrors = {};

    if (!originalDiameterMm || originalDiameterMm === 0)
      newErrors.originalDiameterMm = "Please enter original diameter";
    if (!defectAxialLength || defectAxialLength === 0)
      newErrors.defectAxialLength = "Please enter defect axial length";
    if (!repairLength || repairLength === 0)
      newErrors.repairLength = "Please enter repair length";
    if (!numberLayers || numberLayers === 0)
      newErrors.numberLayers = "Please enter number layers ";
    if (!lover || lover === 0) newErrors.lover = "Please enter number lover";

    return newErrors;
  };

  useEffect(() => {
    if (flagNext) {
      dispatch(addValueTailorMade(form));
      navigate("/9");
    }

    if (flagPrev) {
      dispatch(addValueTailorMade(form));
      navigate("/7");
    }

    setFlagNext(false);
    setFlagPrev(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev]);

  useEffect(() => {
    if (form.warningText === "") {
      dispatch(fetchWarningText());
    }
  }, [dispatch, form.warningText]);

  const handleCalculate = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      dispatch(calculateTailorMade(form));
    }
  };

  const handleClear = () => {
    dispatch(cleanValueTailorMade());
    setForm({
      header: {
        originalDiameterMm: 0,
        originalDiameterInch: 0,
        defectAxialLength: 0,
        repairCompositeId: 1,
        tapeWidth: 100,
        lover: 0,
      },
      main: {
        option: "LTAPER_INCLUDED",
        repairLength: 0,
        numberLayers: 0,
        ltaper: 0,
      },
      footer: {
        reasonRepair: "",
      },
      results: {
        ltaper: 0,
        totalLength: "",
        quantityTapes: "",
        quantityBuckets: "",
      },
      materials: [...form.materials],
      warningText: form.warningText,
      loading: false,
      error: null,
    });
  };

  return (
    <Row className="gx-0">
      {!tailorMade.loading && tailorMade.warningText !== "" && (
        <WarningModal
          showWarningModal={showWarningModal}
          setShowWarningModal={setShowWarningModal}
        >
          {tailorMade.warningText}
        </WarningModal>
      )}
      <PanelHeader
        title="Tailor made (Optional)"
        className="min-height bc-light2"
      />
      <TailorMadeHeader
        form={form.header}
        setField={setFieldHeader}
        errors={errors}
      />
      <TailorMadeMain
        form={form.main}
        setField={setFieldMain}
        errors={errors}
        ltaperResults={tailorMade.results.ltaper}
      />
      <TailorMadeFooter
        form={form.footer}
        setField={setFieldFooter}
        errors={errors}
        handleCalculate={handleCalculate}
        handleClear={handleClear}
        loading={tailorMade.loading}
      />

      <PanelHeader title="Results" className="mt-3" />
      <TailorMadeResults form={{ ...tailorMade.results }} />
    </Row>
  );
};

export default TailorMade;
