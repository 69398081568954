import { Form } from "react-bootstrap";

import Warning from "assets/images/warning.svg";

import { Power } from "./power";

import "./maop.scss";

type MaopProps = {
  maopMPa: number;
  maopPsi: number;
  maopBar: number;
  designPressureMPa: number;
};

const Maop = ({ maopMPa, maopPsi, maopBar, designPressureMPa }: MaopProps) => {
  return (
    <div className="maop mt-2">
      <div className="maop_header">
        <Form.Label>Maop</Form.Label>
        <div className="formFieldMaop_wrap-control">
          <div className="formFieldMaop_wrap-number">
            <Form.Control
              type="text"
              value={maopMPa > 0 ? maopMPa.toFixed(2) : ""}
              readOnly
            />
            <Form.Text>MPa</Form.Text>
          </div>
          <div className="formFieldMaop_wrap-number">
            <Form.Control
              type="text"
              value={maopPsi > 0 ? maopPsi.toFixed(2) : ""}
              readOnly
            />
            <Form.Text>psi</Form.Text>
          </div>
          <div className="formFieldMaop_wrap-number">
            <Form.Control
              type="text"
              value={maopBar > 0 ? maopBar.toFixed(2) : ""}
              readOnly
            />
            <Form.Text>bar</Form.Text>
          </div>
        </div>
      </div>
      <div className="maop_footer">
        <Power
          value={designPressureMPa > 0 ? designPressureMPa : 0}
          label="Design pressure"
        />
        <Power value={maopMPa > 0 ? maopMPa : 0} label="MAOP" />
        {(designPressureMPa > maopMPa ||
          (designPressureMPa > 0 && isNaN(maopMPa))) && (
          <div className="maop_footer_warning">
            <img src={Warning} height="48px" alt="icon warning" />
            MAOP is less than design pressure
          </div>
        )}
      </div>
    </div>
  );
};

export default Maop;
