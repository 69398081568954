import { useState } from "react";
import { Form } from "react-bootstrap";

import "./thermal-expansion.scss";

type ThermalExpansionProps = {
  thermalExpansion: number;
  setField: Function;
};

const ThermalExpansion = ({
  thermalExpansion,
  setField,
}: ThermalExpansionProps) => {
  const [ifNot, setIfNot] = useState(false);

  return (
    <div className="thermal-expansion">
      <div className="thermal-expansion_header">
        <Form.Group
          controlId="formFieldThermalExpansion"
          className="formFieldThermalExpansion"
        >
          <Form.Label>Thermal expansion of Substrate Αs</Form.Label>
          <div className="formFieldThermalExpansion_wrap-control">
            <div className="formFieldThermalExpansion_wrap-number">
              <Form.Control
                type="number"
                min={0}
                value={thermalExpansion || ""}
                onChange={(e) => {
                  setField("thermalExpansion", parseFloat(e.target.value));
                }}
                disabled={!ifNot}
              />
              <Form.Text>mm.mm.°C-1</Form.Text>
            </div>
            {/* <Form.Control.Feedback
                    type="invalid"
                    style={{
                      display: !!errors.wallThicknessMm ? "block" : "none",
                    }}
                  >
                    {errors.wallThicknessMm}
                  </Form.Control.Feedback> */}
          </div>
        </Form.Group>
      </div>
      <div className="thermal-expansion_footer">
        <Form.Label>Correct:</Form.Label>
        <Form.Check
          label="If not"
          onChange={(e) => {
            setIfNot(e.target.checked);
            if (!e.target.checked) {
              setField("thermalExpansion", 12);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ThermalExpansion;
