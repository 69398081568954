import {
  pipeConnectionItems,
  pipeFluidItems,
  pipeNetworkItems,
  pipeEnvironmentalItems,
  apiUrl,
} from "constants/";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "app/hook";
import { getFetchProp } from "helpers/getFetchProp";
import {
  fetchDiameters,
  fetchGrades,
  fetchSchedules,
} from "features/project/project-create.slice";
import {
  PanelBody,
  PanelHeader,
  AddGradeModal,
  DeleteGradeModal,
} from "components/library";

import Bend from "assets/images/pipe_bend.png";
import Straight from "assets/images/pipe_straight.png";
import Tee from "assets/images/pipe_tee.png";

import { addValueTailorMadeStep3 } from "features/tailor-made/tailor-made.slice";

import {
  addValueStep3,
  setChangeAndLoaded,
  setIsFirstTime,
  addGradeToList,
  deleteGrade,
} from "../project-create.slice";

import ThermalExpansion from "./components/thermal-expansion";
import ModalGetProduct from "./components/modal-get-product";

import "./pipe-info.scss";

export type AddGradeValues = {
  name: string;
  smys: number | null;
  mby: number | null;
};

type PipeInfoProps = {
  flagNext: boolean;
  setFlagNext: Function;
  flagPrev: boolean;
  setFlagPrev: Function;
};

interface IPipeInfoErrors {
  originalDiameterMm?: string;
  wallThicknessMm?: string;
  mediumRadiusMm?: string;
  openingAngleMm?: string;
  branchDiameterMm?: string;
  wallThicknessTbMm?: string;
}

const PipeInfo = ({
  flagNext,
  setFlagNext,
  flagPrev,
  setFlagPrev,
}: PipeInfoProps) => {
  const {
    permissions = [],
    accountType,
    token,
  } = useAppSelector((state) => state.loginUser);
  const three = useAppSelector((state) => state.projectCreate.three);

  const { isLoadedProposal } = useAppSelector(
    (state) => state.projectCreate.two
  );
  const { loadingDiameters, loadingSchedules } = three;
  const [form, setForm] = useState(three);
  const [errors, setErrors] = useState<IPipeInfoErrors>({});
  // const [standartThickness, setStandartThickness] = useState(false);
  const [scheduleList, setScheduleList] = useState(
    three.schedules !== null
      ? three.schedules[form.diameter.toString()] || {}
      : {}
  );
  const [showModal, setShowModal] = useState(false);
  const [showGradeModal, setShowGradeModal] = useState(false);
  const [showDeleteGrade, setShowDeleteGrade] = useState(false);
  const [isNewMaterial, setIsNewMaterial] = useState(false);

  const { diameters, grades, schedules } = three;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setField = (field: string, value: string | boolean | number) => {
    setForm((form) => ({
      ...form,
      [field]: value,
    }));

    if (!!errors[field as keyof IPipeInfoErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const fetchBendDiameter = async (diameter: number) => {
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/bend/diameter?main_pipe_diameter_mm=${diameter}`,
      {
        ...getFetchProp({
          token: token,
          method: "GET",
          contentType: "application/json",
        }),
      }
    );

    res.json().then((data) => {
      setField("radius", -1);
      if (data === "Infinity") {
        setField("mediumRadiusMm", 0);
        setField("mediumRadiusInch", 0);
      } else {
        setField("mediumRadiusMm", parseFloat(data));
        setField(
          "mediumRadiusInch",
          parseFloat((parseFloat(data) * 0.0393701).toFixed(2))
        );
      }
    });
  };

  useEffect(() => {
    if (isLoadedProposal) {
      dispatch(setIsFirstTime(false));
    }
    dispatch(setChangeAndLoaded());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (grades.length === 0) {
      dispatch(fetchGrades());
    }
  }, [grades, dispatch]);

  useEffect(() => {
    if (form.standartThickness) {
      if (diameters === null || (isNewMaterial && !loadingDiameters)) {
        setIsNewMaterial(false);
        dispatch(fetchDiameters(form.material));
      }

      if (schedules === null || (isNewMaterial && !loadingSchedules)) {
        setIsNewMaterial(false);
        dispatch(fetchSchedules(form.material));
      }
    }
  }, [
    diameters,
    dispatch,
    form.standartThickness,
    schedules,
    form.material,
    isNewMaterial,
    loadingDiameters,
    loadingSchedules,
  ]);

  if (
    three.schedules !== null &&
    form.diameter > -1 &&
    scheduleList !== three.schedules[form.diameter.toString()]
  ) {
    setScheduleList(
      three.schedules !== null
        ? three.schedules[form.diameter.toString()] || {}
        : {}
    );
  }

  const validateForm = () => {
    const {
      originalDiameterMm,
      wallThicknessMm,
      mediumRadiusMm,
      typePipe,
      branchDiameterMm,
      wallThicknessTbMm,
      openingAngleMm,
    } = form;
    const newErrors: IPipeInfoErrors = {};

    if (!originalDiameterMm || originalDiameterMm === 0)
      newErrors.originalDiameterMm = "Please enter diameter mm";
    if (!wallThicknessMm || wallThicknessMm === 0)
      newErrors.wallThicknessMm = "Please enter wall thickness mm";
    if (typePipe === "BEND" && (!mediumRadiusMm || mediumRadiusMm === 0))
      newErrors.mediumRadiusMm = "Please enter medium radius mm";
    if (typePipe === "BEND" && (!openingAngleMm || openingAngleMm === 0))
      newErrors.openingAngleMm = "Please enter opening angle mm";
    if (typePipe === "TEE" && (!branchDiameterMm || branchDiameterMm === 0))
      newErrors.branchDiameterMm = "Please enter branch diameter mm";
    if (typePipe === "TEE" && (!wallThicknessTbMm || wallThicknessTbMm === 0))
      newErrors.wallThicknessTbMm = "Please enter wall thickness mm";

    return newErrors;
  };

  useEffect(() => {
    if (flagNext) {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setFlagNext(false);
      } else {
        setFlagNext(false);
        dispatch(addValueStep3(form));
        dispatch(
          addValueTailorMadeStep3({
            originalDiameterMm: form.originalDiameterMm,
            originalDiameterInch: form.originalDiameterInch,
          })
        );
        navigate("/4");
      }
    }

    if (flagPrev) {
      setFlagPrev(false);
      if (form.typePipe) {
        dispatch(addValueStep3(form));
        dispatch(
          addValueTailorMadeStep3({
            originalDiameterMm: form.originalDiameterMm,
            originalDiameterInch: form.originalDiameterInch,
          })
        );
      }
      navigate("/2");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagNext, flagPrev]);

  const addGrade = (values: AddGradeValues) => {
    dispatch(addGradeToList(values));
    setShowGradeModal(false);
  };

  const onDeleteGrade = (id: number) => {
    dispatch(deleteGrade(id));
  };

  const getBlockEnvironmental = (value: string) => {
    if (
      form.network === "STORAGE" &&
      (value === "BURIED" || value === "OFFSHORE_SUBSEA")
    )
      return true;

    if (form.network === "RISER" && value === "BURIED") return true;

    if (
      form.network === "VESSEL" &&
      (value === "BURIED" || value === "OFFSHORE_SUBSEA")
    )
      return true;

    return false;
  };

  const getBlockNetwork = (value: string) => {
    if (value === "STORAGE" && !permissions.includes("NETWORK_STORAGE_TANK"))
      return true;

    if (
      value === "VESSEL" &&
      !permissions.includes("NETWORK_PRESSURIZED_VESSEL")
    )
      return true;

    return false;
  };

  const renderModal = () => {
    return (
      <ModalGetProduct
        handleClose={setShowModal}
        setField={setField}
        show={showModal}
        text={form.chemicalProduct}
      />
    );
  };

  return (
    <Row className="gx-0">
      <Form className="form-pipe-info">
        <Col className="panel-wrap">
          <PanelHeader className="min-height" title="Type of pipe" />
          <PanelBody className="border-radius-none">
            <div className="row align-items-start">
              <Form.Group
                controlId="formFieldTypePipe"
                className="formFieldTypePipe"
              >
                <Form.Check
                  checked={form.typePipe === "STRAIGHT"}
                  type="radio"
                  name="groupTypePipe"
                  label="Straight"
                  onChange={(e) => {
                    if (e.target.checked) setField("typePipe", "STRAIGHT");
                  }}
                />
                <Form.Check
                  checked={form.typePipe === "BEND"}
                  name="groupTypePipe"
                  type="radio"
                  label="Bend"
                  onChange={(e) => {
                    if (e.target.checked) setField("typePipe", "BEND");
                  }}
                />
                <Form.Check
                  checked={form.typePipe === "TEE"}
                  disabled={!permissions.includes("PIPE_TYPE_TEE")}
                  name="groupTypePipe"
                  type="radio"
                  label="Tee"
                  onChange={(e) => {
                    if (e.target.checked) setField("typePipe", "TEE");
                  }}
                />
              </Form.Group>
              {form.typePipe === "STRAIGHT" && (
                <img src={Straight} alt="view pipe straight" />
              )}
              {form.typePipe === "BEND" && (
                <img
                  className="pipe-bend-img"
                  src={Bend}
                  alt="view pipe bend"
                />
              )}
              {form.typePipe === "TEE" && <img src={Tee} alt="view pipe tee" />}
            </div>
          </PanelBody>
          <PanelHeader
            title="Pipe dimensions"
            className="border-radius-none min-height"
          />
          <PanelBody className="border-radius-none">
            <div className="row align-items-start row-gap-10">
              <Form.Group
                controlId="formFieldOriginalDiameter"
                className="formFieldOriginalDiameter"
              >
                <Form.Label>D: Original diameter</Form.Label>
                <div className="formFieldOriginalDiameter_wrap-control">
                  <div className="formFieldOriginalDiameter_wrap-number">
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.originalDiameterMm}
                      onChange={(e) => {
                        setField(
                          "originalDiameterMm",
                          parseFloat(e.target.value)
                        );
                        setField(
                          "originalDiameterInch",
                          parseFloat(
                            (parseFloat(e.target.value) * 0.0393701).toFixed(2)
                          )
                        );
                        setField("standartThickness", false);
                        setField("diameter", -1);
                        setField("schedule", -1);
                        setScheduleList({});

                        if (
                          form.typePipe === "BEND" &&
                          parseFloat(e.target.value) > 0
                        ) {
                          fetchBendDiameter(parseFloat(e.target.value));
                        }
                      }}
                      isInvalid={!!errors.originalDiameterMm}
                    />
                    <Form.Text>mm</Form.Text>
                  </div>

                  <div className="formFieldOriginalDiameter_wrap-number">
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.originalDiameterInch}
                      onChange={(e) => {
                        setField(
                          "originalDiameterInch",
                          parseFloat(e.target.value)
                        );
                        setField(
                          "originalDiameterMm",
                          parseFloat(
                            (parseFloat(e.target.value) * 25.4).toFixed(2)
                          )
                        );
                        setField("standartThickness", false);
                        setField("diameter", -1);
                        setField("schedule", -1);
                        setScheduleList({});

                        if (
                          form.typePipe === "BEND" &&
                          parseFloat(e.target.value) > 0
                        ) {
                          fetchBendDiameter(parseFloat(e.target.value) * 25.4);
                        }
                      }}
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      display: !!errors.originalDiameterMm ? "block" : "none",
                    }}
                  >
                    {errors.originalDiameterMm}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldOption"
                className="formFieldOption"
              >
                <Form.Label>
                  <Form.Check
                    className="standard-checkbox"
                    checked={form.standartThickness}
                    label="Standard thickness"
                    onChange={(e) => {
                      setField("standartThickness", !form.standartThickness);

                      if (!e.target.checked) {
                        setField("diameter", -1);
                        setField("schedule", -1);
                        setScheduleList({});
                      }
                    }}
                  />
                </Form.Label>
                <div className="formFieldOption_wrap-control">
                  <div className="formFieldOriginalDiameter_wrap-number">
                    <Form.Select
                      aria-label="Default select diameter"
                      onChange={(e) => {
                        let temp =
                          e.target[parseInt(e.target.value)].dataset
                            .realDiameter || "0";

                        setField("diameter", e.target.value);
                        setField(
                          "originalDiameterMm",
                          parseFloat((parseFloat(temp) * 25.4).toFixed(2))
                        );
                        setField(
                          "originalDiameterInch",
                          parseFloat(parseFloat(temp).toFixed(2))
                        );
                        setScheduleList(
                          three.schedules !== null
                            ? three.schedules[e.target.value.toString()] || {}
                            : {}
                        );
                        setField("standartThickness", true);
                        setField("schedule", -1);
                        setField("wallThicknessMm", 0);
                        setField("wallThicknessInch", 0);

                        if (
                          form.typePipe === "BEND" &&
                          parseFloat(temp) * 25.4 > 0
                        ) {
                          fetchBendDiameter(parseFloat(temp) * 25.4);
                        }
                      }}
                      value={form.diameter}
                    >
                      <option value={-1} key={-1}></option>
                      {diameters !== null &&
                        diameters.map((item) => {
                          let realD = item.real_diameter.toFixed(3);
                          if (~`${realD}`.indexOf("000")) {
                            realD = item.real_diameter.toFixed(2);
                          }
                          return (
                            <option
                              value={item.id}
                              data-nominal-diameter={item.nominal_diameter}
                              data-real-diameter={item.real_diameter}
                              key={item.id}
                            >
                              {`${item.nominal_diameter} -> ${realD}`}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Text>inch</Form.Text>
                  </div>
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldMaterial"
                className="formFieldMaterial"
              >
                <Form.Label>Material</Form.Label>
                <div className="formFieldMaterial_wrap">
                  <Form.Check
                    checked={form.material === "STEEL"}
                    type="radio"
                    name="groupMaterial"
                    label="Carbon steel"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setField("material", "STEEL");

                        setField("schedule", -1);
                        setField("wallThicknessMm", 0);
                        setField("wallThicknessInch", 0);

                        setIsNewMaterial(true);
                      }
                    }}
                  />
                  <Form.Check
                    checked={form.material === "STEINLESS_STEEL"}
                    name="groupMaterial"
                    type="radio"
                    label="Stainless steel"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setField("material", "STEINLESS_STEEL");

                        setField("schedule", -1);
                        setField("wallThicknessMm", 0);
                        setField("wallThicknessInch", 0);

                        setIsNewMaterial(true);
                      }
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldOption"
                className="formFieldOption"
              >
                <Form.Label>Schedule</Form.Label>
                <div className="formFieldOption_wrap-control">
                  <Form.Select
                    aria-label="Default select schedule"
                    onChange={(e) => {
                      setField("schedule", e.target.value);

                      let value =
                        e.target[parseInt(e.target.value) + 1].dataset
                          .wallThickness;
                      if (value !== undefined) {
                        value = value.replace(",", ".");
                      }

                      setField("wallThicknessMm", value || 0);

                      setField(
                        "wallThicknessInch",
                        parseFloat(
                          (parseFloat(value || "0") * 0.0393701).toFixed(2)
                        )
                      );
                    }}
                    value={form.schedule}
                    disabled={!form.standartThickness && !form.diameter}
                  >
                    <option value={-1} key={-1}></option>
                    {Object.keys(scheduleList).map((keyObj, index) => {
                      return (
                        <option
                          value={index}
                          data-wall-thickness={scheduleList[keyObj]}
                          key={keyObj}
                        >
                          {keyObj}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              </Form.Group>
              <Form.Group
                controlId="formFieldWallThickness"
                className="formFieldWallThickness"
                style={form.typePipe !== "STRAIGHT" ? { paddingBottom: 0 } : {}}
              >
                <Form.Label>t: Wall thickness</Form.Label>
                <div className="formFieldWallThickness_wrap-control">
                  <div className="formFieldWallThickness_wrap-number">
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.wallThicknessMm}
                      onChange={(e) => {
                        setField("wallThicknessMm", parseFloat(e.target.value));

                        setField(
                          "wallThicknessInch",
                          parseFloat(
                            (parseFloat(e.target.value) * 0.0393701).toFixed(2)
                          )
                        );
                      }}
                      readOnly={
                        form.standartThickness &&
                        Object.keys(scheduleList).length > 0
                      }
                      isInvalid={!!errors.wallThicknessMm}
                    />
                    <Form.Text>mm</Form.Text>
                  </div>
                  <div className="formFieldOriginalDiameter_wrap-number">
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.wallThicknessInch}
                      onChange={(e) => {
                        setField(
                          "wallThicknessInch",
                          parseFloat(e.target.value)
                        );

                        setField(
                          "wallThicknessMm",
                          parseFloat(
                            (parseFloat(e.target.value) * 25.4).toFixed(2)
                          )
                        );
                      }}
                      readOnly={
                        form.standartThickness &&
                        Object.keys(scheduleList).length > 0
                      }
                    />
                    <Form.Text>inch</Form.Text>
                  </div>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      display: !!errors.wallThicknessMm ? "block" : "none",
                    }}
                  >
                    {errors.wallThicknessMm}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              {form.typePipe === "BEND" && (
                <>
                  <Form.Group
                    controlId="formFieldMediumRadius"
                    className="formFieldMediumRadius"
                  >
                    <Form.Label>A: Medium radius of the bend</Form.Label>
                    <div className="formFieldMediumRadius_wrap-control">
                      <div className="formFieldMediumRadius_wrap-number">
                        <Form.Control
                          type="number"
                          min={0}
                          value={form.mediumRadiusMm}
                          onChange={(e) => {
                            setField("radius", -1);
                            setField(
                              "mediumRadiusMm",
                              parseFloat(e.target.value)
                            );
                            setField(
                              "mediumRadiusInch",
                              parseFloat(
                                (
                                  parseFloat(e.target.value) * 0.0393701
                                ).toFixed(2)
                              )
                            );
                          }}
                          isInvalid={!!errors.mediumRadiusMm}
                        />
                        <Form.Text>mm</Form.Text>
                      </div>
                      <div className="formFieldMediumRadius_wrap-number">
                        <Form.Control
                          type="number"
                          min={0}
                          value={form.mediumRadiusInch}
                          onChange={(e) => {
                            setField("radius", -1);
                            setField(
                              "mediumRadiusInch",
                              parseFloat(e.target.value)
                            );
                            setField(
                              "mediumRadiusMm",
                              parseFloat(
                                (parseFloat(e.target.value) * 25.4).toFixed(2)
                              )
                            );
                          }}
                        />
                        <Form.Text>inch</Form.Text>
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !!errors.mediumRadiusMm ? "block" : "none",
                        }}
                      >
                        {errors.mediumRadiusMm}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="formFieldOption"
                    className="formFieldOption"
                  >
                    <Form.Label></Form.Label>
                    <div className="formFieldOption_wrap-control">
                      <div className="formFieldOriginalDiameter_wrap-number">
                        <Form.Select
                          aria-label="Default select diameter"
                          onChange={(e) => {
                            let temp =
                              e.target[parseInt(e.target.value) - 1].dataset
                                .realDiameter || "0";

                            setField("radius", e.target.value);
                            setField(
                              "mediumRadiusMm",
                              parseFloat((parseFloat(temp) * 25.4).toFixed(2))
                            );
                            setField(
                              "mediumRadiusInch",
                              parseFloat(parseFloat(temp).toFixed(2))
                            );
                          }}
                          value={form.radius}
                        >
                          <option value={-1} key={-1}></option>
                          {diameters !== null &&
                            diameters.map((item) => {
                              return (
                                <option
                                  value={item.id}
                                  data-nominal-diameter={item.nominal_diameter}
                                  data-real-diameter={item.real_diameter}
                                  key={item.id}
                                >
                                  {`${
                                    item.nominal_diameter
                                  } -> ${item.real_diameter.toFixed(3)}`}
                                </option>
                              );
                            })}
                        </Form.Select>
                        <Form.Text>inch</Form.Text>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="formFieldOpeningAngle"
                    className="formFieldOpeningAngle"
                  >
                    <Form.Label>α: Opening angle</Form.Label>
                    <div className="formFieldOpeningAngle_wrap-control">
                      <div className="formFieldOpeningAngle_wrap-number">
                        <Form.Control
                          type="number"
                          min={0}
                          value={form.openingAngleMm}
                          onChange={(e) => {
                            setField(
                              "openingAngleMm",
                              parseFloat(e.target.value)
                            );
                          }}
                          isInvalid={!!errors.openingAngleMm}
                        />
                        <Form.Text>degree</Form.Text>
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !!errors.openingAngleMm ? "block" : "none",
                        }}
                      >
                        {errors.openingAngleMm}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </>
              )}
              {form.typePipe === "TEE" && (
                <>
                  <Form.Group
                    controlId="formFieldAOriginalDiameter"
                    className="formFieldAOriginalDiameter"
                  >
                    <Form.Label>Db: Original diameter</Form.Label>
                    <div className="formFieldAOriginalDiameter_wrap-control">
                      <div className="formFieldAOriginalDiameter_wrap-number">
                        <Form.Control
                          type="number"
                          min={0}
                          value={form.branchDiameterMm}
                          onChange={(e) => {
                            setField(
                              "branchDiameterMm",
                              parseFloat(e.target.value)
                            );
                            setField(
                              "branchDiameterInch",
                              parseFloat(
                                (
                                  parseFloat(e.target.value) * 0.0393701
                                ).toFixed(2)
                              )
                            );
                          }}
                          readOnly={form.standartThickness}
                          isInvalid={!!errors.branchDiameterMm}
                        />
                        <Form.Text>mm</Form.Text>
                      </div>
                      <div className="formFieldAOriginalDiameter_wrap-number">
                        {form.standartThickness ? (
                          <Form.Select
                            aria-label="Default select diameter"
                            onChange={(e) => {
                              let temp =
                                e.target[parseInt(e.target.value) - 1].dataset
                                  .realDiameter || "0";

                              setField("radius", e.target.value);
                              setField(
                                "branchDiameterMm",
                                parseFloat((parseFloat(temp) * 25.4).toFixed(2))
                              );
                            }}
                            value={form.radius}
                          >
                            {diameters !== null &&
                              diameters.map((item) => {
                                return (
                                  <option
                                    value={item.id}
                                    data-nominal-diameter={
                                      item.nominal_diameter
                                    }
                                    data-real-diameter={item.real_diameter}
                                    key={item.id}
                                  >
                                    {`${
                                      item.nominal_diameter
                                    } -> ${item.real_diameter.toFixed(3)}`}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            type="number"
                            min={0}
                            value={form.branchDiameterInch}
                            onChange={(e) => {
                              setField(
                                "branchDiameterInch",
                                parseFloat(e.target.value)
                              );
                              setField(
                                "branchDiameterMm",
                                parseFloat(
                                  (parseFloat(e.target.value) * 25.4).toFixed(2)
                                )
                              );
                            }}
                          />
                        )}
                        <Form.Text>inch</Form.Text>
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !!errors.branchDiameterMm ? "block" : "none",
                        }}
                      >
                        {errors.branchDiameterMm}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="formFieldWallThickness"
                    className="formFieldWallThickness"
                    style={{ paddingBottom: 0 }}
                  >
                    <Form.Label>tb: Wall thickness</Form.Label>
                    <div className="formFieldWallThickness_wrap-control">
                      <div className="formFieldWallThickness_wrap-number">
                        <Form.Control
                          type="number"
                          min={0}
                          value={form.wallThicknessTbMm}
                          onChange={(e) => {
                            setField(
                              "wallThicknessTbMm",
                              parseFloat(e.target.value)
                            );

                            setField(
                              "wallThicknessTbInch",
                              parseFloat(
                                (
                                  parseFloat(e.target.value) * 0.0393701
                                ).toFixed(2)
                              )
                            );
                          }}
                          isInvalid={!!errors.wallThicknessTbMm}
                        />
                        <Form.Text>mm</Form.Text>
                      </div>
                      <div className="formFieldOriginalDiameter_wrap-number">
                        <Form.Control
                          type="number"
                          min={0}
                          value={form.wallThicknessTbInch}
                          onChange={(e) => {
                            setField(
                              "wallThicknessTbInch",
                              parseFloat(e.target.value)
                            );

                            setField(
                              "wallThicknessTbMm",
                              parseFloat(
                                (parseFloat(e.target.value) * 25.4).toFixed(2)
                              )
                            );
                          }}
                        />
                        <Form.Text>inch</Form.Text>
                      </div>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !!errors.wallThicknessTbMm
                            ? "block"
                            : "none",
                        }}
                      >
                        {errors.wallThicknessTbMm}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </>
              )}
            </div>
          </PanelBody>
          <ThermalExpansion
            thermalExpansion={form.thermalExpansion}
            setField={setField}
          />
        </Col>
        <Col>
          <PanelHeader
            className="min-height"
            title="Pipe information"
            theme="light"
          />
          <PanelBody theme="light">
            <div className="row align-items-start row-gap-10">
              <Form.Label className="title title_red">Grade:</Form.Label>
              <Form.Group controlId="formFieldGrade" className="formFieldGrade">
                <div className="formFieldGrade_select-wrapp">
                  <Form.Label>Choose a grade</Form.Label>
                  <Form.Select
                    aria-label="Default select a grade"
                    onChange={(e) => {
                      setField("grade", parseInt(e.target.value) + 1);

                      setField(
                        "smysMPa",
                        parseFloat(
                          (
                            parseFloat(
                              e.target[parseInt(e.target.value)].dataset.smys ||
                                "0"
                            ) / 145.038
                          ).toFixed(2)
                        )
                      );

                      setField(
                        "smyspsi",
                        parseInt(
                          e.target[parseInt(e.target.value)].dataset.smys || "0"
                        )
                      );

                      setField(
                        "mby",
                        e.target[parseInt(e.target.value)].dataset.mby || "0"
                      );
                    }}
                    value={form.grade - 1}
                  >
                    {grades.map((item) => {
                      return (
                        <option
                          value={item.id - 1}
                          data-smys={item.smys}
                          data-mby={item.mby}
                          key={item.id}
                        >
                          {item.grade}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="formFieldGrade_btn-wrapp">
                  <Button
                    className="btn-add-delete"
                    onClick={() => setShowGradeModal(true)}
                  >
                    Add
                  </Button>
                  <Button
                    className="btn-add-delete"
                    onClick={() => setShowDeleteGrade(true)}
                  >
                    Delete
                  </Button>
                </div>
              </Form.Group>
              <AddGradeModal
                showGradeModal={showGradeModal}
                setShowGradeModal={setShowGradeModal}
                onClose={addGrade}
              />
              <DeleteGradeModal
                showDeleteGrade={showDeleteGrade}
                setShowDeleteGrade={setShowDeleteGrade}
                gradeList={grades}
                onClose={onDeleteGrade}
                accountType={accountType}
              />
              <div className="smysAndMbyGroup">
                <Form.Group controlId="formFieldSmys" className="formFieldSmys">
                  <Form.Label>Specific Minimum Yield Stress (SMYS)</Form.Label>
                  <div className="formFieldSmys_wrap-number">
                    <div>
                      <Form.Control
                        type="number"
                        min={0}
                        value={form.smysMPa}
                        onChange={(e) => {
                          setField("smysMPa", parseFloat(e.target.value));

                          setField(
                            "smyspsi",
                            parseFloat(e.target.value) * 145.038
                          );
                        }}
                      />
                      <Form.Text>MPa</Form.Text>
                    </div>
                    <div>
                      <Form.Control
                        type="number"
                        min={0}
                        value={form.smyspsi}
                        onChange={(e) => {
                          setField("smyspsi", parseFloat(e.target.value));

                          setField(
                            "smysMPa",
                            parseFloat(e.target.value) / 145.038
                          );
                        }}
                      />
                      <Form.Text>psi</Form.Text>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="formFieldMby" className="formFieldMby">
                  <Form.Label>Elastic Modulus Before Yield (MBY)</Form.Label>
                  <div className="formFieldMby_wrap-number">
                    <Form.Control
                      type="number"
                      min={0}
                      value={form.mby}
                      onChange={(e) => {
                        setField("mby", parseFloat(e.target.value));
                      }}
                    />
                    <Form.Text>psi</Form.Text>
                  </div>
                </Form.Group>
              </div>
              <div className="connection-block">
                <Form.Label className="title title_red">Connection:</Form.Label>
                <Form.Group
                  controlId="formFieldConnection"
                  className="formFieldConnection"
                >
                  <div className="formFieldConnection_wrap">
                    {pipeConnectionItems.map((item) => (
                      <div className="formFieldConnection_item" key={item.id}>
                        <Form.Label>
                          {item.label} {item.labelValue}
                        </Form.Label>
                        <Form.Check
                          checked={form.connection === item.value}
                          type="radio"
                          name="groupConnection"
                          label=""
                          onChange={(e) => {
                            if (e.target.checked)
                              setField("connection", item.value);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Form.Group>
              </div>
            </div>
          </PanelBody>
        </Col>
        <Col>
          <PanelHeader
            className="min-height"
            title="Pipe information"
            theme="light"
          />
          <PanelBody theme="light">
            <div className="row align-items-start row-gap-10">
              <Form.Label className="title title_red">Fluid:</Form.Label>
              <Form.Group controlId="formFieldFluid" className="formFieldFluid">
                <div className="formFieldFluid_wrap">
                  {pipeFluidItems.map((item) => (
                    <div className="formFieldFluid_item" key={item.id}>
                      <Form.Label>{item.label}</Form.Label>
                      <Form.Check
                        checked={form.fluid === item.value}
                        type="radio"
                        name="groupFluid"
                        label={item.labelValue}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setField("fluid", item.value);
                          }
                        }}
                        onClick={(e) => {
                          if (item.value === "CHEMICAL_PRODUCT")
                            setShowModal(true);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Form.Group>
              <Form.Label className="title title_red">Network:</Form.Label>
              <Form.Group
                controlId="formFieldNetwork"
                className="formFieldNetwork"
              >
                <div className="formFieldNetwork_wrap">
                  {pipeNetworkItems.map((item) => (
                    <div className="formFieldNetwork_item" key={item.id}>
                      <Form.Label>{item.label}</Form.Label>
                      <Form.Check
                        checked={form.network === item.value}
                        disabled={getBlockNetwork(item.value)}
                        type="radio"
                        name="groupNetwork"
                        label={item.labelValue}
                        onChange={(e) => {
                          if (e.target.checked) setField("network", item.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Form.Group>
              <Form.Label className="title title_red">
                Environmental:
              </Form.Label>
              <Form.Group
                controlId="formFieldEnvironmental"
                className="formFieldEnvironmental"
              >
                <div className="formFieldEnvironmental_wrap">
                  {pipeEnvironmentalItems.map((item) => (
                    <div className="formFieldEnvironmental_item" key={item.id}>
                      <Form.Label>{item.label}</Form.Label>
                      <Form.Check
                        checked={form.environmental === item.value}
                        type="radio"
                        name="groupEnvironmental"
                        label={item.labelValue}
                        onChange={(e) => {
                          if (e.target.checked)
                            setField("environmental", item.value);
                        }}
                        disabled={getBlockEnvironmental(item.value)}
                      />
                    </div>
                  ))}
                </div>
              </Form.Group>
            </div>
          </PanelBody>
        </Col>
      </Form>
      {showModal && renderModal()}
    </Row>
  );
};

export default PipeInfo;
