import { useState, useEffect } from "react";

import { ModalWindow } from "components/library";
import { PipeInfoGrade } from "features/project/project-create.slice.type";

import DeleteSvg from "../../svg/delete";

type IModalProps = {
  onClose: (id: number) => void;
  showDeleteGrade: boolean;
  setShowDeleteGrade: (a: boolean) => void;
  gradeList: Array<PipeInfoGrade>;
  accountType: string;
};

const DeleteGradeModal = ({
  onClose,
  showDeleteGrade,
  setShowDeleteGrade,
  gradeList,
  accountType,
}: IModalProps) => {
  const [ownGrades, setOwnGrades] = useState<Array<PipeInfoGrade>>([]);

  useEffect(() => {
    const arr = gradeList.filter(
      (item) => !item.hasOwnProperty("add_by_admin")
    );
    if (
      accountType === "ADMINISTRATOR" ||
      accountType === "TECHNICAL_ADMINISTRATOR"
    ) {
      setOwnGrades(gradeList);
    } else {
      setOwnGrades(arr);
    }
  }, [gradeList, accountType]);

  return (
    <ModalWindow
      isOpen={showDeleteGrade}
      title="Delete grade"
      handleClose={() => setShowDeleteGrade(false)}
      className="add-grade-modal"
    >
      <table>
        <thead>
          <tr className="add-grade-modal_header">
            <th>Grade</th>
            <th>SMYS</th>
            <th>MBY</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ownGrades.map((item) => (
            <tr key={item.id}>
              <td>{item.grade}</td>
              <td>{item.smys}</td>
              <td>{item.mby}</td>
              <td>
                <button className="hover-btn" onClick={() => onClose(item.id)}>
                  <DeleteSvg isHover />
                  <DeleteSvg />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ModalWindow>
  );
};

export default DeleteGradeModal;
