import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import { useAppDispatch } from "app/hook";
import { unique } from "helpers/unique";
import { PanelBody, PanelHeader } from "components/library";
import { TailorMadeMaterial } from "features/tailor-made/tailor-made.slice.type";
import {
  SelectProducts,
  FetchMaterialFilter,
} from "features/project/project-create.slice.type";
import { fetchPrimer } from "features/project/project-create.slice";
import Thermometer from "assets/images/thermometer.png";
import ThermometerHeat from "assets/images/thermometer_heat.png";

type SolutionSelectionProps = {
  setField: (field: string, value: string | number) => void;
  form: SelectProducts;
  materials: TailorMadeMaterial[];
  allmaterials: TailorMadeMaterial[];
  getFilterMaterial: (values: FetchMaterialFilter) => void;
  errors: {
    solutionSelection?: string;
    tapeType?: string;
    tapeWidth?: string;
    resinSelection?: string;
  };
  installTemp: number;
  isFirstTime: boolean;
  environmental: string;
};

type TypeAndResin = {
  type: Array<string>;
  resin: Array<string>;
};

const SolutionSelection = ({
  setField,
  form,
  materials,
  errors,
  installTemp,
  isFirstTime,
  allmaterials,
  getFilterMaterial,
  environmental,
}: SolutionSelectionProps) => {
  const [blink, setBlink] = useState({
    isHeatBlink: false,
    isColdBlink: false,
  });
  const [filterMaterials, setFilterMaterials] = useState<TailorMadeMaterial[]>(
    []
  );
  const [compositeType, setCompositeType] = useState<Array<string>>([]);
  const [typeAndResin, setTypeAndResin] = useState<TypeAndResin>({
    type: [],
    resin: [],
  });
  const [width, setWidth] = useState<Array<number>>([]);
  const [isUpdateResin, setIsUpdateResin] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (installTemp < form.minimumInstallationTemperature) {
      setBlink({
        ...blink,
        isColdBlink: true,
      });
    } else if (installTemp > form.maximumInstallationTemperature) {
      setBlink({
        ...blink,
        isHeatBlink: true,
      });
    } else
      setBlink({
        isHeatBlink: false,
        isColdBlink: false,
      });
    // eslint-disable-next-line
  }, [
    form.minimumInstallationTemperature,
    form.maximumInstallationTemperature,
    installTemp,
  ]);

  useEffect(() => {
    if (allmaterials.length) {
      const arr: Array<string> = [];
      allmaterials.forEach((item) => {
        arr.push(item.composite_type);
      });
      setCompositeType(arr);
    }
    // eslint-disable-next-line
  }, [materials]);

  useEffect(() => {
    if (materials.length) {
      const selectResin = materials.filter(
        (item) => item.composite_type === form.solutionSelection
      );
      setFilterMaterials(selectResin);
    }
    // eslint-disable-next-line
  }, [form.solutionSelection, materials]);

  useEffect(() => {
    const type: Array<string> = [];
    const width: Array<number> = [];

    if (!isFirstTime) {
      setField("tapeType", form.tapeType);
      setField("tapeWidth", form.tapeWidth);
      setField("resinSelection", form.resinSelection);
      setField(
        "maximumInstallationTemperature",
        form.maximumInstallationTemperature
      );
      setField(
        "minimumInstallationTemperature",
        form.minimumInstallationTemperature
      );

      const newMaterials = filterMaterials.filter(
        (item) => item.tape_type === form.tapeType
      );

      newMaterials.length &&
        newMaterials.forEach((item) => width.push(item.tape_width));
      filterMaterials.length &&
        filterMaterials.forEach((item) => type.push(item.tape_type));
    } else if (filterMaterials.length) {
      setField("tapeType", filterMaterials[0].tape_type);
      setField("tapeWidth", filterMaterials[0].tape_width);
      setField("resinSelection", filterMaterials[0].resin_name);
      setField(
        "maximumInstallationTemperature",
        filterMaterials[0].maximum_installation_temperature
      );
      setField(
        "minimumInstallationTemperature",
        filterMaterials[0].minimum_installation_temperature
      );
      filterMaterials.forEach((item) => {
        type.push(item.tape_type);
        width.push(item.tape_width);
      });
    }
    setTypeAndResin({
      ...typeAndResin,
      type: unique(type),
    });
    setWidth(unique(width));
    // eslint-disable-next-line
  }, [filterMaterials]);

  useEffect(() => {
    const width: Array<number> = [];
    if (filterMaterials.length) {
      const arr = filterMaterials.filter(
        (item) => item.tape_type === form.tapeType
      );
      arr.forEach((item) => {
        width.push(item.tape_width);
      });
    }
    setWidth(unique(width));
    // eslint-disable-next-line
  }, [form.tapeType]);

  useEffect(() => {
    if (isUpdateResin) {
      typeAndResin.resin.length &&
        setField("resinSelection", typeAndResin.resin[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeAndResin.resin]);

  useEffect(() => {
    const type: Array<string> = [];
    if (filterMaterials.length) {
      const arr = filterMaterials.filter(
        (item) => item.tape_width === form.tapeWidth
      );
      arr.forEach((item) => {
        type.push(item.tape_type);
      });
    }
    setTypeAndResin({
      ...typeAndResin,
      type: unique(type),
    });
    // eslint-disable-next-line
  }, [form.tapeWidth]);

  useEffect(() => {
    const resin: Array<string> = [];
    if (materials.length) {
      const arr = materials.filter((item) => {
        return (
          item.tape_width === form.tapeWidth &&
          item.tape_type === form.tapeType &&
          item.composite_type === form.solutionSelection
        );
      });
      arr.forEach((item) => {
        resin.push(item.resin_name);
      });
    }
    setTypeAndResin({
      ...typeAndResin,
      resin: unique(resin),
    });
    // eslint-disable-next-line
  }, [form.tapeWidth, form.tapeType, materials, form.solutionSelection]);

  useEffect(() => {
    const arr = materials.filter((item) => {
      return (
        item.tape_width === form.tapeWidth &&
        item.tape_type === form.tapeType &&
        item.composite_type === form.solutionSelection &&
        item.resin_name === form.resinSelection
      );
    });

    arr.forEach((item) => {
      setField(
        "maximumInstallationTemperature",
        item.maximum_installation_temperature
      );

      setField(
        "minimumInstallationTemperature",
        item.minimum_installation_temperature
      );

      setField("repairCompositeId", item.id);

      dispatch(fetchPrimer(item.id));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.tapeWidth,
    form.tapeType,
    form.resinSelection,
    form.solutionSelection,
  ]);

  return (
    <>
      <PanelHeader
        title="Solution selection"
        isSolutionSelection
        setField={setField}
        solutionSelection={form.solutionSelection}
        getFilterMaterial={getFilterMaterial}
        materials={unique(compositeType)}
        errors={errors}
      />
      <PanelBody>
        <div className="gx-0 py-2 row align-items-start">
          <div className="typeTypeAndResinBlock">
            <Form.Group
              controlId="formFieldTapeOfType"
              className="formFieldSolutionSelect"
            >
              <Form.Label>Tape of type:</Form.Label>
              <Form.Select
                onChange={(e) => {
                  setIsUpdateResin(true);
                  setField("tapeType", e.target.value);
                }}
                value={form.tapeType}
                disabled={
                  !typeAndResin.type.length || typeAndResin.type[0] === ""
                }
              >
                {typeAndResin.type.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group
              controlId="formFieldResinSelection"
              className="formFieldSolutionSelect"
            >
              <Form.Label>Please select resin:</Form.Label>
              <Form.Select
                aria-label="Default select resin"
                onChange={(e) => {
                  setField("resinSelection", e.target.value);
                }}
                value={form.resinSelection}
                disabled={
                  !typeAndResin.resin.length || typeAndResin.resin[0] === ""
                }
              >
                {typeAndResin.resin.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </div>
          <div className="widthAndTemp">
            {!(
              Array.isArray(width) &&
              width.length === 1 &&
              width[0] === 300
            ) &&
              width.length > 0 && (
                <Form.Group
                  controlId="formFieldTapeWidth"
                  className="formFieldTapeWidth"
                >
                  <Form.Label>Tape width (mm):</Form.Label>
                  <div className="formFieldTapeWidth-btns">
                    {width.includes(25) && (
                      <Form.Control
                        className={
                          form.tapeWidth === 25
                            ? "btn-item btn-item-active"
                            : "btn-item"
                        }
                        type="button"
                        value="25 mm"
                        onClick={() => setField("tapeWidth", 25)}
                      />
                    )}
                    {width.includes(50) && (
                      <Form.Control
                        className={
                          form.tapeWidth === 50
                            ? "btn-item btn-item-active"
                            : "btn-item"
                        }
                        type="button"
                        value="50 mm"
                        onClick={() => setField("tapeWidth", 50)}
                      />
                    )}
                    {width.includes(100) && (
                      <Form.Control
                        className={
                          form.tapeWidth === 100
                            ? "btn-item btn-item-active"
                            : "btn-item"
                        }
                        type="button"
                        value="100 mm"
                        onClick={() => setField("tapeWidth", 100)}
                      />
                    )}
                    {width.includes(150) && (
                      <Form.Control
                        className={
                          form.tapeWidth === 150
                            ? "btn-item btn-item-active"
                            : "btn-item"
                        }
                        type="button"
                        value="150 mm"
                        onClick={() => setField("tapeWidth", 150)}
                      />
                    )}
                    {width.includes(285) && (
                      <Form.Control
                        className={
                          form.tapeWidth === 285
                            ? "btn-item btn-item-active"
                            : "btn-item"
                        }
                        type="button"
                        value="285 mm"
                        onClick={() => setField("tapeWidth", 285)}
                      />
                    )}
                  </div>
                </Form.Group>
              )}
            <Form.Group
              controlId="formFieldAplicationTemp"
              className="formFieldAplicationTemp"
            >
              <Form.Label>Application temperature:</Form.Label>
              <div className="formFieldAplicationTemp_inputs">
                <div className="formFieldAplicationTemp_item">
                  <Form.Text>Min</Form.Text>
                  <Form.Control
                    type="number"
                    value={form.minimumInstallationTemperature}
                    readOnly
                  />
                  <Form.Text>°C</Form.Text>
                </div>
                <div className="formFieldAplicationTemp_item">
                  <Form.Text>Max</Form.Text>
                  <Form.Control
                    type="number"
                    value={form.maximumInstallationTemperature}
                    readOnly
                  />
                  <Form.Text>°C</Form.Text>
                </div>
              </div>
            </Form.Group>
            <div className="thermometerWrapp">
              {blink.isHeatBlink ? (
                <img
                  className={`${
                    blink.isHeatBlink ? "thermometer blink" : "thermometer"
                  }`}
                  alt="thermometer"
                  src={ThermometerHeat}
                />
              ) : (
                <img
                  className={`${
                    blink.isColdBlink ? "thermometer blink" : "thermometer"
                  }`}
                  alt="thermometer"
                  src={Thermometer}
                />
              )}
            </div>
          </div>
        </div>
      </PanelBody>
    </>
  );
};

export default SolutionSelection;
